import {renderToStaticMarkup} from "react-dom/server";
import {removeFormatBlock, applyFormatBlock, replaceTag} from "./tynyFunctions";
import ArticleMediaFile from "../bodyMediaFile";
import React from "react";
import CF from "../../../common";

export function insertColoredBox(settings, manualTriggerSaveFunc) {
    const ed = tinymce.activeEditor;
    let section = ed.selection.getNode().closest(".stndt-blk");
    const triggerEditorChange = !!section;
    let newKey = settings.image_key || CF.generateUniqKey();

    if (!section) {
        applyFormatBlock(settings.use_aside_tag ? 'coloredBoxAsideFormat' : 'coloredBoxFormat');
        section = ed.selection.getNode().closest(".stndt-blk");
    } else {
        if (settings.use_aside_tag && section.tagName !== 'ASIDE') {
            section = replaceTag(section, 'aside');
        } else if (!settings.use_aside_tag && section.tagName === 'ASIDE') {
            section = replaceTag(section, 'div');
        }
        section.className = "stndt-blk";
    }

    let styles = [];
    if (settings.color) { styles.push(`color: ${settings.color}`); }
    if (settings.bgColor) { styles.push(`background-color: ${settings.bgColor}`); }
    if (settings.borderStyle) { section.classList.add(settings.borderStyle); }
    if (settings.borderColor) { styles.push(`border-color: ${settings.borderColor} !important`); }
    if (settings.icon) { section.classList.add(settings.icon); }
    if (settings.smallPadding) { section.classList.add('stndt-blk--small'); }
    if (settings.greenLinks) { section.classList.add('green-stndt-blk'); }

    section.setAttribute('style', styles.join('; '));
    section.setAttribute('data-mce-style', styles.join('; '));

    if (!!settings.defaultContent && ed.selection.isCollapsed()) {
        ed.execCommand('mceInsertContent', false, renderToStaticMarkup(settings.defaultContent));
        section = ed.selection.getNode().closest(".stndt-blk");
    }

    const imgContainer = section.querySelector('.stndt-blk > .cell.medium-order-2');
    const leftContentContainer = section.querySelector('.stndt-blk > .cell.medium-order-1');
    if (settings.withImage) {
        section.classList.add('grid-x');
        section.classList.add('stndt-blk--with-featured-img');
        section.setAttribute('contenteditable', false);

        if (!leftContentContainer) {
            //const newElem = ed.dom.add(ed.getBody(), 'div', {class: 'cell medium-8 medium-order-1 test-a', contentEditable: false}, section.innerHTML);
            section.innerHTML = `<div class="cell medium-${settings.logo ? 'auto' : '8'} medium-order-1" contentEditable="true">` + section.innerHTML + '</div>';
            // section.insertBefore(newElem, section.firstElementChild);
        } else {
            if (leftContentContainer.classList.contains('medium-8') && settings.logo) {
                leftContentContainer.classList.remove('medium-8');
                leftContentContainer.classList.add('medium-auto');
            }
            if (leftContentContainer.classList.contains('medium-auto') && !settings.logo) {
                leftContentContainer.classList.remove('medium-auto');
                leftContentContainer.classList.add('medium-8');
            }
        }


        // if (settings.withBgImage) {
        //
        // } else {
        const imgContent = settings.withBgImage ? renderToStaticMarkup(<span className='article-img-conatainer article-img-conatainer--bg' data-item={JSON.stringify(settings.image)} style={{backgroundImage: `url(${settings.image.original_path})`}} id={newKey} contentEditable="false" />)
            : renderToStaticMarkup(
                <span className='article-img-conatainer' data-item={JSON.stringify(settings.image)} id={newKey} contentEditable="false">
                    <ArticleMediaFile item={settings.image} />
                </span>);

            if (imgContainer) {
                ed.dom.setHTML(imgContainer, imgContent);
                if (imgContainer.classList.contains('medium-4') && settings.logo) {
                    imgContainer.classList.remove('medium-4');
                    imgContainer.classList.add('medium-shrink');
                }
                if (imgContainer.classList.contains('medium-shrink') && !settings.logo) {
                    imgContainer.classList.remove('medium-shrink');
                    imgContainer.classList.add('medium-4');
                }
            } else {
                const newElem = ed.dom.add(ed.getBody(), 'div', {class: `cell medium-${settings.logo ? 'shrink' : '4'} medium-order-2`, contentEditable: false}, imgContent);
                section.insertBefore(newElem, section.firstElementChild);
            }
        // }
    } else {
        section.removeAttribute('contenteditable');
        if (imgContainer) {
            imgContainer.remove();
        }
        if (leftContentContainer) {
            let content = leftContentContainer.innerHTML;
            leftContentContainer.remove();
            ed.execCommand('mceInsertContent', false, content);
        }
    }
    if (triggerEditorChange) { manualTriggerSaveFunc(ed.getContent()); }
}

export function removeColoredBox(keepContent, manualTriggerSaveFunc) {
    removeFormatBlock('.stndt-blk', keepContent);
    manualTriggerSaveFunc(tinymce.activeEditor.getContent());
}

export function regStandOutBlocks(ed, modal, blockForm) {
    ed.ui.registry.addToggleButton('coloredBox', {
        icon: 'gamma',
        tooltip: 'Standout Block',
        onAction: function () {
            if (tinymce.activeEditor.formatter.match('coloredBoxFormat') || tinymce.activeEditor.formatter.match('coloredBoxAsideFormat')) {
                const node = tinymce.activeEditor.selection.getNode().closest(".stndt-blk");
                modal.current.open();
                if (!!node) {
                    let img = {};
                    let img_tag = node.querySelector('.article-img-conatainer');
                    if(!!img_tag) {
                        let img_attributes= JSON.parse(img_tag.getAttribute('data-item'));
                        img = img_attributes;
                        img['is_logo'] = img_tag.parentElement.classList.contains('medium-shrink');
                    }
                    blockForm.current.set(node.style.backgroundColor, node.style.color, node.style.borderColor, node.classList, node.className, node.tagName, img);
                }
            } else {
                modal.current.open();
            }
        },
        onSetup: function (api) {
            let editorEventCallback = function (eventApi) {
                const node = eventApi.element.closest(".stndt-blk");
                api.setActive(!!node);
            };
            ed.on('NodeChange', editorEventCallback);
            return function (api) {
                ed.off('NodeChange', editorEventCallback);
            };
        }
    });
}

export function escapeFromBlock(ed) {
    ed.on('keydown', (event) => {
        let blockSelector = null;
        if (tinymce.activeEditor.formatter.match('coloredBoxFormat') || tinymce.activeEditor.formatter.match('coloredBoxAsideFormat')) { blockSelector = '.stndt-blk' }
        if (tinymce.activeEditor.formatter.match('blockquote')) { blockSelector = 'blockquote' }
        if (tinymce.activeEditor.formatter.match('customQuoteFormat')) { blockSelector = 'section.quote' }
        if (tinymce.activeEditor.formatter.match('customGrid3Format') || tinymce.activeEditor.formatter.match('customGrid2Format')) { blockSelector = '.article-grid-container' }
        if (!blockSelector && ed.selection.getNode().closest("div")) { blockSelector = 'div' }
        if (!blockSelector) { return; }

        if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey)
            return;

        let insertContent = null;
        if (['ArrowLeft', 'Left', 'ArrowUp', 'Up'].includes(event.key)) {
            insertContent = 'before';
        }
        if (['ArrowRight', 'Right', 'ArrowDown', 'Down'].includes(event.key)) {
            insertContent = 'after';
        }
        if (event.key === 'Enter') {
            insertContent = 'after_enter';
        }
        if (!insertContent) { return; }

        const currentNode = ed.selection.getNode();

        // Assert the user is within a blockquote
        const blockNode = ed.dom.getParent(currentNode, blockSelector);
        if (!blockNode) return;

        const currentSelection = ed.selection.getRng();
        // Return if user has highlighted text
        if (currentSelection.startOffset !== currentSelection.endOffset) return;


        // Exit the blockquote if a delete happens on an empty line
        const lineText = currentNode.innerText;

        const newParagraph = ed.dom.create(
            'p',
            {},
            '<br/>'
        );

        if (insertContent === 'before') {
            if (currentSelection.startOffset !== 0) return;

            const blockPrevNode =  ed.dom.getPrev(blockNode, '*');
            if (blockPrevNode) { return; }

            // Don't split the blockquote if there's a previous node and the current line has text
            const prevNode = ed.dom.getPrev(currentNode, '*');
            if (prevNode && lineText !== '\n') return;

            const root = ed.dom.getRoot();

            root.insertBefore(newParagraph, blockNode);
            ed.selection.setCursorLocation(newParagraph, 0);
        } else if (insertContent === 'after') {
            const blockNextNode =  ed.dom.getNext(blockNode, '*');
            if (blockNextNode) { return; }

            if ((currentSelection.startOffset+1) < currentSelection.startContainer.length) return;

            const nextNode = ed.dom.getNext(currentNode, '*');
            if (nextNode && lineText !== '\n') return;

            const newElem = ed.dom.add(ed.getBody(), 'p', {}, '<br/>');
            ed.selection.setCursorLocation(newElem, 0);
        } else {
            const nextNode = ed.dom.getNext(currentNode, '*');
            if (nextNode || (currentSelection.endContainer.length > 0 && blockSelector !== 'section.quote')) return;
            ed.dom.insertAfter(newParagraph, blockNode);
            ed.selection.setCursorLocation(newParagraph, 0);
        }

        event.preventDefault();
    });
}