export const SET_INITIAL_ARTICLE = 'SET_INITIAL_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const CHANGE_ARTICLE = 'CHANGE_ARTICLE';
export const SAVE_ARTICLE_ERRORS = 'SAVE_ARTICLE_ERRORS';
export const CHANGE_ARTICLE_SOURCE = 'CHANGE_ARTICLE_SOURCE';
export const ADD_ARTICLE_SOURCE = 'ADD_ARTICLE_SOURCE';
export const DELETE_ARTICLE_SOURCE = 'DELETE_ARTICLE_SOURCE';

export const SET_INITIAL_HTML_BLOCK = 'SET_INITIAL_HTML_BLOCK';
export const CHANGE_HTML_BLOCK = 'CHANGE_HTML_BLOCK';

export const SET_CATEGORY = 'SET_CATEGORY';
export const CHANGE_CATEGORY = 'CHANGE_CATEGORY';

export const SET_USER = 'SET_USER';
export const CHANGE_USER = 'CHANGE_USER';
export const CHANGE_USER_DETAIL = 'CHANGE_USER_DETAIL';
export const ADD_USER_DETAIL = 'ADD_USER_DETAIL';
export const DELETE_USER_DETAIL = 'DELETE_USER_DETAIL';

export const SET_INITIAL_GALLERY = 'SET_INITIAL_GALLERY';
export const CHANGE_GALLERY_SETTINGS = 'CHANGE_GALLERY_SETTINGS';
export const SET_GALLERY_ITEM = 'SET_GALLERY_ITEM';
export const CHANGE_GALLERY_ITEM = 'CHANGE_GALLERY_ITEM';
export const UPLOAD_GALLERY_ITEM = 'UPLOAD_GALLERY_ITEM';
export const DELETE_GALLERY_ITEM = 'DELETE_GALLERY_ITEM';

export const SET_CV_WRITING_PACKAGE = 'SET_CV_WRITING_PACKAGE';
export const CHANGE_CV_WRITING_PACKAGE = 'CHANGE_CV_WRITING_PACKAGE';
export const SET_IDS_CV_WRITING_PACKAGE = 'SET_ID_CV_WRITING_PACKAGE';
export const SET_ERRORS_WRITING_PACKAGE = 'SET_ERRORS_WRITING_PACKAGE';
export const ADD_CV_WRITING_PACKAGE_EXTRAS = 'ADD_CV_WRITING_PACKAGE_EXTRAS';
export const CHANGE_CV_WRITING_PACKAGE_EXTRAS = 'CHANGE_CV_WRITING_PACKAGE_EXTRAS';
export const DELETE_CV_WRITING_PACKAGE_EXTRAS = 'DELETE_CV_WRITING_PACKAGE_EXTRAS';
export const SET_ERRORS_CV_WRITING_PACKAGE_EXTRAS = 'SET_ERRORS_CV_WRITING_PACKAGE_EXTRAS';
export const SET_ID_CV_WRITING_PACKAGE_EXTRAS = 'SET_ID_CV_WRITING_PACKAGE_EXTRAS';
export const REORDER_CV_WRITING_PACKAGE_EXTRAS = 'REORDER_CV_WRITING_PACKAGE_EXTRAS';

export const SET_INITIAL_CV_TEMPLATE = 'SET_INITIAL_CV_TEMPLATE';
export const CHANGE_CV_TEMPLATE = 'CHANGE_CV_TEMPLATE';


export const SET_INITIAL_STATE_FORM = 'SET_INITIAL_STATE_FORM';
export const SAVE_FORM = 'SAVE_FORM';
export const SAVE_FORM_ERROR = 'SAVE_FORM_ERROR';
export const CHANGE_FORM = 'CHANGE_FORM';

export const SET_INITIAL_FIELDS = 'SET_INITIAL_FIELDS';
export const REORDER_FIELDS = 'REORDER_FIELDS';
export const ADD_FIELD = 'ADD_FIELD';
export const DELETE_FIELD = 'DELETE_FIELD';
export const CHANGE_FIELD = 'CHANGE_FIELD';
export  const SAVE_FIELD_ERRORS = 'SAVE_FIELD_ERRORS';
export const RESET_ERRORS_FIELDS = 'RESET_ERRORS_FIELDS';

export const ADD_SELECTED_VALUE_FIELD = 'ADD_SELECTED_VALUE_FIELD';
export const DELETE_SELECTED_VALUE_FIELD = 'DELETE_SELECTED_VALUE_FIELD';
export const REORDER_SELECTED_VALUES_FIELD = 'REORDER_SELECTED_VALUES_FIELD';

export const SET_INITIAL_PRESS_ARTICLE = 'SET_INITIAL_PRESS_ARTICLE';
export const UPDATE_PRESS_ARTICLE = 'UPDATE_PRESS_ARTICLE';
export const CHANGE_PRESS_ARTICLE = 'CHANGE_PRESS_ARTICLE';
export const SAVE_PRESS_ARTICLE_ERRORS = 'SAVE_PRESS_ARTICLE_ERRORS';
export const CHANGE_PRESS_ARTICLE_KEY_TAKEAWAY = 'CHANGE_PRESS_ARTICLE_KEY_TAKEAWAY';
export const ADD_PRESS_ARTICLE_KEY_TAKEAWAY = 'ADD_PRESS_ARTICLE_KEY_TAKEAWAY';
export const DELETE_PRESS_ARTICLE_KEY_TAKEAWAY = 'DELETE_PRESS_ARTICLE_KEY_TAKEAWAY';
export const REORDER_PRESS_ARTICLE_KEY_TAKEAWAYS = 'REORDER_PRESS_ARTICLE_KEY_TAKEAWAYS';
