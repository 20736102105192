import React, { Component } from 'react';
import * as galleryActions from "../actions/galleryActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import GalleryIndex from './index';
import ItemUpload from './itemUpload';
import ItemForm from "./itemForm";

class Gallery extends Component {

    render() {
        if (this.props.gallery.screen === 'upload') {
            return (<div className="grid-x">
                <div className="cell main">
                    <ItemUpload />
                </div>
            </div>)
        }

        return(
            <div className="grid-x">
                <div className="small-9 cell main">
                    <GalleryIndex ref="galleryModal" mode={this.props.mode} />
                </div>
                <div className="small-3 cell preview">
                    <ItemForm  ref="itemForm" fileMode={this.props.fileMode}/>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {gallery: state.gallery};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, galleryActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery);