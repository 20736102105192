import { SET_INITIAL_GALLERY, CHANGE_GALLERY_SETTINGS, SET_GALLERY_ITEM, CHANGE_GALLERY_ITEM, UPLOAD_GALLERY_ITEM, DELETE_GALLERY_ITEM } from './actionTypes';

export function setGallery(gallery) {
    return {
        type: SET_INITIAL_GALLERY,
        gallery: gallery
    };
}

export function changeGallerySettings(settings) {
    return {
        type: CHANGE_GALLERY_SETTINGS,
        settings: settings
    };
}

export function setGalleryItem(item) {
    return {
        type: SET_GALLERY_ITEM,
        item: item
    }
}

export function changeGalleryItem(item) {
    return {
        type: CHANGE_GALLERY_ITEM,
        item: item
    }
}

export function uploadGalleryItem(item) {
    return {
        type: UPLOAD_GALLERY_ITEM,
        item: item
    }
}

export function deleteGalleryItem(item) {
    return {
        type: DELETE_GALLERY_ITEM,
        item: item
    }
}