import React from "react";

export default function (props) {

    return (
        props.result.results.map((answer, index) => {
            return (
                    <div key={index} className="large-3 medium-6 small-12 cell questionnaire-list-icons">
                        <a href={answer.link} target={answer.target_blank ? '_blank' : ''} >
                            {answer.type === 'icon' ? <div className={"questionnaire-circle"}>
                                    <div className={answer.image}/>
                            </div> :
                                <div className={'questionnaire-list-img'} style={{backgroundImage:`url(${ answer.image })`}}> </div>
                            }
                            <p className="questionnaire-option-info">{answer.title}</p>
                        </a>
                            <a href={answer.link} target={answer.target_blank ? '_blank' : ''} role="button" tabIndex="0" className="questionnaire-option-link">
                            {answer.link_title} <span className="small-icon orange-arrow"/>
                        </a>
                    </div>
            )
        })
    )

}