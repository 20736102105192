import React from "react";
import {TextField, Loader, ErrorMessage, actions, InputTypeField} from 'react-fields';
import RegisterApi from '../api/register';
import SimpleModal from '../modals/simpleModal';
import TermsCheckboxField from '../customFields/termsCheckbox';
import PasswordStrength from '../base/passwordStrength';
import MatchField from '../base/fieldMatch';
import InvisibleRecaptcha from '../base/invisibleRecaptcha';

class Form extends React.Component {
        state = {
            user: {errors: {}, agree_terms: false},
            isLoading: false
        };
        recaptchaInstance = React.createRef();


    resetErrors = () => {
        let user = this.state.user;
        user.errors = {};
        this.setState({user: user});
    };


    handleFieldChange = (e) => {
        let field_name = e.target.name;
        let user = this.state.user;
        user[field_name] = field_name === 'agree_terms' ? e.target.checked : e.target.value;
        user.errors[actions.camelize(field_name)] = "";
        this.setState({user: user});
        if (field_name === 'password' || field_name === 'password_confirmation'){
            if (field_name === 'password') {
                this.refs.passwordStrength.checkValue(e.target.value);
            }
            this.refs.passwordMatch.checkValue(user.password, user.password_confirmation);

        }
    };

    renderErrors = (errors) => {
        this.setState({isLoading: false});
        this.recaptchaInstance.current.reset();
        let user = this.state.user;
        user.errors = errors;
        this.setState({user: user});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        try {
            this.recaptchaInstance.current.execute();
        } catch (error) {
            console.log('captcha err');
            console.log(error);
        }
    };

    submitForm = (token) => {
        this.setState({isLoading: true});
        this.resetErrors();
        RegisterApi.register(this.state.user, token, this.onSuccess, this.renderErrors)
    };

    onSuccess = (response) => {
        this.refs.afterRegistrationModal.open();
        this.recaptchaInstance.current.reset();
    };

    afterRegistration = () =>{
        document.location.href='/';
    };




    render(){
        let user = this.state.user;
        return(
           <React.Fragment>
            <form onSubmit={this.handleSubmit} style={{position: 'relative'}}>
                {this.state.isLoading ? <Loader size="small" /> : null}
                <div className="grid-x">
                    <div className="small-12 medium-6 cell padR5">
                        <TextField name="first_name" onChange={this.handleFieldChange} value={user.first_name}
                        placeholder="First Name*" errorMessage={user.errors.firstName} errorType="tooltip"
                        />
                    </div>
                    <div className="small-12 medium-6 cell padL5">
                        <TextField name="last_name" onChange={this.handleFieldChange} value={user.last_name}
                                   placeholder="Last Name*" errorMessage={user.errors.lastName} errorType="tooltip" />
                    </div>
                    <div className="small-12 cell">
                        <TextField name="email" onChange={this.handleFieldChange} value={user.email}
                                   placeholder="Email*" errorMessage={user.errors.email} errorType="tooltip" />
                    </div>
                    <div className="small-12 cell">
                        <TextField name="email_confirmation" onChange={this.handleFieldChange} value={user.email_confirmation}
                                   placeholder="Confirm Email*" errorMessage={user.errors.emailConfirmation} errorType="tooltip" />
                    </div>
                    <div className="small-12 medium-6 cell padR5">
                        <InputTypeField name="password" onChange={this.handleFieldChange} value={user.password}
                                        type="password" placeholder="Choose Password*" errorType="tooltip"
                                        errorMessage={user.errors.password} />
                        <PasswordStrength ref="passwordStrength" value={user.password} />
                    </div>
                    <div className="small-12 medium-6 cell padL5">
                        <InputTypeField name="password_confirmation" onChange={this.handleFieldChange}
                                   type="password" value={user.password_confirmation} errorType="tooltip"
                                   placeholder="Confirm Password*" errorMessage={user.errors.passwordConfirmation} />
                        <MatchField value={user.password_confirmation} match_value={user.password} ref="passwordMatch" />
                    </div>
                </div>
                <ErrorMessage message={user.errors.base}/>
                <div className="grid-x form-buttons align-middle">
                    <div className="medium-7 small-7 xsmall-12 cell">
                            <TermsCheckboxField name="agree_terms" value={user.agree_terms}
                                                errorMessage={user.errors.agreeTerms}
                                                onChange={this.handleFieldChange}/>

                    </div>
                    <div className="medium-5 small-5 xsmall-12 cell text-right">
                        <button className="button-orange" onClick={this.handleSubmit}>Register</button>
                    </div>
                </div>
            </form>
               <InvisibleRecaptcha ref={this.recaptchaInstance} sitekey={this.props.recaptcha_key}
                                   verifyCallback={this.submitForm} />
            <SimpleModal ref='afterRegistrationModal'
                          title="Thank you for registering!"
                          sprite="ad-icon popUp"
                          button="Close"
                          onClose={this.afterRegistration}>
                <p className="lastText">Once your application has been approved, you’ll receive an email from us within two business days containing a link to confirm your email address.</p>
            </SimpleModal>
           </React.Fragment>
        );

    }
}
export default Form;