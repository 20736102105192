import {combineReducers} from 'redux';
import article from './articleReducer';
import htmlBlock from './htmlBlockReducer';
import category from './categoryReducer';
import cv_writing_package from './cvWritingReducer';
import gallery from './galleryReducer';
import user from './userReducer';
import form from './flexibleFormReducer';
import fields from './flexibleFieldsReducer';
import cvTemplate from './cvTemplateReducer';
import pressArticle from "./pressArticleReducer";

const rootReducer = combineReducers({
    article,
    htmlBlock,
    category,
    cv_writing_package,
    cvTemplate,
    gallery,
    user,
    form,
    fields,
    pressArticle
});

export default rootReducer;