import React, {useState} from "react";
import StudyCollection from './collection/studyCollection';
import PressApi from "../api/pressApi";

export default function Study(props) {

    const [collection, setCollection] = useState(props.collection);
    const [page, setPage] = useState(props.page ? parseInt(props.page) + 1 : 2);

    const onLoadCollection = (loadedCollection, meta) => {
        let newCollection = [...collection, ...loadedCollection];
        setCollection(newCollection);
        setPage(page + 1)
    };

    const loadMore = (e) => {
        e.preventDefault();
        PressApi.getList(onLoadCollection, 'study', page)
    };

    const seoProps = {itemType: 'http://schema.org/CollectionPage', itemScope: true};

    return (
            <div className="grid-container studies" {...seoProps}>
                <h1 className={'press__main-title press__main-title--small-mar'}>Studies</h1>
                <meta itemProp="name" content="Studies"/>
                <meta itemProp="headline" content="Studies"/>
                <meta itemProp="description" content={props.seoDescription}/>
                <meta itemProp="url" content="/study"/>

                <div className="press__study">
                    <StudyCollection collection={collection}/>
                </div>

                <div className="grid-x align-center">
                    {page <= props.totalPages ?
                        <a href={`/study?page=${page}`} onClick={loadMore} className="button button-orange load-more">Load more</a> :
                        null
                    }
                </div>
            </div>
        );
}