import { SET_INITIAL_STATE_FORM, SAVE_FORM, SAVE_FORM_ERROR, CHANGE_FORM } from '../actions/actionTypes';
import initialState from './initialState';

export default function form(state = initialState.form, action) {
    switch (action.type) {
        case SET_INITIAL_STATE_FORM:
            let initialForm = action.form;
            return {
                id: initialForm.id || "",
                title: initialForm.title ||  "",
                subject: initialForm.subject ||  "",
                emails: initialForm.emails || "",
                media_kit_file: initialForm.media_kit_file || {},
                user_email_template: initialForm.user_email_template || "",
                form_class: initialForm.form_class,
                on_sent_message: initialForm.on_sent_message,
                errors: {}
            };
        case SAVE_FORM:
            let newState = action.form;
            newState.errors = {};
            return {...state, form: {$set: newState}};
        case CHANGE_FORM:
            return Object.assign({}, state, action.form);
        case SAVE_FORM_ERROR:
            return Object.assign({}, state, {errors: action.errors});

        default:
            return state;
    }
}