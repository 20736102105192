import {removeFormatBlock} from "./tynyFunctions";

export function regAsideWrapper(ed) {
    ed.ui.registry.addToggleButton('asideWrapper', {
        icon: 'select-all',
        tooltip: 'Aside',
        onAction: function () {
            if (ed.formatter.match('aside')) {
                removeFormatBlock('aside', false);
            } else {
                ed.execCommand('RemoveFormat');
                ed.execCommand('FormatBlock', true, 'asideFormat');
            }
        },
        onSetup: function (api) {
            // ed.formatter.formatChanged('asideFormat', function (state) {
            //     api.setActive(state);
            // });
            let editorEventCallback = function (eventApi) {
                const node = eventApi.element.closest("aside:not(.stndt-blk)");
                api.setActive(!!node);
                const block = eventApi.element.closest(".stndt-blk");
                api.setDisabled(!!block);
            };
            ed.on('NodeChange', editorEventCallback);
            return function (api) {
                ed.off('NodeChange', editorEventCallback);
            };
        }
    });
}