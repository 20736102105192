import React, {Component, Fragment} from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as articleActions from '../../actions/articleActions';

import mainApi from '../../api/articlesApi';

import {TextField, ErrorMessage, Loader} from "react-fields";
import cf from "../../common";
import ArticleValidation from '../articleValidation';

class VideoArea extends Component {

    state = {isLoading: false, isOpened: false};

    handleFieldChange = (e) => {
        let article = this.props.article;
        const field = e.target.name;
        article[field] = e.target.value;
        article['errors'][cf.camelize(field)] = '';
        this.props.actions.changeArticle(article);
    };

    handleGetVideoInfo = (e) => {
        e.preventDefault();
        this.setState({isLoading: true}, () => {
            let videoUrl = this.props.article.video_url;
            if (!videoUrl) {
                this.onError({videoUrlInfo: 'Video URL is required'});
                return
            }

            let validationErrors = ArticleValidation.validateVideo(this.props.article);
            if (Object.keys(validationErrors).length) {
                this.onError(validationErrors);
                return
            }

            let videoId = '';
            if (videoUrl.includes('www.youtube.com/watch')) {
                videoId = videoUrl.split('v=').pop();
            } else {
                videoId = videoUrl.split('/').pop();
            }

            if (!videoId) {
                this.onError({videoUrlInfo: 'Video Id is required'});
                return
            }

            mainApi.getVideoInfo(videoId, this.onSuccess, this.onError);
        });
    };

    onSuccess = (result) => {
        console.log(result);
        let article = this.props.article;
        article['errors']['videoUrl'] = '';
        article['errors']['videoUrlInfo'] = '';
        this.props.actions.changeArticle({...article, ...result});
        this.setState({isLoading: false});
    };

    onError = (errors) => {
        console.log('error', errors);
        let article = this.props.article;
        article['errors'] = {...article['errors'], ...errors};
        this.props.actions.changeArticle(article);
        this.setState({isLoading: false});
    };

    renderInfo = () => {
        let article = this.props.article;
        if (!this.state.isOpened) {return null}

        const fields = [{label: 'Video Id', name: 'video_id'}, {label: 'Name', name: 'video_name'},
            {label: 'Description', name: 'video_description'}, {label: 'Thumbnail URL', name: 'video_thumbnail_url'},
            {label: 'Duration', name: 'video_duration'}, {label: 'Uploaded Date', name: 'video_upload_date'}];

        return (
            <div className="grid-x">
                <div className='cell'>
                    <ul>{fields.map(f => (<li key={`video_${f.name}`}><b>{f.label}</b>: {article[f.name]}</li>))}</ul>
                </div>
            </div>
        )
    };

    render() {
        let article = this.props.article;
        let allowShowInfo = this.props.mode === 'admin';
        return (
            <Fragment>
                {this.state.isLoading ? <Loader size="small"/> : null}
                <div className="grid-x sources-input-group">
                    <div className="cell auto">
                        <TextField onChange={this.handleFieldChange} name='video_url'
                                   placeholder="" value={article.video_url}
                                   errorMessage={article.errors.videoUrl} errorType="tooltip" />
                    </div>
                    { allowShowInfo ?
                        <div className="cell shrink">
                            <button className="button-add" onClick={this.handleGetVideoInfo}>Get Video Info</button>
                        </div>
                    : null }
                </div>
                <ErrorMessage message={article.errors.videoUrlInfo} />
                {allowShowInfo && !!article.video_id ?
                    <Fragment>
                        <div className="grid-x" onClick={() => {this.setState({isOpened: !this.state.isOpened})}}>
                            <div className="cell shrink"><div className={`admin-icon ${this.state.isOpened ? 'chevron-up' : 'chevron-down'}`} /></div>
                            <div className="cell auto">Video Info:</div>
                        </div>
                        {this.renderInfo()}
                    </Fragment>
                : null}
            </Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        article: state.article
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoArea);