import {SET_INITIAL_ARTICLE, UPDATE_ARTICLE, CHANGE_ARTICLE, SAVE_ARTICLE_ERRORS,
    CHANGE_ARTICLE_SOURCE, DELETE_ARTICLE_SOURCE, ADD_ARTICLE_SOURCE} from './actionTypes';

export function setArticle(article, content, mode, timeZone) {
    article['content'] = content;
    return {
        type: SET_INITIAL_ARTICLE,
        mode: mode,
        article: article,
        timeZone: timeZone
    };
}

export function updateArticle(article) {
    return {
        type: UPDATE_ARTICLE,
        article: article
    };
}

export function changeArticle(article) {
    return {
        type: CHANGE_ARTICLE,
        article: article
    };
}

export function saveArticleErrors(errors) {
    return {
        type: SAVE_ARTICLE_ERRORS,
        errors: errors
    }
}

export function changeArticleSource(article, source) {
    return {
        type: CHANGE_ARTICLE_SOURCE,
        article: article,
        source: source
    };
}

export function addArticleSource(article) {
    return {
        type: ADD_ARTICLE_SOURCE,
        article: article
    };
}

export function deleteArticleSource(article, source) {
    return {
        type: DELETE_ARTICLE_SOURCE,
        article: article,
        source: source
    };
}