import React, {Component, Fragment} from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as articleActions from '../../actions/articleActions';
import FieldWrapper from "../../partials/adminFieldWrapper";

const RELS =  [
    {title: 'Dofollow', value: 'follow'},
    {title: 'No Follow & Sponsored', value: 'nofollow_sponsored'},
    {title: 'Sponsored', value: 'sponsored'},
    {title: 'No Follow', value: 'nofollow'}
];

import {TextField, TextareaField, ErrorMessage, Loader, SelectField} from "react-fields";
import PropTypes from "prop-types";
import cf from "../../common";

import {ImageUploader} from 'image-uploader';

class AdvertorialCompany extends Component {

    changeArticle = (e) => {
        let article = this.props.article;
        let field = e.target.name;
        article[field] = e.target.value;
        article['errors'][cf.camelize(field)] = '';
        this.props.actions.changeArticle(article);
    };

    setLogo = (options) => {
        let article = this.props.article;
        article.company_logo = {...article.company_logo, ...options};
        article.company_logo_options = {...article.company_logo, ...options};
        article['errors'][cf.camelize('company_logo')] = '';
        this.props.actions.changeArticle(article);
    };

    deleteLogo = () => {
        let article = this.props.article;
        article.company_logo_options = article.company_logo.id ?  {delete_logo_id: article.company_logo.id} : {};
        article.company_logo = {};
        article.company_logo_id = null;
        article['errors'][cf.camelize('company_logo')] = '';
        this.props.actions.changeArticle(article);
    };

    render() {
        let article = this.props.article;

        return(
         <Fragment>
                <h5 className="subsection">Advertorial Company</h5>
                <FieldWrapper label='Company Name' name='company_name'>
                    <TextField onChange={this.changeArticle} name='company_name'
                               value={article.company_name}
                               errorMessage={article.errors.companyName} errorType="tooltip" />
                </FieldWrapper>
                <FieldWrapper label='Company Logo' name='company_logo'>
                    <ImageUploader image={article.company_logo} setOptions={this.setLogo}
                                   url="/api/company_logos/cache" name="company_logo"
                                   withDelete={true} deleteImage={this.deleteLogo} />
                    <ErrorMessage message={article.errors.companyLogo} />
                </FieldWrapper>
             <div className={'split-section'}>
                <FieldWrapper label='Company URL' name='company_url'>
                    <TextField onChange={this.changeArticle} name='company_url'
                               value={article.company_url}
                               errorMessage={article.errors.companyUrl} errorType="tooltip" />
                </FieldWrapper>

                <FieldWrapper label='Rel' name='company_rel'>
                    <SelectField name="company_rel" placeholder="Select rel..." className='label-icon-right select-label'
                                 onChange={this.changeArticle}
                                 options={RELS} optionsValue='value' optionsLabel='title'
                                 selected={article.company_rel}
                                 errorMessage={article.errors.companyRel} errorType="tooltip" />
                </FieldWrapper>
             </div>




         </Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        article: state.article
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions), dispatch)
    };
}

AdvertorialCompany.contextTypes = {
    store: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertorialCompany);
