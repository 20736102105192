import {actions} from 'react-fields'

export default class FlexibleFormApi {

    static submit(token, form, mediaKit, onSuccess, onError) {
        let fields = {};
        form.fields.map((f) => fields[`field_${f.id}`] = f.value);
        let data = {form: {id: form.id, fields: fields}, g_recaptcha_response: token, request_media_kit: mediaKit};
        const url = '/api/flexible_forms/submit';

        fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }


}