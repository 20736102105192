import React, { Component, Fragment } from 'react';
import * as galleryActions from "../actions/galleryActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {actions, SelectField, TextField, ErrorMessage, CheckboxField} from "react-fields";
import cf from '../common';

import GalleryApi from "../api/galleryApi";

class ItemForm extends Component {

    handleFieldChange = (e) => {
        let item = this.props.item;
        item[e.target.name] = ['link_to_nofollow', 'link_to_blank', 'source_blank', 'source_nofollow', 'shadow'].includes(e.target.name) ? e.target.checked : e.target.value;
        item['errors'][cf.camelize(e.target.name)] = '';
        this.props.actions.changeGalleryItem(item);
    };

    onSuccessDelete = (item, response) => {
        console.log('onSuccessDelete', response);
        this.props.actions.deleteGalleryItem(item);
    };

    onDelete = (e) => {
        e.preventDefault();
        if (confirm("Delete Item?"))
            GalleryApi.delete(this.props.item.id, (response) => this.onSuccessDelete(this.props.item, response), this.onError)
    };

    onSuccess = (response) => {
        console.log('onSuccess proceed');
        if (this.props.gallery.onSave) { this.props.gallery.onSave(this.props.item); }
    };

    onError = (errors) => {
        let item = this.props.item;
        item['errors'] = errors;
        this.props.actions.changeGalleryItem(item);
    };

    onChange = () => {
        let gallery = this.props.gallery;
        if (gallery.mode !== 'admin' ) { return }
        gallery['screen'] = 'upload';
        gallery['reUploadItemId'] = {id: this.props.item.id, itemType: this.props.item.type.split(/(?=[A-Z])/).join('_').toLowerCase()};
        this.props.actions.changeGallerySettings(gallery);
    };

    handleImageSave = () => {
        let galleryItem = this.props.item;
        let errors = GalleryApi.validate(galleryItem, this.props.gallery.fileMode);
        if (Object.keys(errors).length) {
            this.onError(errors);
            return;
        }

        if (galleryItem['source_link']) {
            let r = new RegExp('^(?:[a-z]+:)?//', 'i');
            if (!r.test(galleryItem['source_link'])) {
                galleryItem['source_link'] = `https://${galleryItem['source_link']}`;
            }
        }

        if (this.props.item.isAllowedToEdit) {
            GalleryApi.update(galleryItem, this.onSuccess, this.onError);
        } else {
            this.props.gallery.onSave(galleryItem);
        }
    };

    render() {
        let item = this.props.item;
        if (!item) { return null; }
        return(
            <Fragment>
                <div className="preview-action">
                <div className="grid-x">
                    {item.type === 'GalleryFile' ?
                        <div className='media-file-container'>
                            <div className={item.icon} />
                        </div>
                        :
                        <div className='preview-file-container'>
                            <img src={item.original_path} className="preview-file" />
                        </div>
                    }
                    <div className="small-9 cell file-action">
                        <a className="path" href={item.original_path} target='_blank'>{item.original_path}</a>
                    </div>
                    <div className="small-3 cell text-right file-action">
                        {item.isAllowedToEdit ? <a className="delete" onClick={this.onDelete}>Delete</a> : null }
                        {/*{this.props.gallery.mode === 'admin' ? <a className="right" onClick={this.onChange}>Change</a> : null }*/}
                    </div>
                    <div className="small-12 cell">
                    <ul className="file-details menu">
                        <li className="menu">
                            <b>File Size</b><p>{item.size}</p>
                        </li>
                        <li className="menu">
                            <b>Dimensions</b><p>{item.dimensions}</p>
                        </li>
                        <li className="menu">
                            <b>File Type</b>{item.extension ? <p>.{item.extension}</p> : null }
                        </li>
                        {this.props.gallery.mode === 'admin' ?
                            <li className="menu">
                                <b>Uploaded By</b><a href={item.uploaded_by_path} target='_blank'>{item.uploaded_by_name}</a>
                            </li>
                        : null}
                    </ul>
                    </div>
                    {this.props.gallery.mode === 'admin' && item ?
                        <div className="small-12 cell">
                            <label htmlFor='assigns'>Assigned To:</label>
                            <ErrorMessage message={item.errors.assigns} />
                            <ul>
                                {item.articles ? item.articles.map( (assignment, i) => {
                                    return (<li key={`assignment${i}`}><a href={assignment.path} target='_blank'>{assignment.name}</a></li>)
                                }) : null}
                            </ul>
                        </div>
                    : null}
                    <div className="small-12 cell">
                    <TextField onChange={this.handleFieldChange} name='name'
                               placeholder="Name" value={item.name} disabled={!this.props.item.isAllowedToEdit}
                               errorMessage={item.errors.name} />
                    <TextField onChange={this.handleFieldChange} name='alt'
                               placeholder="Alt Text" value={item.alt}
                               errorMessage={item.errors.alt} />
                        {this.props.gallery.itemType !== 'gallery_file' &&
                            <React.Fragment>
                                <TextField onChange={this.handleFieldChange} name='source_text'
                                           placeholder="Source Title" value={item.source_text}
                                           errorMessage={item.errors.sourceText}/>
                                <TextField onChange={this.handleFieldChange} name='source_link'
                                    placeholder="Source URL" value={item.source_link}
                                    errorMessage={item.errors.sourceLink} />
                            </React.Fragment>
                        }
                    {item.source_link ? <Fragment>
                        <CheckboxField onChange={this.handleFieldChange} name='source_blank'  className="linkTo"
                                      value={item.source_blank} label='Open in new tab' />
                        <CheckboxField onChange={this.handleFieldChange} name='source_nofollow'
                                       value={item.source_nofollow} label='NOFOLLOW'  className="linkTo"/>
                    </Fragment> : null }

                    {this.props.gallery.itemType !== 'article_featured_image' ?
                        <Fragment>
                            <label htmlFor='link_type'>{this.props.gallery.fileMode === 'insert' ? 'Link Options' : 'Link To'}</label>
                            {this.props.gallery.fileMode !== 'insert' && <SelectField name="link_type" className='label-icon-right select-label'
                                         onChange={this.handleFieldChange}
                                         options={[{value: 'none', name: 'None'}, {value: 'url', name: 'Custom URL'}]} optionsValue='value' optionsLabel='name'
                                         selected={item.link_type} /> }
                            {item.link_type === 'url' || this.props.gallery.fileMode === 'insert' ?
                                <Fragment>
                                    {this.props.gallery.fileMode === 'insert' ?
                                        <TextField onChange={this.handleFieldChange} name='display_text'
                                                   placeholder="Enter link text" value={item.display_text}
                                                   errorMessage={item.errors.displayText}/> :
                                        <TextField onChange={this.handleFieldChange} name='link_to'
                                                   placeholder="Enter a url to link to" value={item.link_to}
                                                   errorMessage={item.errors.linkTo}/>
                                    }
                                    <CheckboxField onChange={this.handleFieldChange} name='link_to_nofollow'
                                                   value={item.link_to_nofollow} label='NOFOLLOW'  className="linkTo"/>
                                    <CheckboxField onChange={this.handleFieldChange} name='link_to_blank'
                                                   value={item.link_to_blank} label='Open in new tab'  className="linkTo"/>
                                </Fragment>
                            : null}
                            {this.props.gallery.itemType !== 'gallery_file' &&
                                <React.Fragment>
                                    <label htmlFor='caption'>Caption</label>
                                    <TextField onChange={this.handleFieldChange} name='caption'
                                               placeholder="Enter a caption for this image" value={item.caption}
                                               errorMessage={item.errors.caption}/>
                                </React.Fragment>
                            }
                            </Fragment>
                    : null}
                        {this.props.gallery.itemType === 'article_image' ?
                        <Fragment>
                            <label htmlFor='shadow'>Appearance</label>
                            <CheckboxField onChange={this.handleFieldChange} name='shadow'
                                           value={item.shadow} label='Shadow'  className="linkTo"/>
                        </Fragment>
                        : null}
                    </div>
                </div>
                </div>
                <div className='menu file-buttons'>
                    {this.props.gallery.mode === 'admin' ? null : <button className="button-grey" onClick={this.props.gallery.onClose}>Cancel</button>}
                    <button className="button-orange" onClick={this.handleImageSave}>{this.props.gallery.mode === 'admin' ? 'Save' : 'Place Image'}</button>
                    <ErrorMessage message={item.errors.base} />
                </div>
            </Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {gallery: state.gallery, item: state.gallery.item};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, galleryActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemForm);