import React from "react";
import PressItem from './pressItem';

export default function PressCollection(props) {

    return (
            props.collection.map((article) => {
                return <PressItem key={article.id} article={article}/>
            })

    )
}