import React, {Component} from "react";
import ArticleCard from "../articles/partials/articleCard";
import PropTypes from "prop-types";
import AdBanner from '../adBanners/ad';

class Bunch extends Component {

    state = {
        collection: this.props.collection,
        spots: this.props.spots
    };

    shouldComponentUpdate(nextProps){
        return false;
    }



    renderList = () => {
        let length = this.state.collection.length;
        let result = [];
        let curArticle =0, curSpot = 0;
        //let adSpot1 = 6, adSpot2 =13;
        let [adSpot1, adSpot2] = this.state.spots;
        // if (length > 4)
        //     adSpot1 = Math.floor(Math.random() * 4) + 4;
        // if (length > 8)
        //     adSpot2 = Math.floor(Math.random() * 4) + 12;
        //
        // if (adSpot1 > length && length > 4 && length < 7) {
        //     adSpot1 = length;
        // } else {
        //     if (adSpot2 > length && length > 11 && length < 14)
        //         adSpot2 = length;
        // }

        while (curArticle < length || curSpot === adSpot1 || curSpot === adSpot2) {
            if (curSpot === adSpot1 || curSpot === adSpot2){
                let randKey = `ad_${this.props.index}_${curSpot}`;
                result.push(<div className="large-3 medium-6 small-12 cell" key={randKey} >
                    <div className='article-card-banner'><AdBanner /></div>
                </div>);
            } else {
                let article = this.state.collection[curArticle];
                let position = this.props.index * 14 + curSpot + 1;
                result.push(<div className="large-3 medium-6 small-12 cell" key={`article${article.id}`} >
                    <ArticleCard position={position} article={article} careerPath={this.props.careerPath} withSeo={this.props.withSeo} />
                </div>);
                curArticle++;
            }

            curSpot++;
        }
        return result;
    };

    render() {
       return this.renderList();
    }
}
Bunch.contextTypes = {
    store: PropTypes.object
};
export default Bunch;