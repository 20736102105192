import React from 'react';
import PropTypes from "prop-types";

export const FormFieldWrapper = props => {
    return (<React.Fragment>
        <div className="grid-x">
            { props.link ? <React.Fragment>
                    <div className="small-6 cell">
                        <label htmlFor={props.name}>{props.label}</label>
                    </div>
                    <div className="small-6 cell text-right">
                        <a href={props.link.href}><h5 className="secondary-header">{props.link.title}</h5></a>
                    </div>
                </React.Fragment> :
            <div className="small-12 cell">
                <label htmlFor={props.name}>{props.label}</label>
            </div>}
        </div>
        <div className="grid-x">
            <div className="small-12 cell">
                {props.children}
            </div>
        </div>
    </React.Fragment>);
};

FormFieldWrapper.propTypes = { label: PropTypes.string, name: PropTypes.string, link: PropTypes.object };

export default FormFieldWrapper;