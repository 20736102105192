import React from "react";
import {ErrorMessage, Loader} from 'react-fields';
import InvisibleRecaptcha from '../base/invisibleRecaptcha';
import * as formActions from "../actions/flexibleFormActions";
import {bindActionCreators} from "redux";
import { connect } from 'react-redux';
import FieldsList from "./fieldsList";
import formApi from '../api/flexibleFormApi';
import TermsCheckboxField from "../customFields/termsCheckbox";
import SimpleModal from "../modals/simpleModal";
import CustomCheckboxField from "../customFields/checkbox";


class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            agreeTerms: false,
            requestMediaKit: false,
            agreeTermsError: null
        };

        this.recaptchaInstance = React.createRef();
        this.fileRef = React.createRef();
    }

    validateForm = () => {
        let valid = true;
        let fields = this.props.flexibleForm.fields; //this.props.flexibleForm.fields.filter((f) => f.step <= (currentStep.index + 1));
        fields.map((field) => {
           if(field.required && !field.value){
               field.error = "This field is required";
               valid = false;
               this.props.actions.changeField(field);
           } else if (field.content_type === 'email') {
               let re = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
               if (!re.test(field.value)) {
                   field.error = "Email is not valid";
                   valid = false;
                   this.props.actions.changeField(field);
               } else {
                   if (field.value.length > 50){
                       field.error = "Email is too long (maximum is 50 characters)";
                       valid = false;
                       this.props.actions.changeField(field);
                   } else {
                       let domain = field.value.split('@')[1];
                       if (domain.indexOf ( '.' ) > 1 && domain.substr(domain.indexOf ( '.' ) + 1 ).length > 15) {
                           field.error = "Email is not valid";
                           valid = false;
                           this.props.actions.changeField(field);
                       }
                   }
               }
           }
           if (field.content_type === 'multiselect' && field.required && field.value.length === 0) {
               field.error = "Select minimum 1 value";
               valid = false;
           }
        });

        if(!this.state.agreeTerms){
            this.setState({agreeTermsError: "You need to accept the Terms and Privacy"});
            valid = false;
        }

        return valid;
    };


    submitForm = (token) => {
        this.setState({isLoading: true});
        formApi.submit(token, this.props.flexibleForm, this.state.requestMediaKit, this.onSuccess, this.onError);
    };

    resetForm = () => {
        this.props.actions.resetForm();
    };

    onSuccess =() => {
        this.resetForm();
        this.refs.messageSentModal.open();
        this.recaptchaInstance.current.reset();
        this.setState({isLoading: false, agreeTerms: false, requestMediaKit: false});
    };

    onError = (errors) => {
        this.setState({isLoading: false});
        console.log(errors);
        this.props.actions.submitFormError(errors);
        this.recaptchaInstance.current.reset();
    };

    onChangeTerms = () => {
       this.setState({agreeTerms: !this.state.agreeTerms, agreeTermsError: null})
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if(!this.validateForm()){
            return;
        }
        try {
            this.recaptchaInstance.current.execute();
        } catch (error) {
            console.log('captcha err');
            console.log(error);
        }
    };

    render() {
        const pathname = window.location.pathname;
        return(
            <div className={this.props.flexibleForm.form_class ? `form-container contact ${this.props.flexibleForm.form_class}` : pathname === '/advertise' ? 'form-container contact in-advertise' : 'form-container contact'}>
                {this.state.isLoading ? <Loader size="small" /> : ""}
                <h3 className='form-header'>{this.props.flexibleForm.title}</h3>
                {this.props.subheader ?
                    <p className="form-subheading">{this.props.subheader}</p> : null
                }
                <form onSubmit={this.handleSubmit}>
                    <div className="grid-x">
                        <FieldsList />
                    </div>
                    <ErrorMessage message={this.props.flexibleForm.errors.base}/>
                    <div className="grid-x form-buttons align-middle subscribe">
                        <div className="medium-8 small-12 cell flex-align-center">
                            {this.props.flexibleForm.media_kit_file ?
                                <div style={{marginRight: '10px'}}>
                                <CustomCheckboxField label="Request media kit"
                                                     value={this.state.requestMediaKit}
                                                     onChange={(e) => {this.setState({requestMediaKit: e.target.checked})}}
                                                     name="request_media_kit"
                                /></div> : null}
                            <TermsCheckboxField name="terms_agreement"
                                                value={this.state.agreeTerms}
                                                errorMessage={this.state.agreeTermsError}
                                                onChange={this.onChangeTerms}/>
                        </div>
                        <div className="medium-4 small-12 cell medium-text-right">
                            <button onClick={this.handleSubmit} className="button-orange">Send</button>
                        </div>
                    </div>
                </form>
                <InvisibleRecaptcha ref={this.recaptchaInstance} sitekey={this.props.recaptcha_key}
                                    verifyCallback={this.submitForm} />

                {this.props.flexibleForm.media_kit_file && this.state.requestMediaKit ?
                <SimpleModal ref='messageSentModal' className="reveal-large-grey" title="Thank you for contacting us!" sprite="confirmed-icon">
                    <div className="form-orange-line"/>
                    <p className="form-blue-desc with-margin">A member of our team will be in touch within the next 1-2 business days.</p>
                    <p className="form-blue-desc">We have also sent you an email which contains a link to our Media Kit. Please be sure to check your junk and promotional folders.</p>
                </SimpleModal> :
                    <SimpleModal ref='messageSentModal' title="Message Sent" sprite="ad-icon popUp" button="Close">
                        <div className="form-orange-line"/>
                        <p className="form-blue-desc with-margin">{this.props.flexibleForm.on_sent_message || 'A member of our team will be in touch :)'}</p>
                    </SimpleModal> }
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        flexibleForm: state.flexibleForm
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, formActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);