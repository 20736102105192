import React, { Component } from 'react';


class NavigationTabs extends Component {

    state = {
        isOpen: false,
        isSticky: false,
        isTopBarStuck: false,
        topSectionId: `section-${Math.random().toString(36).substring(4)}`
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


    handleScroll = () => {
        this.setState({isOpen: false});
        const _this = this;
        const welcomeAnchorBannerHeight = document.querySelector(`#${this.state.topSectionId}`).clientHeight;
        const isTopBarStuck = document.querySelector('.sticky-topbar').classList.contains("is-stuck");
        if (isTopBarStuck !== this.state.isTopBarStuck) {
            this.setState({isTopBarStuck: isTopBarStuck})
        }
        if ((window.matchMedia('(min-width: 769px)').matches && window.scrollY >= welcomeAnchorBannerHeight) || (window.matchMedia('(max-width: 769px)').matches && window.scrollY > 0)) {
            _this.setState({ isSticky: true });
        } else {
            _this.setState({ isSticky: false });
        }
    };

    onDropdownClick = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    renderTitle = () => {
        return this.props.currentTab ? this.props.tabs[this.props.currentTab]['title'] : null
    };

    render() {
        let currentTab = this.props.currentTab;
        const { isSticky, isTopBarStuck } = this.state;
        let navStyle = {
            marginTop: isSticky && isTopBarStuck ? '51px' : '0'
        };
        const isActiveClass = this.props.isActiveClass || 'is-active';
        const hoverClass = this.props.hoverClass ? `tabs-navigation-item--${this.props.hoverClass}` : '';

        const mobileDropdown =  <div className="tabs-wrapper" onClick={this.onDropdownClick}>
            <div className="selected-tab">
                {this.state.isOpen && this.props.mobileActiveTitle ?
                    <h4 className={`selected-tab-content ${this.props.isActiveClass || ''}`}>{this.props.mobileActiveTitle}</h4>
                    :
                    <span className={`selected-tab-content ${this.props.isActiveClass || ''}`}>{this.renderTitle()}</span>
                }

                <span className={'small-icon caret-down' + (this.state.isOpen ?  ' slide-up' : '')}/>
            </div>
        </div>;
        return (
            <React.Fragment>
                <div id={this.state.topSectionId} >
                    {this.props.children}
                </div>
                <div data-sticky-container={true}
                     className={"sticky-nav-container" + (this.props.navHeight === "small" ? " small-nav" : " large-nav")}
                >
                    <div className={'sticky sticky-nav' + (isSticky ? ' is-stuck' : '')} style={navStyle}>
                        <div className={"tabs-navigation" + (this.state.isOpen ? ' border-bottom-trans' : '')}>
                            <div className="grid-container">
                                <div className="grid-x">
                                    <div className="small-12 cell">
                                        {mobileDropdown}
                                        <ul onClick={this.onDropdownClick}  className={'tabs-menu' + (this.state.isOpen ? '' : ' hidden')}>
                                            {this.props.leftBar}
                                            <li className={"nested " + (this.props.addTabsClass || '')}>
                                                <nav>
                                                    <ul>
                                                        {Object.keys(this.props.tabs).map((tab) => {
                                                            const largeMargin = this.props.tabs[tab].margin ? ' tab-margin' : '';
                                                            const navClasses = `${this.props.navClass || ''} text-left  ${largeMargin}` +
                                                                (currentTab === tab ? ` ${isActiveClass}` : '') +
                                                                (this.props.tabs[tab].hide_large ? ' hidden-large-tab-item' : ' large-text-center');

                                                            return(
                                                               <li className={navClasses} key={tab}>
                                                                   {this.props.tabs[tab].link ?
                                                                       <a href={this.props.tabs[tab].link}
                                                                          className={`tabs-navigation-item ${hoverClass} ` + (currentTab === tab ? ` ${isActiveClass}` : '')}>
                                                                           {this.props.tabs[tab].title}
                                                                       </a> :
                                                                       <div className={`tabs-navigation-item--title`}>
                                                                           {this.props.tabs[tab].title}
                                                                       </div>
                                                                   }
                                                           </li>
                                                           )
                                                        })}
                                                    </ul>
                                                </nav>
                                            </li>
                                            {this.props.rightBar}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default NavigationTabs;