import React, { Component, Fragment } from 'react';

export default class StickySubheader extends Component {

    state = {
        displayExtended: false,
        isTopBarStuck: false,
        showApplePay: false
    };

    componentDidMount() {
        this.calculateStickySettings();
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    calculateStickySettings = () => {
        const stickyTopBarHeight = 50; //document.querySelector('.sticky-topbar').clientHeight; because shrinks to 50 on scroll
        const offsetTop = document.querySelector(this.props.offsetSelector).offsetTop;
        console.log(offsetTop);
        const stopTop = offsetTop - stickyTopBarHeight + 22;
        this.setState({stopTop: stopTop});
    };

    handleResize = () => {
        console.log('hResize');
        this.calculateStickySettings();
    };

    handleScroll = () => {
        console.log('hScroll', window.innerWidth);
        if (window.innerWidth <= 1024) {
            const isTopBarStuck = document.querySelector('.sticky-topbar').classList.contains("is-stuck");
            if (isTopBarStuck !== this.state.isTopBarStuck) {
                this.setState({isTopBarStuck: isTopBarStuck})
            }
            if (window.pageYOffset >= this.state.stopTop) {
                this.setState({ displayExtended: true });
            } else {
                this.setState({ displayExtended: false })
            }
        }
    };

    renderHeader = () => {
        const priceRender = !!this.props.discountPrice ?
            <React.Fragment>
                <div style={{marginLeft: "10px", display: 'inline-block'}}>
                    <h3  className="cv-type">{this.props.discountPrice}</h3>
                </div>
            </React.Fragment>
            :
            <h3 className="cv-type">{this.props.price}</h3>
            ;
        return(
            <Fragment>
                <div className="grid-x align-baseline">
                    <div className="cell small-9">
                        <h3 className="cv-type">{this.props.header}</h3>
                    </div>
                    <div className="cell small-3 text-right">
                        {priceRender}
                    </div>
                </div>
            </Fragment>
        );
    };


    render() {
        const { displayExtended } = this.state;
        let headerStyle = {
            backgroundColor: this.props.backgroundColor || 'rgba(222, 228, 230, 0.8)',
            display: displayExtended ? 'block' : 'none',
            marginTop: displayExtended && this.state.isTopBarStuck ? '49px' : '0'
        };
        return (
            <div className="sticky-container cv-writing-block package templates-package extended-container">
                <div className={'cv-type-block order sticky extended' + (displayExtended ? ' is-stuck' : '')} style={headerStyle}>
                    {this.renderHeader()}
                </div>
            </div>
        )
    }

}