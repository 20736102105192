class OneSignalActions {

    static addTagsUponCartUpdate(productName, productType, productCheckoutUrl, productImageURL = null) {
        window.onload = () => {
            if(!window.OneSignal) {
                return console.log("OneSignal not available ");
            }
            console.log("OneSignal loaded ");
            OneSignal.push(() => {
                let timestamp = Math.floor(Date.now() / 1000);
                let tags = {
                    cart_update: timestamp,
                    product_name: productName,
                    product_type: productType,
                    product_checkout_url: productCheckoutUrl,
                };
                if(productImageURL){
                    tags.product_image = productImageURL;
                }
                OneSignal.sendTags(tags).then( (tagsSent) => {
                    // Callback called when tags have finished sending
                    console.log(tagsSent);
                });
            });
        }

    }

    static removeCartTags(){
        if(!window.OneSignal) {
            return;
        }
        OneSignal.push(() => {
            OneSignal.sendTags({
                cart_update: "",
                product_name: "",
                product_image: "",
                product_type: "",
                product_checkout_url: ""
            }).then( (tagsSent) => {
                console.log(tagsSent);
            });
        });
    }

}
export default OneSignalActions;