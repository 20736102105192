import React, {useState, useEffect} from "react";
import NewsCollection from './collection/newsCollection';
import PressApi from "../api/pressApi";

export default function News(props) {

    const [collection, setCollection] = useState(props.collection);
    const [page, setPage] = useState(props.page ? parseInt(props.page) + 1 : 2);

    const onLoadCollection = (loadedCollection) => {
        let newCollection = [...collection, ...loadedCollection];
        setCollection(newCollection);
        setPage(page + 1)
    };

    const loadMore = (e) => {
        e.preventDefault();
        PressApi.getList(onLoadCollection, 'news', page)
    };

    const seoProps = {itemType: 'http://schema.org/CollectionPage', itemScope: true};

    return (
            <div className="grid-container" {...seoProps}>
                <h1 className={'press__main-title'}>In the News</h1>
                <meta itemProp="name" content="Latest News"/>
                <meta itemProp="headline" content="Latest News"/>
                <meta itemProp="description" content={props.seoDescription}/>
                <meta itemProp="url" content="/news"/>

                <NewsCollection collection={collection}/>
                <div className="grid-x align-center">
                    {page <= props.totalPages ?
                        <a href={`/news?page=${page}`} onClick={loadMore} className="button button-orange load-more">Load more</a> :
                        null
                    }
            </div>
            </div>
        )

}