import {removeFormatBlock} from "./tynyFunctions";

export function regQuotes(ed) {
    ed.ui.registry.addIcon('quote_icon', '<svg width="24" height="24"><defs>\n' +
        '    <style>\n' +
        '      .ac2d3a4e-7fab-4ade-92b8-d6cee3bf9adf {\n' +
        '        fill: #222f3e;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <title>Quote</title>\n' +
        '  <path class="ac2d3a4e-7fab-4ade-92b8-d6cee3bf9adf" d="M18.13,12.21a3.51,3.51,0,0,0-.63.08c.17-2.37,1.6-4,4.42-5.19L20.24,3.62c-5,2.19-7.3,5.55-7.3,9.92s1.76,6.84,5,6.84A4,4,0,0,0,22,16.08,3.67,3.67,0,0,0,18.13,12.21Z"/>\n' +
        '  <path class="ac2d3a4e-7fab-4ade-92b8-d6cee3bf9adf" d="M7.2,12.21a3.61,3.61,0,0,0-.64.08c.17-2.37,1.6-4,4.43-5.19L9.3,3.62C4.34,5.81,2,9.17,2,13.54s1.76,6.84,5,6.84a4,4,0,0,0,4.06-4.3A3.67,3.67,0,0,0,7.2,12.21Z"/></svg>');

    ed.ui.registry.addIcon('blockquote_icon', '<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs>\n' +
        '    <style>\n' +
        '      .afde4e6e-0986-47a3-b0d4-273dd12706a0 {\n' +
        '        fill: #fff;\n' +
        '      }\n' +
        '\n' +
        '      .ee9aa944-0ebc-488b-9087-0e2f670c12ad {\n' +
        '        fill: #222f3e;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <title>Block-Quote</title>\n' +
        '  <g>\n' +
        '    <path class="afde4e6e-0986-47a3-b0d4-273dd12706a0" d="M7,8.22a1.86,1.86,0,0,0-.33,0A2.94,2.94,0,0,1,8.62,5.77L9,5.61,8.12,3.83A6.94,6.94,0,0,0,5.44,5.77,4.88,4.88,0,0,0,4.38,8.9c0,2.2.9,3.5,2.56,3.5A2,2,0,0,0,9,10.2,1.88,1.88,0,0,0,7,8.22Z"/>\n' +
        '    <path class="afde4e6e-0986-47a3-b0d4-273dd12706a0" d="M13.4,8.22a2.11,2.11,0,0,0-.33,0A3,3,0,0,1,15,5.77l.36-.16-.86-1.78a6.82,6.82,0,0,0-2.67,1.94A4.89,4.89,0,0,0,10.74,8.9c0,2.2.9,3.5,2.56,3.5a2,2,0,0,0,2.08-2.2A1.88,1.88,0,0,0,13.4,8.22Z"/>\n' +
        '  </g>\n' +
        '  <path class="ee9aa944-0ebc-488b-9087-0e2f670c12ad" d="M21.78,5.77H17.09L16.69,5l-.86-1.78-.63-1.3-1.33.58a8.12,8.12,0,0,0-3.46,2.67L10.33,5,9.47,3.17l-.63-1.3-1.33.58A7.74,7.74,0,0,0,3.64,5.77H2.22A2.22,2.22,0,0,0,0,8V19.86a2.22,2.22,0,0,0,2.22,2.22H21.78A2.22,2.22,0,0,0,24,19.86V8A2.22,2.22,0,0,0,21.78,5.77ZM8.12,3.83,9,5.61l-.36.16A2.94,2.94,0,0,0,6.71,8.26a1.86,1.86,0,0,1,.33,0,1.88,1.88,0,0,1,2,2,2,2,0,0,1-2.08,2.2c-1.66,0-2.56-1.3-2.56-3.5A4.88,4.88,0,0,1,5.44,5.77,6.94,6.94,0,0,1,8.12,3.83Zm6.36,0,.86,1.78L15,5.77a3,3,0,0,0-1.91,2.49,2.11,2.11,0,0,1,.33,0,1.88,1.88,0,0,1,2,2,2,2,0,0,1-2.08,2.2c-1.66,0-2.56-1.3-2.56-3.5a4.89,4.89,0,0,1,1.07-3.13A6.82,6.82,0,0,1,14.48,3.83Z"/></svg>');


    ed.ui.registry.addToggleButton('customQuote', {
        icon: 'quote_icon',
        onAction: function () {
            if (ed.formatter.match('customQuoteFormat')) {
                removeFormatBlock('section.quote', false);
            } else {
                ed.execCommand('RemoveFormat');
                ed.execCommand('FormatBlock',true,'customQuoteFormat');

                if (ed.formatter.match('customQuoteFormat')) {
                    let quoteParagraph = ed.selection.getNode();
                    quoteParagraph.setAttribute('contenteditable', true);
                    let quote_node = quoteParagraph.parentNode;
                    let author_node = document.createElement("p");
                    author_node.className = 'author';
                    author_node.appendChild(document.createTextNode("'Enter Author'"));
                    author_node.setAttribute('contenteditable', true);
                    quote_node.appendChild(author_node);
                    quote_node.setAttribute('contenteditable', false);
                }
            }
        },
        onSetup: function (api) {
            ed.formatter.formatChanged('customQuoteFormat', function (state) {
                api.setActive(state);
            });
        }
    });

    ed.ui.registry.addToggleButton('customBlockQuote', {
        icon: 'blockquote_icon',
        onAction: function () {
            if (ed.formatter.match('blockquote')) {
                removeFormatBlock('blockquote', false);
            } else {
                ed.execCommand('RemoveFormat');
                ed.execCommand('FormatBlock', true, 'blockquote');
            }
        },
        onSetup: function (api) {
            ed.formatter.formatChanged('blockquote', function (state) {
                api.setActive(state);
            });
        }
    });
}