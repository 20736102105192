import React, {Fragment, useRef} from 'react';
import PopUp from "../modals/popup";

export default function CopyToClipboard(props) {

    const inputToCopy = useRef(null);
    const modalSuccessCopy = useRef(null);

    const onClick = (e) => {
        e.preventDefault();
        inputToCopy.current.select();
        document.execCommand('copy');
        e.target.focus();
        modalSuccessCopy.current.open();
    };

    return (
        <Fragment>
            <input ref={inputToCopy} type="text" name={props.name} value={props.value} readOnly />
            <a className="button cv-coupon-button" onClick={onClick}>Copy</a>

            <PopUp ref={modalSuccessCopy} className={'add-animation'}>
                <h1 className="text-center">{props.msg}</h1>
                <div className="text-center">
                    <button onClick={() => modalSuccessCopy.current.close()} className="button-orange">Close</button>
                </div>
                <button className="close-button" aria-label="Close modal" type="button" onClick={() => modalSuccessCopy.current.close()}>
                    <div aria-hidden="true"/>
                </button>
            </PopUp>
        </Fragment>
    );

};