import {combineReducers} from 'redux';
import profile from './profileReducer';
import account from './accountReducer';
import article from '../../admin_components/reducers/articleReducer';
import gallery from '../../admin_components/reducers/galleryReducer';
import flexibleForm from './flexibleFormReducer';
import adBanners from  './adBannersReducer';
import pressNavigation from "./pressNavigationReducer";

const rootReducer = combineReducers({
    profile,
    account,
    article,
    gallery,
    flexibleForm,
    adBanners,
    pressNavigation
});

export default rootReducer;