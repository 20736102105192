import React, {Component} from 'react';
import CF from '../common';

class ToggleSwitch extends Component {

    state = {fieldName: CF.generateUniqKey()};

    render() {
        return (
            <div className="switch tiny">
                <input className="switch-input" type="checkbox" id={this.state.fieldName} name={this.props.name}
                       value={this.props.value} checked={this.props.value} onChange={this.props.onChange} />
                <label className="switch-paddle" htmlFor={this.state.fieldName} />
            </div>
        )
    }
}
export default ToggleSwitch;