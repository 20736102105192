import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Profile from './profile';
import Settings from './settings';
import {setAuthorProfile} from '../actions/profileActions';
import store from '../store/store';

export default class Root extends Component {

    componentDidMount() {
        store.dispatch(setAuthorProfile(this.props.user));
    }


    render() {
        return (
            <Provider store={store}>
                {this.props.tab === 'profile' ? <Profile /> : <Settings />}
            </Provider>
        );
    }
}