import React, { Component } from 'react';
import {Loader, ErrorMessage} from 'react-fields';
import AvatarApi from '../api/avatarApi';


class AvatarUploader extends Component {

    state = {
        id: this.props.id || Math.random().toString(36).substring(7),
        errors: [],
        isLoading: false,
        src: null,
        acceptText: this.props.acceptText || ".png, .jpg, .jpeg, .gif",
        uploadMessageVisible: false

    };


    uploadImage = (file) => {
        let data = new FormData();
        data.append('avatar[data]', file);
        data.append('notify_admin', 'true');
        AvatarApi.save(data, this.onLoadFileSuccess, this.onLoadFileError);
    };

    onLoadFileSuccess = (response) => {
        this.setState({isLoading: false});
        let src = response.thumbnails.original.src;
        this.setState({src: src});

    };


    onLoadFileError = (response) => {
        let errors = this.state.errors;
        let values = typeof response !== 'string' ? Object.values(response) : [response];
        errors.push(values);
        this.setState({errors: errors, isLoading: false});
        if (this.props.onError)
            this.props.onError();
    };

    handleOnDataChange = (e) =>{
        let _this = this;
        this.setState({errors: []});
        this.setState({isLoading: true});
        let file = e.target.files[0];
        _this.uploadImage(file);
        document.getElementById(this.state.id).value = "";
    };



    render() {

        let errors = (this.state.errors).map(function(error, i) {
            return (
                <li key={i} className="list-group-error">
                    <ErrorMessage message={error}/>
                </li>)
        });

        let img = this.state.src || (this.props.image ? this.props.image.original : '');

        return (
            <div className="pad-column">
                <label htmlFor={this.state.id}>
                    <div className="avatar-uploader">
                        {this.state.isLoading ? <Loader size="small"/> : null}
                        <div className="circle" onClick={this.onUploadImage}>
                            {img ?
                                <div className="avatar-img" style={{backgroundImage:`url(${ img })`}}/>
                                :
                                <div style={{cursor: 'pointer'}} className="page-icon camera"/>}
                        </div>
                        <div className="circle-on-hover" onClick={this.onUploadImage}>
                            <div className="txt">{img ? `Change` : `Add a`} Profile Picture</div>
                        </div>
                    </div>

                </label>
                <input accept={this.state.acceptText} className="input-file" id={this.state.id} type="file"
                       style={{display: 'none'}}
                       onChange={this.handleOnDataChange} name={this.props.name + '[data]'}/>
                <label className="upload-button" htmlFor={this.state.id}>
                    <span className="button-name">{this.state.buttonName}</span>
                </label>
                {errors}
            </div>
        )
    }
}
export default AvatarUploader;