import React, { Component } from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import * as navigationActions from '../actions/navigationActions';
import NavigationTabs from '../navigation/navigationTabs';
import LeftBarNavigation from "./leftBar";
import RightBarNavigation from "./rightBar";

class Navigation extends Component {

    render(){
        let currentTab = this.props.account.currentTab;
        const leftBar = <LeftBarNavigation/>;
        const rightBar = <RightBarNavigation/>;
        const tabs ={
            profile: {title: ' My Profile', link: "/account/profile"},
            articles: {title: 'My Articles', link: "/account/articles", margin: true},
            submit: {title: "Submit an Article", hide_large: true, link: "/account/submit"},
            settings: {title: 'Account Settings', link: "/account/settings" }
        };
        return (
           <NavigationTabs currentTab={currentTab}
                           leftBar={leftBar}
                           rightBar={rightBar}
                           tabs={tabs}
                           navClass={'author-menu'}
                           navHeight={"large"}
           >
               <div className="grid-container show-for-large-tab-item">
                   <div className="author-welcome">
                       <div className="grid-x">
                           <div className="small-12 cell">
                               <h1 className="text-center">Welcome, {this.props.user.first_name}</h1>
                           </div>
                       </div>
                   </div>
               </div>
           </NavigationTabs>
        )
    }
}
Navigation.propTypes = {
    account: PropTypes.object.isRequired

};
function mapStateToProps(state) {
    return {
        account: state.account

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, navigationActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation);