import React, { Component } from 'react';
import {ErrorTooltip} from 'react-fields';

class TextFieldWithIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id || Math.random().toString(36).substring(7),
            className: this.props.className || "",

        };
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(e){
        if(e.key === 'Enter'){
            e.preventDefault();
        }
        if (this.props.onKeyPress){
            this.props.onKeyPress(e);
        }
    }

    render() {
        let inputAttrs = {  value: this.props.value || "",
            onChange: this.props.onChange,
            onClick: this.props.onClick,
            onKeyDown: this.props.onKeyDown,
            placeholder: this.props.placeholder,
            disabled: this.props.disabled,
            type: 'text',
            tabIndex: this.props.tabindex,
            name: this.props.name,
            onKeyPress: this.handleKeyPress,
            id: this.state.id };

        if (this.props.maxlength) { inputAttrs['maxLength'] = this.props.maxlength; }
        if (this.props.minValue) { inputAttrs['min'] = this.props.minValue; }
        let className = this.state.className + (this.props.errorMessage ? " err" : "");
        return  (
            <label className={className} title={this.props.title}>
                <input {...inputAttrs}/>
                <ErrorTooltip message={this.props.errorMessage}/>
            </label>
        );
    }
}
export default TextFieldWithIcon;