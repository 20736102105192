import {SET_USER, CHANGE_USER, CHANGE_USER_DETAIL, ADD_USER_DETAIL, DELETE_USER_DETAIL} from '../actions/actionTypes';
import initialState from './initialState';
import CF from "../common";



export default function user(state = initialState.user, action) {
    switch (action.type) {
        case SET_USER:
            let initialUser = action.user;
            let details = initialUser.user_details.map((ex) => {
                return {...ex, ...{key: CF.generateUniqKey(), errors: {}}}
            });
            return { ...initialUser,
                author_type: action.user.author_type || 'approved',
                initialPath: action.user.path,
                details: details,
                detailsDeleted: [],
                topicAutocomplete: {name: '', selectedItem: null},
                errors: {}};
        case CHANGE_USER:
            return Object.assign({}, state, action.user);
        case CHANGE_USER_DETAIL:
            return Object.assign({}, state, {details: state.details.map(item => {
                    if(item.key === action.detail.key){
                        return { ...item, ...action.detail}
                    }
                    return item
                })});
        case ADD_USER_DETAIL:
            let newDetail = new Object({detail_type: action.detail_type, key: CF.generateUniqKey(), errors: {} });
            return Object.assign({}, state, {details: [...state.details, newDetail]});
        case DELETE_USER_DETAIL:
            let detailsAfterDelete = Object.assign([], state.details);
            let index = state.details.findIndex(f => f.key === action.detail.key);
            let deletedDetail = state.details[index];
            detailsAfterDelete.splice(index, 1);
            return Object.assign({}, state, {details: detailsAfterDelete, detailsDeleted: [...state.detailsDeleted, deletedDetail]});
        default:
            return state;
    }
}