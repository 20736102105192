import React from "react";
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "../subscriptionActionHOC";

const SubscriptionForm = SubscriptionActionHOC(props => {
    return (
        <div className="side-ad__input-block">
            <InputTypeField className={'side-ad__input'} type='email' name="email" value={props.form.email}
                            onChange={props.setEmail} errorMessage={props.form.errors.email}
                            placeholder="Enter email"
                            onKeyPress={props.submitOnEnter}
            />
            <div className="side-ad__submit" onClick={props.submitForm}>
                <span className="send-icon"/>
            </div>
        </div>
    )
});

//SubscribeAd id={'SubscribeSidebarAd'} for GTM!
class SubscriptionAdDark extends React.Component {

    render() {

        return (
            <div className="SubscribeAd" id={'SubscribeSidebarAd'}>
                <div className="side-ad__block">
                    <div className="side-ad__line"/>
                    <div className={'grid-x align-middle'}>
                        <div className={'large-12 medium-5 small-10 cell'}>
                            <h3 className={'side-ad__title'}>Subscribe</h3>
                            <p className="side-ad__desc">For more great career advice!</p>
                            <p className={'side-ad__offer'}>GET <span>10% OFF</span> at <a href="https://www.careerhunter.io/?utm_source=careeraddict&utm_medium=native&utm_campaign=subscribe"
                                                                                           target="_blank" className="side-ad__logo">Career<span>Hunter</span></a>™</p>
                        </div>
                        <div className="large-12 large-offset-0 medium-offset-1 medium-5 small-12 cell">
                            <p className={'side-ad__coupon'}>CLAIM YOUR COUPON</p>
                            <SubscriptionForm formType='banner' design={'sidebar'} />
                        </div>
                    </div>
                </div>
            </div>)
    }
}


export default SubscriptionAdDark;
