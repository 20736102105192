import React from "react";
import {TextField, Loader, ErrorMessage, actions, InputTypeField} from 'react-fields';
import LoginApi from '../api/login';
import CustomCheckboxField from '../customFields/checkbox';

class Form extends React.Component {
        state = {
            user: {...this.props.user, ...{errors: {}, remember_me: false}},
            isLoading: false
        };


    resetErrors = () => {
        let user = this.state.user;
        user.errors = {};
        this.setState({user: user});
    };


    handleFieldChange = (e) => {
        let field_name = e.target.name;
        let user = this.state.user;
        user[field_name] = field_name === 'remember_me' ? e.target.checked : e.target.value;
        user.errors[actions.camelize(field_name)] = "";
        this.setState({user: user});
    };

    renderErrors = (errors) => {
        this.setState({isLoading: false});
        let user = this.state.user;
        user.errors = errors;
        this.setState({user: user});
    };

    handleSubmit = (e) => {
        if (e)
            e.preventDefault();
        this.setState({isLoading: true});
        this.resetErrors();
        LoginApi.login(this.state.user, this.onSuccess, this.renderErrors)
    };

    onSuccess = (response) => {
        if (this.props.afterLogin) {
            this.props.afterLogin(response);
        } else {
            window.location.href =  !this.props.continuePath ? '/account/profile' : this.props.continuePath;
        }

    };

    submitOnEnter = (e) => {
        if(e.key === 'Enter'){
            this.handleSubmit();
        }
    };



    render(){
        let user = this.state.user;
        return(

            <form onSubmit={this.handleSubmit} style={{position: 'relative'}}>
                {this.state.isLoading ? <Loader size="small" /> : null}
                <div className="grid-x">
                    <div className="small-12 cell">
                        <TextField name="email" onChange={this.handleFieldChange} value={user.email}
                                   placeholder="Email*" errorMessage={user.errors.email} />
                    </div>
                    <div className="small-12 cell">
                        <InputTypeField onKeyPress={this.submitOnEnter} name="password"
                                        onChange={this.handleFieldChange} value={user.password}
                        placeholder="Password*" type="password" errorMessage={user.errors.password}/>
                    </div>
                    <ErrorMessage message={user.errors.base}/>
                </div>
                <div className="grid-x form-buttons align-middle">
                    <div className="small-6 cell">
                            <CustomCheckboxField label="Keep me signed in"
                                                 onChange={this.handleFieldChange}
                                                 value={user.remember_me}
                                                 name="remember_me"
                            />
                    </div>
                    <div className="small-6 cell text-right">
                        <button className="button-orange" onClick={this.handleSubmit}>Log In</button>
                    </div>
                </div>
               </form>
        );
    }
}
export default Form;