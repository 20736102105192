import { SET_CATEGORY, CHANGE_CATEGORY} from '../actions/actionTypes';
import initialState from './initialState';



export default function category(state = initialState.category, action) {
    switch (action.type) {
        case SET_CATEGORY:
            let initialCategory = action.category;
            return { ...initialCategory,
                topicAutocomplete: {name: '', selectedItem: null},
                errors: {}
            };
        case CHANGE_CATEGORY:
            return Object.assign({}, state, action.category);
        default:
            return state;
    }
}