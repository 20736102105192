import { SET_INITIAL_STATE_FLEXIBLE_FORM, NEXT_STEP_FLEXIBLE_FORM, PREV_STEP_FLEXIBLE_FORM, CHANGE_FIELD_FLEXIBLE_FORM,
    RESET_FIELDS_FLEXIBLE_FORM, SUBMIT_FLEXIBLE_FORM_ERRORS } from './actionTypes';

export function setForm(form) {
    return {
        type: SET_INITIAL_STATE_FLEXIBLE_FORM,
        form: form
    };
}

export function nextStepForm() {
    return {
        type: NEXT_STEP_FLEXIBLE_FORM
    };
}
export function prevStepForm() {
    return {
        type: PREV_STEP_FLEXIBLE_FORM
    };
}
export function changeField(field) {
    return {
        type: CHANGE_FIELD_FLEXIBLE_FORM,
        field: field
    };
}
export function resetForm() {
    return {
        type: RESET_FIELDS_FLEXIBLE_FORM
    };
}
export function submitFormError(errors) {
    return {
        type: SUBMIT_FLEXIBLE_FORM_ERRORS,
        errors: errors
    };
}

