import React from "react";

class CustomCheckboxField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            className: this.props.className || "",
            position: this.props.position || 'left',
            id: this.props.id || this.props.name,
            sizes: this.props.sizes || [12,10]
        }
    }

    render() {

        return (
            <div className={`relative-checkbox ${this.state.className}`}>
                <label>
                    <input
                        type="checkbox"
                        value={this.props.value || false}
                        onChange={this.props.onChange}
                        name={this.props.name}
                        checked={this.props.value || false}
                        disabled={this.props.disabled || false}
                        className="custom-checkbox-input"
                        id={this.state.id}
                    />

                    <div className={"custom-checkbox" + (this.props.value ? ' checked' : '') + (this.props.disabled ? ' disabled' : '')}>
                        {this.props.useTickMark ?
                            <div className={'tick-mark'}/>
                            :
                            <svg width="12px" height="10px" viewBox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"/>
                            </svg>
                        }
                    </div>
                    {this.props.label}
                </label>
            </div>
        )

    }
}
export default CustomCheckboxField;