export default {
    article: {errors: {}, sources: [], topicAutocomplete: {}, topics: []},
    htmlBlock: {errors: {}},
    category: {errors: {}, topicAutocomplete: {}, topics: []},
    cv_writing_package: {errors: {}, cv_templates: [], extras: []},
    cvTemplate: {errors: {}, archive: {}, images: []},
    gallery: {deletedIds: [], uploadedIds: []},
    user: {errors: {}, details: [], topicAutocomplete: {}, topics: []},
    form: {errors: {}},
    fields: [],
    pressArticle: {errors: {}, takeaways: [], file_attachment: {}, articleLoaded: false, charLeft: {}}
}