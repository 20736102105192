
import * as types from './actionTypes';


export function setInitialTab() {
    return {
        type: types.SET_INITIAL_AUTHOR_TAB

    };
}

export function setProfileTab() {
    return {
        type: types.SET_PROFILE_AUTHOR_TAB

    };
}
export function setSettingsTab() {
    return {
        type: types.SET_SETTINGS_AUTHOR_TAB

    };
}
export function setArticlesTab() {
    return {
        type: types.SET_ARTICLES_AUTHOR_TAB

    };
}
export function setSubmitTab() {
    return {
        type: types.SET_SUBMIT_AUTHOR_TAB

    };
}