import React from "react";
import StudyItem from './studyItem';

export default function StudyCollection(props) {

    return (
        <div className="grid-x">
            {props.collection.map((article) => {
                return <StudyItem key={article.id} article={article}/>
            })}
        </div>


    )
}