import React, { Component } from 'react';
import CollectionForSelect from '../../../../components/base/collectionForSelect';

class ProductShortcodeModal extends Component {

    state = {
        id: this.props.id || Math.random().toString(36).substring(7),
        selectedProductId: null, errors: [],
        isOpened: false
    };

    handleFieldChange = (e) => {
        this.setState({selectedProductId: e.target.value, errors: []})
    };

    submit = () => {
        if (this.state.selectedProductId) {
            this.props.onSave(this.state.selectedProductId);
            this.props.close();
        } else {
            this.setState({errors: {selectedProductId: "Product can't be blank"}});
        }
    };

    submitAdSpot = () => {
        this.props.onSave(null);
        this.props.close();
    };

    render() {
        return(
            <React.Fragment>
                <h1>Add a Product</h1>
                <CollectionForSelect name="selectedProductId" placeholder="Product"
                                     onChange={this.handleFieldChange}
                                     url="/api/ad_banners" optionsValue='id' optionsLabel='name'
                                     selected={this.state.selectedProductId}
                                     errorMessage={this.state.errors.selectedProductId} />
                <button className="button-orange" onClick={this.submit}>Insert into Post</button>
                <h1>OR an Ad Spot</h1>
                <button className="button-orange" onClick={this.submitAdSpot}>Insert Ad Spot</button>
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </React.Fragment>
        )
    }

}
export default ProductShortcodeModal;