import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Form from './form';
import {setForm} from '../actions/flexibleFormActions';
import store from '../store/store';

export default class Root extends Component {

    componentDidMount() {
        store.dispatch(setForm(this.props.form));
    }


    render() {
        return (
            <Provider store={store}>
                <Form recaptcha_key={this.props.recaptcha_key} subheader={this.props.subheader} />
            </Provider>
        );
    }
}