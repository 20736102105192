export function regCustomBullets(ed) {
    ed.ui.registry.addSplitButton('bullistc', {
        tooltip: 'Bullet list',
        icon: 'unordered-list',
        presets: 'listpreview',
        columns: 3,
        fetch: function (callback) {
            const items = [{
                type: 'choiceitem',
                icon: 'list-bull-default',
                text: 'Default',
                value: 'default'
            },{
                type: 'choiceitem',
                icon: 'list-bull-circle',
                text: 'Circle',
                value: 'circle'
            },{
                type: 'choiceitem',
                icon: 'list-bull-square',
                text: 'Square',
                value: 'square'
            },{
                type: 'choiceitem',
                icon: 'selected',
                text: 'Tick',
                value: 'tick'
            },{
                type: 'choiceitem',
                icon: 'close',
                text: 'Cross',
                value: 'cross'
            }];

            callback(items);
        },
        onAction: function () {
            return ed.execCommand('InsertUnorderedList');
        },
        onItemAction: function (_splitButtonApi, value) {
            console.log(_splitButtonApi);
            tinymce.activeEditor.execCommand('InsertUnorderedList', false, (value === 'tick' || value === 'cross') ? null : {'list-style-type': value});

            const listNode = tinymce.activeEditor.dom.getParent(tinymce.activeEditor.selection.getNode(), "ul");
            console.log(listNode);
            if (value === 'tick' && listNode) {tinyMCE.activeEditor.dom.addClass(listNode, 'list--tick');}
            if (value === 'cross' && listNode) {tinyMCE.activeEditor.dom.addClass(listNode, 'list--cross');}
        },
        select: function (value) {
            console.log('onSelect');
            return false;
            //var listStyleType = getSelectedStyleType(editor);
            // var listElm = ed.dom.getParent(ed.selection.getNode(), 'ol,ul');
            // var style = ed.dom.getStyle(listElm, 'listStyleType');
            // var listStyleType = {
            //     some: some,
            //     none: none,
            //     from: from
            // }.from(style);
            //
            // return listStyleType.map(function (listStyle) {
            //     return value === listStyle;
            // }).getOr(false);
        },
        onSetup: function (api) {
            console.log('onSetup');
            var nodeChangeHandler = function (eventApi) {
                const node = eventApi.element.closest("li");
                api.setActive(!!node || eventApi.element.nodeName === 'LI');
            };
            ed.on('NodeChange', nodeChangeHandler);
            return function () {
                return ed.off('NodeChange', nodeChangeHandler);
            };
        }
    });
}