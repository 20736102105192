import React from "react";
import {TextField, TextareaField, NumberField, SelectField,
     ErrorMessage, InputTypeField, actions} from 'react-fields';
import Multiselect from "./multiselect";
const phoneRegExp = /^[0-9\.\b()\+\-\s]+$/;



class Field extends React.Component {

        state ={
           [`field_${this.props.field.id}`]: '',
           tabIndex: parseInt(this.props.field.order) + 3,
           className: this.props.field.style === 'half' ? "medium-12 large-6 cell" : "medium-12 cell",
            countriesList: []
        };



    componentDidMount(){
        let _this = this;
        if(this.props.field.content_type === 'country'){
            fetch(actions.getData("/api/countries/"))
                .then(actions.status)
                .then(actions.json)
                .then(function(data) {
                    _this.setState({countriesList: data.map((country) => country.name)})
                    });

        }
    }


    showLimit = () => {
        return (this.props.field.limit && this.props.field.content_type !== 'dropdown');
    };

    handleFieldChange = (e) => {
        let field = this.props.field;
        let value = this.checkLimit(e);
        if (field.content_type !== 'phone' || (value === '' || phoneRegExp.test(value))) {
            field.value = value;
            field.error = "";
            this.props.onChange(field);
        }
    };

    handleMultiselectChange = (value) => {
        let field = this.props.field;

        if(field.limit && value.length > field.limit) {
            field.error = `Maximum selectable values is ${field.limit}`;
        } else {
            field.value = value;
            field.error = "";
        }

        this.props.onChange(field);
    };

    checkLimit(e) {
        let value = e.target.value;
        let field = this.props.field;
        if (this.showLimit()) {
            let limit = parseInt(field.limit);
            if(value.length > limit) {
                value = value.substring(0, limit);
            }

        }
        return value;
    };

    render(){
        let field = this.props.field;
        let placeholder = field.placeholder + (field.required ? '*' : '');
        let attributes = {name: `field_${field.id}`,
            value: field.value,
            tabindex: this.state.tabIndex,
            errorMessage: field.error,
            className: "",
            placeholder: placeholder,
            onChange: field.content_type === 'multiselect' ? this.handleMultiselectChange : this.handleFieldChange,
            errorType: "tooltip"};
        if (this.showLimit()){
            attributes.hintText = (parseInt(field.limit) - field.value.length)
        }

        if (['dropdown', 'country', 'subject', 'multiselect'].includes(field.content_type)){
            attributes.options = field.content_type === 'country' ? this.state.countriesList :
                field.selectable_values.split(',').map((s) => {return s.split('|')[0]});
            attributes.selected = field.value;
            attributes.required = true;
            attributes.className = "label-icon-right select-label"
        }

        switch(field.content_type) {
            case 'email':
                return <InputTypeField type='email' {...attributes} />;
            case 'phone':
                return <InputTypeField type='tel' {...attributes} />;
            case 'string':
                return field.input_type ? <InputTypeField {...attributes} /> : <TextField {...attributes} />;
            case 'number':
                return <NumberField {...attributes} />;
            case 'message':
                return <TextareaField {...attributes} rows={5} />;
            case 'dropdown':
                return <SelectField {...attributes} />;
            case 'subject':
                return <SelectField {...attributes} />;
            case 'country':
                return <label className="select-label">
                    <SelectField {...attributes} />
                </label>;
            case 'multiselect':
                return <Multiselect {...attributes} />;
        }
    }
}
export default Field;