import {SET_INITIAL_CV_TEMPLATE, CHANGE_CV_TEMPLATE} from "../actions/actionTypes";
import initialState from "./initialState";
import CF from "../common";

export default function cvTemplate(state = initialState.cvTemplate, action) {
    switch (action.type) {
        case SET_INITIAL_CV_TEMPLATE:
            let initialAttributes = {
                isSetUp: true,
                deletedImages: [],
                errors: {},
                archive: action.cvTemplate.archive || {},
                images: action.cvTemplate.images.map((image, i) => {return { ...image, ...{key: CF.generateUniqKey(), order: image.order || i}}})
            };
            return { ...action.cvTemplate, ...initialAttributes };
        case CHANGE_CV_TEMPLATE:
            return Object.assign({}, state, action.cvTemplate );
        default:
            return state;
    }
}