import {actions} from 'react-fields'

class RegisterApi {

    static register(user, token, onSuccess, onError) {
        const url = '/register';
        let data = { user: {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                password: user.password,
                password_confirmation: user.password_confirmation,
                email_confirmation: user.email_confirmation,
                agree_terms: user.agree_terms
            }, g_recaptcha_response: token };

        fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static changeEmail(user, onSuccess, onError) {
        const url = '/register';
        let data = {user:
                {
                    email: user.new_email || "",
                    email_confirmation: user.email_confirmation,
                    current_password: user.current_password}};

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( (response) => {

            onSuccess(response);

        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static changePassword(user, onSuccess, onError) {
        const url = '/register';
        let data = {user:
                {
                    password: user.new_password || "",
                    password_confirmation: user.password_confirmation || "",
                    current_password: user.current_password}};

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then(  (response) => {

            onSuccess(response);

        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

}

export default RegisterApi;