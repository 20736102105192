class CommonFunctions {

    static formatMoney(n, cur) {
        if (n === 0) {
            return 'FREE';
        }
        var c = 2,
            t = ",",
            i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
            j = (j = i.length) > 3 ? j % 3 : 0,
            cur = cur || '$';

        return cur + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t);
    }

    static colorLuminance(hex, lum) {

        // validate hex string
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
        }
        lum = lum || 0;

        // convert to decimal and change luminosity
        var rgb = "#", c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i*2,2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00"+c).substr(c.length);
        }

        return rgb;
    }

    static scrollToErrorSimple() {
        let errorTag = document.querySelector('div.err, small.error');
        if (!errorTag) { return }
        let errorOffset = errorTag.offsetTop;
        if(errorOffset) {
            window.scrollTo({
                top: errorOffset - 200,
                behavior: "smooth"
            });
        }
    }

    static scrollToError() {
        const errorTag = document.querySelector('div.err, small.error');
        if (!errorTag) { return }
        const errorOffset = errorTag.offsetTop;
        const start = Math.round(window.pageYOffset);
        let startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const topBarHeight = document.querySelector('#topBar').clientHeight;
        const extendedBlockHeight = document.querySelector('.extended') ? document.querySelector('.extended').clientHeight : 0;
        let destinationOffsetToScroll = Math.round(documentHeight - errorOffset < windowHeight ? documentHeight - windowHeight : errorOffset) - (topBarHeight + extendedBlockHeight);
        function easeInOutCubic(t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        }
        function scroll() {
            let now = 'now' in window.performance ? performance.now() : new Date().getTime();
            let time = Math.min(1, ((now - startTime) / 800));
            let timeFunction = easeInOutCubic(time);
            window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

            if (Math.round(window.pageYOffset) === destinationOffsetToScroll) {
                return;
            }

            requestAnimationFrame(scroll);
        }

        if (errorOffset) {
            if ('requestAnimationFrame' in window === false) {
                window.scroll(0, destinationOffsetToScroll);
                return;
            }
            scroll();
        }
    }

    static slideToggle(target, duration = 500) {
        let slideUp = (target, duration=500) => {
            target.style.transitionProperty = 'height, margin, padding';
            target.style.transitionDuration = duration + 'ms';
            target.style.boxSizing = 'border-box';
            target.style.height = target.offsetHeight + 'px';
            target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            window.setTimeout( () => {
                target.style.display = 'none';
                target.style.removeProperty('height');
                target.style.removeProperty('padding-top');
                target.style.removeProperty('padding-bottom');
                target.style.removeProperty('margin-top');
                target.style.removeProperty('margin-bottom');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        };

        let slideDown = (target, duration=500) => {
            target.style.removeProperty('display');
            let display = window.getComputedStyle(target).display;

            if (display === 'none')
                display = 'block';

            target.style.display = display;
            let height = target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            target.offsetHeight;
            target.style.boxSizing = 'border-box';
            target.style.transitionProperty = "height, margin, padding";
            target.style.transitionDuration = duration + 'ms';
            target.style.height = height + 'px';
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            window.setTimeout( () => {
                target.style.removeProperty('height');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        };
        if (window.getComputedStyle(target).display === 'none') {
            return slideDown(target, duration);
        } else {
            return slideUp(target, duration);
        }
    };

    static btoa = (str) => {
        return Buffer.from(str).toString('base64');
    };

    static atob = (str) => {
        return Buffer.from(str, 'base64').toString();
    };

}
export default CommonFunctions;