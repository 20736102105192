import React from 'react';
import PropTypes from "prop-types";

export const Topic = props => {
    return (<div style={{width: 'auto',padding:'0 5px'}}>
        <div className="grid-x topic-selected">
            <div className="cell auto">
                <p>{props.name}</p>
            </div>
            <div className="cell shrink">
                <div className="remove" onClick={props.delete}> <span className="small-icon grey-cross"/> </div>
            </div>
        </div>
    </div>);
};

Topic.propTypes = { name: PropTypes.string, delete: PropTypes.func };

export default Topic;