import React, { Component } from 'react';
import {Loader} from "react-fields";

class DeleteFromGalleryModalContent extends Component {

    state = {isRemoved: false, isLoading: false};

    open = (galleryItemId) => {
        this.setState({isRemoved: false, isLoading: false, galleryItemId: galleryItemId}, () => {
            this.galleryDeleteModal.current.open();
        })
    };

    onDelete = () => {
        this.setState({isLoading: true}, () => {
            this.props.onDelete(this.onSuccess, this.onError)
        });
    };

    onSuccess = () => {
        this.setState({isLoading: false, isRemoved: true});
    };

    onError = (errors) => {
        this.setState({isLoading: false, isRemoved: errors['responseStatus'] && errors['responseStatus'] === 404});
    };

    render() {
        if (this.state.isRemoved) {
            return (
                <React.Fragment>
                    <h1>Image was removed</h1>
                    <div className={'buttons-area text-center'}>
                        <button className="button-orange" onClick={this.props.close}>Close</button>
                    </div>
                    <button className="close-button" aria-label="Close modal" type="button" onClick={this.closeConfirmation} />
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                {this.state.isLoading ? <Loader size="unconfirmed"/> : null}
                <h1>Delete From Gallery?</h1>
                <div className={'buttons-area text-center'}>
                    <button className="button-orange" onClick={this.onDelete}>Yes</button>
                    <button className="button-orange" onClick={this.props.close}>No</button>
                </div>

                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </React.Fragment>
        )
    }

}
export default DeleteFromGalleryModalContent;