import React, {Component, Fragment} from 'react';
import {SelectField, CheckboxField, TextField} from "react-fields";

type Button = {url: string,  label: string, small_label: string, rel?: string, target_blank?: boolean };

interface Props {
    openGallery: (galleryItem: {}, insertMediaItem: (item: {}, key: string) => void ) => void,
    onSave: (item: {}, key: string, button: {}) => void,
    close: () => void,
    delete: () => void
}

type Errors = {
    image?: string,
    label?: string,
    small_label?: string,
    url?: String
}


type State = {
    image: {},
    image_key: string,
    button: Button,
    errors: Errors,
    mode: string
};

type Rel = {
    name: string,
    value: string
}

const RELS: Rel[] =  [{name: 'Follow', value: ''},{name: "Nofollow, Sponsored", value: 'nofollow sponsored'},
    {name: 'Sponsored', value: 'sponsored'}, {name: 'Nofollow', value: 'nofollow'}];



class RecommendedProductModal extends Component<Props, State> {


    state = {
        image: {original_path: null},
        image_key: null,
        button: {label: "", small_label: "", rel: null, target_blank: false, url: "", use_aside_tag: true},
        errors: {image: "", label: "", small_label: "", url: ''},
        mode: 'new'
    };


    openGallery = (galleryItem?: {}) => {
        this.props.openGallery(galleryItem, this.insertMediaItem);
    };

    insertMediaItem = (item, key) => {
        this.setState({image: item, image_key: key});
    };

    isValid = () => {
        let button = this.state.button;
        let image = this.state.image;
        let errors = this.state.errors;
        let valid = true;
        if(!button.label) {
            errors.label = "Label can't be blank";
            valid = false;
        }
        if(!button.small_label) {
            errors.small_label = "Small label can't be blank";
            valid = false;
        }
        if(!image || !image.original_path) {
            errors.image = "Image can't be blank";
            valid = false;
        }
        if(!button.url) {
            errors.url = "Url can't be blank";
            valid = false;
        }
        this.setState({errors: errors});
        return valid;
    };

    handleFieldChange = (e) => {
        let button = this.state.button;
        let errors = this.state.errors;
        button[e.target.name] = e.target.name === 'target_blank' || e.target.name === 'use_aside_tag' ? e.target.checked : e.target.value;
        errors[e.target.name] = '';
        this.setState({button: button, errors: errors});
    };

    set = (image, image_key, button, mode) => {
       this.setState({image: image, image_key: image_key, button: button, mode: mode});
    };


    submit = () => {
        if (this.isValid()) {
            this.props.onSave(this.state.image, this.state.image_key, this.state.button);
            this.props.close();
        }
    };


    render() {
        return(
            <React.Fragment>
                <h1>Add a Product</h1>

                {this.state.image.original_path  && <img src={this.state.image.original_path} style={{maxWidth: '150px'}} className={'mar-b-10'} />}
                <button className="button-orange mar-b-10" onClick={() => this.openGallery()}>Upload/Select Image</button>
                <TextField name={'label'}
                           onChange={this.handleFieldChange}
                           placeholder={'Label text'}
                           className={'mar-b-10'}
                           value={this.state.button.label}
                           errorMessage={this.state.errors.label} />
                <TextField name={'small_label'}
                           onChange={this.handleFieldChange}
                           placeholder={'Small label text'}
                           className={'mar-b-10'}
                           value={this.state.button.small_label}
                           errorMessage={this.state.errors.small_label} />
                <TextField name={'url'}
                           onChange={this.handleFieldChange}
                           placeholder={'URL'}
                           className={'mar-b-10'}
                           value={this.state.button.url}
                           errorMessage={this.state.errors.url} />

                <SelectField name="rel" placeholder="Rel" className='label-icon-right select-label mar-b-10'
                             onChange={this.handleFieldChange}
                             options={RELS}
                             optionsLabel={'name'}
                             optionsValue={'value'}
                             selected={this.state.button.rel}
                              />
                <CheckboxField onChange={this.handleFieldChange}
                               name='target_blank'
                               className={'mar-b-10'}
                               value={this.state.button.target_blank}
                               label='Open in new window'
                />
                <div className="field with-tooltip">
                    <CheckboxField onChange={this.handleFieldChange}
                                   name='use_aside_tag'
                                   className={'mar-b-10'}
                                   value={this.state.button.use_aside_tag}
                                   label='Aside tag'
                    />
                    <div className="tooltip-trigger">?
                        <span className="tooltip top">To be selected when this component is NOT required to understand the content. So in cases of promotions/advertising, comments, pullquotes, glossary, footnotes that’s tangentially related to the page or content. </span>
                    </div>
                </div>
                <button className="button-orange" onClick={this.submit}>Insert into Post</button>
                {this.state.mode === 'edit' && <button className="button-orange" onClick={this.props.delete}>Remove from Post</button>}
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </React.Fragment>
        )
    }

}
export default RecommendedProductModal;