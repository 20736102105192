import React, {Component, Fragment} from "react";
import {actions} from "react-fields";
import ArticlesCarousel from '../carousels/articles';

class MainCategory extends Component {

    state = {
        subcategoriesList: []
    };

    componentDidMount() {
        let _this = this;
        let url = `/api/category/${this.props.id}/subcategories`;
        fetch(actions.getData(`${url}`))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({subcategoriesList: data});
            });
    }

    renderSubcategory = (c) => {
        return(
            <div className="categories-container" key={`subcategory${c.id}`}>
                <div className="grid-container noPadding">
                    <div className="grid-x">
                        <div className="large-3 medium-5 small-12 cell medium-padding">
                            <div className="grid-x align-middle">
                                <div className="small-12 show-for-medium">
                                    <img src={c.icon_path} alt={c.name} />
                                </div>
                                <div className="medium-12 small-9">
                                    <h3 className="category-name">{c.name}</h3>
                                </div>
                            </div>
                            <h5 className="category-description">{c.description}</h5>
                            <p className="see-all">
                            <a href={c.path} className="read-link" style={{color: this.props.color}}>See all Articles</a>
                            </p>
                        </div>
                        <div className="large-9 medium-7 small-12 cell">
                            <ArticlesCarousel articles={c.articles} />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        let _this = this;
        return(
            <Fragment>
                {this.state.subcategoriesList.map(function (c) {
                    return (_this.renderSubcategory(c));
                })}
            </Fragment>
        )
    }

}
export default MainCategory;