import React from "react";

import {ErrorMessage} from 'react-fields';


class TermsAgree extends React.Component {



    render(){
        return(
            <div className="grid-x">
                <div className="medium-7 cell">
                    <div className="relative_checkbox">
                        <label>
                                <span>I accept the <a href="/terms" className="terms" target="_blank">Terms</a> and <a href="/privacy" target="_blank" className="terms">Privacy</a>
                                   <input type="checkbox" name="agreeTerms" className="custom-checkbox-input"
                                          checked={this.props.agreeTerms}
                                          onChange={this.props.onChange}/>
                                </span>
                            <div className={this.props.agreeTerms ? 'custom-checkbox checked': 'custom-checkbox '} onChange={this.props.onChange}><span></span></div>
                        </label>
                    </div>
                    <ErrorMessage message={this.props.agreeTermsError}/>

                </div>
            </div>

        )
    }
}
export default TermsAgree;

