import cf from '../common';

class ArticleValidation {

    static validate(article, state) {
        let errors = {};

        if (!article.name)
            errors['name'] = `Name can't be blank`;

        errors = {...errors, ...this.validateVideo(article)};


        let sourcesErrors = {};
        article.sources.map(function (source) {
            let sourceErrors = {};
            if (!source.name)
                sourceErrors['name'] = 'This field is required';

            if (!source.url)
                sourceErrors['url'] = 'This field is required';
            else if (!cf.is_valid_url(source.url)) {
                sourceErrors['url'] = 'URL is not valid';
            }

            if (Object.keys(sourceErrors).length) {
                sourcesErrors[source.key] = sourceErrors;
            }
        });
        if (Object.keys(sourcesErrors).length) { errors['sources'] = sourcesErrors; }

        if (state === 'draft')
            return errors;

        ['content', 'category_id'].map((name) => {
            if (!article[name])
                errors[cf.camelize(name)] = 'This field is required';
        });


        let topicAutocomplete = article.topicAutocomplete;
        let topicSelected = (topicAutocomplete.selectedItem && topicAutocomplete.selectedItem.id) || topicAutocomplete.name;
        if (article.topics.length === 0 && !topicSelected && !article.advertorial) {
            errors['topics'] = 'This field is required';
        }

        if (article.publish_date || article.publish_time) {
            ['publish_date', 'publish_time'].map((name) => {
                if (!article[name])
                    errors[cf.camelize(name)] = 'This field is required';
            });
        }

        const videoShortcodeCounts = (article.content.match(/\[Video\]/g) || []).length;
        if (!!article.video_url) {
            if ( videoShortcodeCounts === 0) { errors['content'] = 'Should have a Video shortcode' }
            if ( videoShortcodeCounts > 1) { errors['content'] = 'Should have only one Video shortcode' }
        } else {
            if ( videoShortcodeCounts > 0) { errors['content'] = 'Should not have a Video shortcode' }
        }

        if (state !== 'published')
            return errors;

        if (article.cpCategory) {
            ['industry_id', 'job_title', 'job_description'].map((name) => {
                if (!article[name])
                    errors[cf.camelize(name)] = 'This field is required';
            });
        }

        if (!!article.video_url && !article.video_id) {
            errors['videoUrlInfo'] = 'Video Info is required';
        } else if (!!article.video_url && !errors['videoUrl'] && !article.video_url.includes(article.video_id)) {
            errors['videoUrlInfo'] = 'Video Info is not valid';
        }

        if (!!article.video_url && !article.video_type)
            errors['videoType'] = 'Video Type is required';

        if (!article.featured_image || (article.featured_image && !article.featured_image.id))
            errors['featuredImage'] = 'Featured image is required';

        ['path', 'publish_date', 'publish_time', 'share_message', 'meta_title', 'meta_description'].map((name) => {
            if (!article[name])
                errors[cf.camelize(name)] = 'This field is required';
        });

        if (!article.skipIntroValidation && !article['subtitle']) {
            errors[cf.camelize('subtitle')] = 'This field is required';
        }

        if (!(article['user'] || article.advertorial)) {
            errors['user'] = 'This field is required';
        }

        // if (article.sponsored_display) {
        //     ['sponsor_name', 'sponsor_url', 'sponsor_desc', 'sponsor_logo'].map((name) => {
        //         if (!article[name])
        //             errors[cf.camelize(name)] = 'This field is required';
        //     });
        // }

        return errors;
    }

    static validateVideo(article) {
        let errors = {};
        const videoUrl = article.video_url;

        if (!videoUrl)
            return errors;

        if (!cf.is_valid_url(videoUrl) || (!videoUrl.includes('www.youtube.com') && !videoUrl.includes('youtu.be'))) {
            errors['videoUrl'] = 'URL is not valid';
        }

        return errors;
    }

}

export default ArticleValidation;
