import React, {Fragment} from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';
import * as articleActions from '../actions/articleActions';
import * as galleryActions from "../actions/galleryActions";
import {TextField, SelectField, TextareaField, ErrorMessage, ErrorTooltip, actions} from 'react-fields';

import Source from './partials/source';
import GalleryModal from '../../admin_components/gallery/modal';
import DeleteFromGalleryModal from "../gallery/deleteItemModal";

const CHAR_LIMITS = {name: 60, share_message: 200, meta_title: 60, meta_description: 160, job_title: 25, job_description: 100,
    subtitle: 150};

import cf from '../common';
import ArticleBodyEditor from "./partials/articleBodyEditor";
import TopicsArea from "./partials/topicsArea";

import VideoArea from "./partials/videoArea";
import SponsorArea from './partials/sponsorArea';

import PopUp from '../../components/modals/popup';
import AdvertorialButton from './partials/advertorialButton';
import AdvertorialCompany from "./partials/advertorialCompany";
import {Editor} from "@tinymce/tinymce-react";

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state= {
            categoriesList: [],
            industriesList: [],
            industriesLoaded: false,
            topicsList: [],
            videoTypes: [{name: 'Advice', value: 'advice'}, {name: 'Interviews', value: 'interviews'}],
            currentSelectedTopic: null,
            topicValue: '',
            charLeft: {
                name: CHAR_LIMITS['name'] - (this.props.article.name || '').length,
                share_message: (CHAR_LIMITS['share_message'] - (this.props.article.share_message || '').length),
                meta_title: (CHAR_LIMITS['meta_title'] - (this.props.article.meta_title || '').length),
                meta_description: (CHAR_LIMITS['meta_description'] - (this.props.article.meta_description || '').length),
                job_title: (CHAR_LIMITS['job_title'] - (this.props.article.job_title || '').length),
                job_description: (CHAR_LIMITS['job_description'] - (this.props.article.job_description || '').length),
                subtitle: (CHAR_LIMITS['subtitle'] - (this.props.article.subtitle || '').length)
            }
        };

        this.galleryModal = React.createRef();
        this.galleryDeleteModal = React.createRef();
    }

    onWindowClose = (event) => { // the method that will be used for both add and remove event
        if (this.props.article.hasChanges) {
            event.preventDefault();
            event.returnValue = "There are unsaved changes";
        }
    };

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onWindowClose);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.onWindowClose);
        let _this = this;
        fetch(actions.getData('/api/categories/subcategories'))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({categoriesList: data});
            });

        if (this.props.article.cpCategory) { _this.loadIndustries(); }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.industriesLoaded && this.props.article && this.props.article.cpCategory && this.props.article.cpCategory !== prevProps.article.cpCategory) {
            this.loadIndustries();
        }
    }

    loadIndustries() {
        if (this.state.industriesList.length === 0) {
            let _this = this;
            fetch(actions.getData('/api/industries'))
                .then(actions.status)
                .then(actions.json)
                .then(function(data) {
                    _this.setState({industriesList: data, industriesLoaded: true});
                });
        }
    }

    openFeaturedImageUploadModal = (galleryItem) => {
        this.props.actions.setGallery({
            itemType: 'article_featured_image',
            item: galleryItem,
            mode: this.props.mode === 'admin' ? this.props.mode : 'view',
            onSave: this.handleFeaturedImageSet,
            onClose: () => this.galleryModal.current.close()
        });
        this.galleryModal.current.open();
    };

    openUploadModal = (galleryItem, onSave, key) => {
        this.props.actions.setGallery({
            itemType: 'article_image',
            item: galleryItem,
            mode: this.props.mode === 'admin' ? this.props.mode : 'view',
            onSave: (item) => this.handleMediaSet(item, onSave, key),
            onClose: () => this.galleryModal.current.close()
        });
        this.galleryModal.current.open();
    };

    openFileUploadModal = (onSave) => {
        this.props.actions.setGallery({
            itemType: 'gallery_file',
            item: null,
            fileMode: 'insert',
            mode: this.props.mode === 'admin' ? this.props.mode : 'view',
            onSave: (item) => this.handleFileSet(item, onSave),
            onClose: () => this.galleryModal.current.close()
        });
        this.galleryModal.current.open();
    };

    handleFeaturedImageSet = (item) => {
        if (!item && this.props.article.featured_image && this.props.article.featured_image.new) {
            this.galleryDeleteModal.current.open(this.props.article.featured_image.id);
        }
        this.galleryModal.current.close();
        this.changeArticle('featured_image', item);
    };

    handleMediaSet = (item, onSave, key) => {
        this.galleryModal.current.close();
        onSave(item, key);
    };


    handleFileSet = (item, onSave) => {
        this.galleryModal.current.close();
        onSave(item);
    };

    changeArticle = (attribute, value) => {
        let article = this.props.article;
        article[attribute] = value;
        if (attribute === 'category_id') {
            let category = this.state.categoriesList.find(c => c.id === parseInt(value));
            article.cpCategory = category.career_path;
            article.category = category;
            if (article.cpCategory) { this.loadIndustries(); }
        }
        if (attribute === 'content' && this.props.mode === 'admin') {
            article.wordsCount = tinymce.activeEditor.plugins.wordcount.getCount();
        }
        article['errors'][cf.camelize(attribute)] = '';
        this.props.actions.changeArticle(article);
    };

    handleFieldChange = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        value = value.replace(/\s/g, ' ');

        if (CHAR_LIMITS[field]) {
            if (value.length > CHAR_LIMITS[field]) {
                value = value.substring(0, CHAR_LIMITS[field]);
            }
            this.setState({charLeft: Object.assign({}, this.state.charLeft, {[field]: CHAR_LIMITS[field] - value.length})})
        }

        this.changeArticle(field, value);
    };

    handleSourceAdd = (e) => {
        e.preventDefault();
        this.props.actions.addArticleSource(this.props.article);
    };

    render() {
        let article = this.props.article;
        const FieldWrapper = this.props.fieldWrapper;
        return (
            <React.Fragment>
                {(article.state === 'rejected' || (article.was_rejected && article.state !== 'published')) ?
                    <FieldWrapper label='' name='reject_reason'>
                        <ErrorMessage message={`Reject Reason: ${article.reject_reason}`} />
                    </FieldWrapper>
                : null}
                <FieldWrapper label='Article Title*' name='name' link={{title: 'Submission Guide', href: '/submission-guide'}}>
                    <TextField onChange={this.handleFieldChange} name='name'
                                   placeholder="Enter a title suggestion for your article." value={article.name}
                                   errorMessage={article.errors.name} hintText={this.state.charLeft['name']} errorType="tooltip" />
                </FieldWrapper>

                {this.props.mode === 'admin' ?
                    <FieldWrapper label='Subtitle*' name='subtitle'>
                        <TextareaField name="subtitle" onChange={this.handleFieldChange}
                                       placeholder='Article subtitle'
                                       rows={3}
                                       errorMessage={article.errors.subtitle} errorType="tooltip"
                                       hintText={this.state.charLeft['subtitle']}
                                       value={article.subtitle}/>

                    </FieldWrapper> : null}

                <FieldWrapper label='Choose a Category*' name='category_id'>
                    <SelectField name="category_id" placeholder="Category" className='label-icon-right select-label'
                                 onChange={this.handleFieldChange}
                                 options={this.state.categoriesList} optionsValue='id' optionsLabel='name'
                                 selected={article.category_id}
                                 errorMessage={article.errors.categoryId} errorType="tooltip" />
                </FieldWrapper>

                {article.cpCategory && this.props.mode === 'admin' ?
                    <Fragment>
                        <FieldWrapper label='Choose an Industry' name='industry_id'>
                            <SelectField name="industry_id" placeholder="Industry" className='label-icon-right select-label'
                                         onChange={this.handleFieldChange}
                                         options={this.state.industriesList} optionsValue='id' optionsLabel='name'
                                         selected={article.industry_id}
                                         errorMessage={article.errors.industryId} errorType="tooltip" />
                        </FieldWrapper>
                        <FieldWrapper label='Job Title' name='job_title'>
                            <TextField onChange={this.handleFieldChange} name='job_title'
                                       placeholder="Enter the job title for this article" value={article.job_title}
                                       errorMessage={article.errors.jobTitle} errorType="tooltip" hintText={this.state.charLeft['job_title']} />
                        </FieldWrapper>
                        <FieldWrapper label='Job Description' name='job_description'>
                            <TextareaField name="job_description" onChange={this.handleFieldChange}
                                           placeholder='Enter the job description for this article.'
                                           hintText={this.state.charLeft['job_description']}
                                           errorMessage={article.errors.jobDescription} errorType="tooltip" value={article.job_description}/>
                        </FieldWrapper>
                    </Fragment>
                : null}

                {this.props.mode === 'admin' ? <Fragment>
                    <FieldWrapper label='Featured Image' name='featured_image_id'>
                        {article.featured_image ?
                            <div className="featured-img-container">
                                <img src={article.featured_image.original_path} alt="Article Image" className="featured-img" />
                                <div className="edit-buttons">
                                    <button className="button-delete" onClick={() => this.handleFeaturedImageSet(null) }><div className="small-icon bin"/></button>
                                    <button className="button-edit" data-open="insert-file" onClick={() => this.openFeaturedImageUploadModal(article.featured_image)}>Edit</button>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <button className="button-add" onClick={() => this.openFeaturedImageUploadModal(null)}>Select an image<span className="small-icon cross"/></button>
                            </React.Fragment>
                        }
                        <ErrorMessage message={article.errors.featuredImage} />
                    </FieldWrapper>

                    <FieldWrapper label='Video URL' name='video_url'>
                        <VideoArea mode={this.props.mode} />
                    </FieldWrapper>
                    {article.video_url ?
                        <FieldWrapper label='Video Type' name='video_type'>
                            <SelectField name="video_type" placeholder="Choose a video type" className='label-icon-right select-label'
                                         onChange={this.handleFieldChange}
                                         options={this.state.videoTypes} optionsValue='value' optionsLabel='name'
                                         selected={article.video_type}
                                         errorMessage={article.errors.videoType} errorType="tooltip" />
                        </FieldWrapper> : null}
                </Fragment> : null}

                <FieldWrapper label='Article Body*' name='content'>
                    <div className={article.errors.content ? "err" : ""}>
                        <ArticleBodyEditor value={article.content || ''} tinyMceCss={this.props.tinyMceCss}
                                           advertorial={article.advertorial}
                                           openFileUploadModal={this.openFileUploadModal}
                                           mode={this.props.mode} onGalleryOpen={this.openUploadModal} interviewsVideo={article.video_type === 'interviews'}
                                           onChange={(content) => this.changeArticle('content', content)} />
                        {/*<Editor value={article.content || ''}*/}
                        {/*        id={`tinyMceEditor${CF.generateUniqKey()}`}*/}
                        {/*        onEditorChange={(content) => this.changeArticle('content', content)}*/}
                        {/*        init={{*/}
                        {/*            plugins: [*/}
                        {/*                "advlist autolink lists link image charmap print preview anchor",*/}
                        {/*                "searchreplace visualblocks code fullscreen",*/}
                        {/*                "insertdatetime media table paste wordcount contextmenu"*/}
                        {/*            ],*/}
                        {/*            toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",*/}
                        {/*            contextmenu: "link linkchecker image imagetools lists table spellchecker"*/}
                        {/*        }}/>*/}
                        <ErrorTooltip message={article.errors.content}

                        />
                    </div>
                </FieldWrapper>

                <FieldWrapper label='Topics*' name='topics'>
                    <TopicsArea mode={this.props.mode} />
                </FieldWrapper>

                <FieldWrapper label='Sources' name='sources'>
                    {article.sources.map(function (s, i) {
                        return (<Source key={`source${i}`} source={s} />)
                    })}
                    <button className="button-add" onClick={this.handleSourceAdd}>Add a source<span className="small-icon cross"/></button>
                </FieldWrapper>

                {this.props.mode === 'admin' ? <Fragment>
                    <FieldWrapper label='Twitter Sharing Text' name='share_message'>
                        <TextareaField name="share_message" onChange={this.handleFieldChange}
                                       placeholder='Add optional text for sharing this article to Twitter.'
                                       hintText={this.state.charLeft['share_message']}
                                       errorMessage={article.errors.shareMessage} errorType="tooltip" value={article.share_message}/>
                    </FieldWrapper>

                    <FieldWrapper label='Meta Title' name='meta_title'>
                        <TextField onChange={this.handleFieldChange} name='meta_title'
                                   placeholder="Enter a meta title suggestion for your article." value={article.meta_title}
                                   errorMessage={article.errors.metaTitle} errorType="tooltip" hintText={this.state.charLeft['meta_title']} />
                    </FieldWrapper>

                    <FieldWrapper label='Meta Description' name='meta_description'>
                        <TextareaField name="meta_description" onChange={this.handleFieldChange}
                                       placeholder='Enter a meta description suggestion for your article.'
                                       hintText={this.state.charLeft['meta_description']}
                                       errorMessage={article.errors.metaDescription} errorType="tooltip" value={article.meta_description}/>
                    </FieldWrapper>

                    {article.sponsored_display ? <Fragment>
                        <SponsorArea tinyMceCss={this.props.tinyMceCss} />
                    </Fragment> : null}

                    {article.advertorial ? <AdvertorialButton/> : null }
                    {article.advertorial ? <AdvertorialCompany/> : null }

                </Fragment> : null}

                <FieldWrapper label='' name='stale'>
                    <ErrorMessage message={article.errors.stale} />
                </FieldWrapper>
                <PopUp ref={this.galleryModal} className={'large'} disablePageScroll>
                    <GalleryModal close={() => {this.galleryModal.current.close()}} />
                </PopUp>

                <DeleteFromGalleryModal ref={this.galleryDeleteModal} />
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        article: state.article,
        gallery: state.gallery
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions, galleryActions), dispatch)
    };
}

Form.contextTypes = {
    store: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);

