import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as articleActions from '../../admin_components/actions/articleActions';

import Form from '../../admin_components/articles/form';
import {FormFieldWrapper} from "./formFieldWrapper";
import ArticlesApi from "../../admin_components/api/articlesApi";

import cf from "../common";
import ArticleValidation from "../../admin_components/articles/articleValidation";
import SimpleModal from "../modals/simpleModal";
import {Loader} from "react-fields";
import {setCookie} from "../base/cookieHelper";

class AuthorForm extends React.Component {

    state = {isLoading: false};

    updateBrowserHistory = () => {
        let href = '/account/submit/'+this.props.article.id;
        window.history.pushState({href: href}, '', href);
    };

    openPreview = (response, state) => {
        let articleId = this.props.article.id;
        if (response) {
            articleId = response.id;
            this.props.actions.updateArticle(response);
            this.setState({isLoading: false});
            console.log(response);
            console.log(response.id);
        } else {
            let key = `articlePreview${articleId}`;
            setCookie(key, this.props.article);
        }

        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: `/account/article/${articleId}`
        }).click();
    };

    onSuccess = (response, state) => {
        if (state === 'draft'){
            this.setState({isLoading: false});
            this.refs.saveDraftModal.open();
        } else {
            this.refs.savePendingModal.open();
        }
        this.props.actions.updateArticle(response);
    };

    onError = (errors) => {
        this.props.actions.saveArticleErrors(errors);
        this.setState({isLoading: false}, () => { cf.scrollToError(); });
    };

    handleSubmit = (e, articleState, onSuccessAction) => {
        e.preventDefault();
        if (this.props.disabled)
            return null;

        this.setState({isLoading: true});
        let errors = ArticleValidation.validate(this.props.article, articleState);
        if (Object.keys(errors).length) {
            this.onError(errors);
        } else {
            ArticlesApi.submit(this.props.article, 'author', articleState, onSuccessAction, this.onError);
        }
    };

    render() {
        return(
            <React.Fragment>
                {this.state.isLoading ? <Loader size="small"/> : null}
                <Form fieldWrapper={FormFieldWrapper} mode={'author'} tinyMceCss={this.props.tinyMceCss} />

                <div className="buttons-area text-center">
                    <button className="button-grey" onClick={(e) => this.props.article.id ? this.openPreview() : this.handleSubmit(e, 'draft', this.openPreview)}>Preview</button>
                    {' '}
                    <button className="button-grey" onClick={(e) => this.handleSubmit(e, 'draft', this.onSuccess)}>Save Draft</button>
                    {' '}
                    <button className="button-orange" onClick={(e) => this.handleSubmit(e, 'pending', this.onSuccess)}>Submit</button>
                </div>

                <SimpleModal ref='saveDraftModal'
                             title="Saved Successfully!"
                             sprite="ad-icon popUp"
                             button="Close"
                             onClose={this.updateBrowserHistory} >
                    <p className="lastText">Your article has been saved. You can continue writing or come back to it later</p>
                </SimpleModal>

                <SimpleModal ref='savePendingModal'
                             title="Submitted for Review!"
                             sprite="ad-icon popUp"
                             button="Close" onClose={() => {document.location.href = '/account/articles';}} >
                    <p className="lastText">Please allow up to 5 working days for us to review your submission.</p>
                </SimpleModal>
            </React.Fragment>
        );
    }

}

function mapStateToProps(state) {
    return { article: state.article };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorForm);