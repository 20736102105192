import React from "react";
import moment from 'moment';
import SchemaOrganization from "../../partials/schemaOrganization";

export default function StudyItem(props) {

    const seoProps = {itemType: 'http://schema.org/Article', itemScope: true, itemProp: 'hasPart'};
    const seoImgProps = {itemType: 'http://schema.org/ImageObject', itemScope: true, itemProp: 'image'};

    return (
        <div className="large-6 medium-12 cell" {...seoProps}>
            <meta itemProp="headline" content={props.article.name} />
            <meta itemProp="articleSection" content='Study' />
            <meta itemProp="datePublished" content={moment(props.article.publish_date).format('YYYY-MM-DD')} />
            <meta itemProp="dateModified" content={moment(props.article.updated_at).format('YYYY-MM-DD')} />
            <meta itemProp="mainEntityOfPage" content={""} itemScope itemType="http://schema.org/WebPage" itemID={props.article.url} />
            <SchemaOrganization itemProp={'author'} />
            <SchemaOrganization itemProp={'publisher'} />

            <div className="press__study-box">
                <div className=" press__study-details">
                    <a href={`/study${props.article.path}`}>
                       <h5 className="press__study-label">{props.article.label}</h5>
                      <h3 className="press__study-title">{props.article.name}</h3>
                    </a>
                    <h5 className="press__item-date">{moment(props.article.publish_date).format('MMMM D, YYYY')}</h5>
                </div>
                <div className="press__item-img" {...seoImgProps}>
                    <meta itemProp="url" content={props.article.featured_image.original_path} />
                    <meta itemProp="width" content="700" />
                    <meta itemProp="height" content="400" />
                    <a href={`/study${props.article.path}`}>
                       <img src={props.article.featured_image.medium_path} alt={props.article.featured_image.alt}/>
                    </a>
                </div>
            </div>
        </div>
    )

}