export const SET_AUTHOR_PROFILE = 'SET_AUTHOR_PROFILE';
export const CHANGE_AUTHOR_PROFILE = 'CHANGE_AUTHOR_PROFILE';

export const SET_INITIAL_AUTHOR_TAB = 'SET_INITIAL_AUTHOR_TAB';
export const SET_PROFILE_AUTHOR_TAB = 'SET_PROFILE_AUTHOR_TAB';
export const SET_SETTINGS_AUTHOR_TAB = 'SET_SETTINGS_AUTHOR_TAB';
export const SET_ARTICLES_AUTHOR_TAB = 'SET_ARTICLES_AUTHOR_TAB';
export const SET_SUBMIT_AUTHOR_TAB = 'SET_SUBMIT_AUTHOR_TAB';

export const SET_INITIAL_PRESS_TAB = 'SET_INITIAL_PRESS_TAB';
export const SET_NEWS_PRESS_TAB = 'SET_NEWS_PRESS_TAB';
export const SET_STUDY_PRESS_TAB = 'SET_STUDY_PRESS_TAB';
export const SET_PRESS_RELEASE_PRESS_TAB = 'SET_PRESS_RELEASE_PRESS_TAB';

export const RELOAD_ARTICLES_LIST = 'RELOAD_ARTICLES_LIST';
export const LOAD_MORE_ARTICLE_LIST = 'LOAD_MORE_ARTICLE_LIST';
export const INITIAL_ARTICLES_LIST = 'INITIAL_ARTICLES_LIST';

export const SET_INITIAL_STATE_FLEXIBLE_FORM = 'SET_INITIAL_STATE_FLEXIBLE_FORM';
export const NEXT_STEP_FLEXIBLE_FORM = 'NEXT_STEP_FLEXIBLE_FORM';
export const PREV_STEP_FLEXIBLE_FORM = 'PREV_STEP_FLEXIBLE_FORM';
export const CHANGE_FIELD_FLEXIBLE_FORM = 'CHANGE_FIELD_FLEXIBLE_FORM';
export const RESET_FIELDS_FLEXIBLE_FORM = 'RESET_FIELDS_FLEXIBLE_FORM';
export const SUBMIT_FLEXIBLE_FORM_ERRORS = 'SUBMIT_FLEXIBLE_FORM_ERRORS';

export const SET_INITIAL_AD_BANNERS = 'SET_INITIAL_AD_BANNERS';
export const AD_BANNERS_LOADED = 'AD_BANNERS_LOADED';

