import React, {Component, Fragment} from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as articleActions from '../../actions/articleActions';
import FieldWrapper from "../../partials/adminFieldWrapper";

import { Editor } from '@tinymce/tinymce-react';
import CF from "../../common";

import {TextField, TextareaField, ErrorMessage, Loader, SelectField} from "react-fields";
import PropTypes from "prop-types";
import cf from "../../common";

const RELS =  [
    {title: 'Dofollow', value: 'follow'},
    {title: 'No Follow & Sponsored', value: 'nofollow_sponsored'},
    {title: 'Sponsored', value: 'sponsored'},
    {title: 'No Follow', value: 'nofollow'}
];

import {ImageUploader} from 'image-uploader';
import {CheckboxField} from "react-fields/src";

class SponsorArea extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sponsorEditorId: `tinyMceEditor${CF.generateUniqKey()}`,
            editorSettings: {
                plugins: 'link, paste, searchreplace',
                menubar: 'edit view insert format tools',
                height: 200,
                extended_valid_elements: 'a[href|target|id|rel],strong/b,' +
                    'div[align],br,ul,li,ol,u,em,i,h1,h2,h3,h4,h5,h6',
                invalid_styles: 'box-sizing, padding, font-weight, letter-spacing, widows, background-color, ' +
                    'font-variant-caps, text-rendering, text-decoration-style, text-decoration-color, -webkit-text-stroke-width,' +
                    'font-variant-ligatures, line-height, font-family, orphans, word-spacing, margin, text-indent, white-space',
                toolbar: 'bold italic underline | forecolor backcolor | link',
                content_css: this.props.tinyMceCss,
                rel_list: [
                    {title: 'Dofollow', value: ''},
                    {title: 'No Follow & Sponsored', value: 'nofollow sponsored'},
                    {title: 'Sponsored', value: 'sponsored'},
                    {title: 'No Follow', value: 'nofollow'}
                ]
            }
        };
    }

    changeArticle = (attribute, value) => {
        let article = this.props.article;
        article[attribute] = value;
        article['errors'][cf.camelize(attribute)] = '';
        this.props.actions.changeArticle(article);
    };

    setLogo = (options) => {
        let article = this.props.article;
        article.sponsor_logo = {...article.sponsor_logo, ...options};
        article.sponsor_logo_options = {...article.sponsor_logo, ...options};
        article['errors'][cf.camelize('sponsor_logo')] = '';
        this.props.actions.changeArticle(article);
    };

    deleteLogo = () => {
        let article = this.props.article;
        article.sponsor_logo_options = article.sponsor_logo.id ? {delete_logo_id: article.sponsor_logo.id} : {};
        article.sponsor_logo = {};
        article.sponsor_logo_id = null;
        article['errors'][cf.camelize('sponsor_logo')] = '';
        this.props.actions.changeArticle(article);
    };

    render() {
        let article = this.props.article;

        return(
            <Fragment>
                <h5 className="subsection">Sponsored </h5>
                <FieldWrapper label='Sponsor Name' name='sponsor_name'>
                    <TextField onChange={(e) => {this.changeArticle('sponsor_name', e.target.value);}} name='sponsor_name'
                               value={article.sponsor_name}
                               errorMessage={article.errors.sponsorName} errorType="tooltip" />
                </FieldWrapper>

                <FieldWrapper label='Sponsor URL' name='sponsor_url'>
                    <TextField onChange={(e) => {this.changeArticle('sponsor_url', e.target.value);}} name='sponsor_url'
                               value={article.sponsor_url}
                               errorMessage={article.errors.sponsorUrl} errorType="tooltip" />
                </FieldWrapper>
                <div className={'split-section'}>
                    <FieldWrapper label='Sponsor URL Rel' name='sponsor_url_rel'>
                        <SelectField name="sponsor_url_rel" placeholder="Select rel..." className='label-icon-right select-label'
                                     onChange={(e) => {this.changeArticle('sponsor_url_rel', e.target.value);}}
                                     options={RELS} optionsValue='value' optionsLabel='title'
                                     selected={article.sponsor_url_rel}
                                     errorMessage={article.errors.sponsorUrlRel} errorType="tooltip" />
                    </FieldWrapper>
                    <FieldWrapper label='Sponsor URL open in new window?' name='sponsor_url_target_blank'>
                        <CheckboxField onChange={(e) => {this.changeArticle('sponsor_url_target_blank', e.target.checked);}}
                                       name='sponsor_url_target_blank'
                                       className={'mar-b-10'}
                                       value={article.sponsor_url_target_blank}
                                       label='New window'
                        />
                    </FieldWrapper>
                </div>

                <FieldWrapper label='Sponsor Logo' name='sponsor_logo'>
                    <ImageUploader image={article.sponsor_logo} setOptions={this.setLogo}
                                   url="/api/sponsor_logos/cache" name="sponsor_logo" withDelete={true} deleteImage={this.deleteLogo} />
                    <ErrorMessage message={article.errors.sponsorLogo} />
                </FieldWrapper>

                <FieldWrapper label='Sponsor Description' name='sponsor_desc'>
                    <Editor value={article.sponsor_desc}
                            onEditorChange={(content) => this.changeArticle('sponsor_desc', content)}
                            id={this.state.id}
                            init={{...this.state.editorSettings,
                                relative_urls : false,
                                remove_script_host : false,
                                entity_encoding: 'raw',
                                media_live_embeds: true,
                                mobile: {
                                    theme: 'silver'
                                }
                            }} />
                    <ErrorMessage message={article.errors.sponsorDesc} />
                </FieldWrapper>
            </Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        article: state.article
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions), dispatch)
    };
}

SponsorArea.contextTypes = {
    store: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(SponsorArea);
