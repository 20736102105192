import { SET_PRESS_RELEASE_PRESS_TAB, SET_STUDY_PRESS_TAB, SET_NEWS_PRESS_TAB, SET_INITIAL_PRESS_TAB} from '../actions/actionTypes';
import initialState from './initialState';

export default function pressNavigation(state = initialState.pressNavigation, action) {
    switch (action.type) {
        case SET_INITIAL_PRESS_TAB:
            return {currentTab: action.tab || 'press'};
        case SET_NEWS_PRESS_TAB:
            return {currentTab: 'news'};
        case SET_STUDY_PRESS_TAB:
            return {currentTab: 'study'};
        case SET_PRESS_RELEASE_PRESS_TAB:
            return {currentTab: 'press'};
        default:
            return state;
    }
}