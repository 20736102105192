import {
    SET_INITIAL_STATE_FLEXIBLE_FORM,
    NEXT_STEP_FLEXIBLE_FORM,
    PREV_STEP_FLEXIBLE_FORM,
    CHANGE_FIELD_FLEXIBLE_FORM,
    RESET_FIELDS_FLEXIBLE_FORM, SUBMIT_FLEXIBLE_FORM_ERRORS
} from '../actions/actionTypes';

import initialState from './initialState';

export default function flexibleForm(state = initialState.flexibleForm, action) {
    switch (action.type) {
        case SET_INITIAL_STATE_FLEXIBLE_FORM:
            let initialForm = action.form;
            let fields = initialForm.fields.map((field) => {
                return { ...field, ...{value : field.content_type === 'multiselect' ? [] : "", error: ""} }
            });
            return {...initialForm, ...{fields: fields, errors: {}, currentStep: 0}
            };
        case NEXT_STEP_FLEXIBLE_FORM:
            let nextStep = state.currentStep + 1;
            return {...state, ...{currentStep: nextStep}};
        case PREV_STEP_FLEXIBLE_FORM:
            let prevStep = state.currentStep - 1;
            return {...state, ...{currentStep: prevStep}};
        case CHANGE_FIELD_FLEXIBLE_FORM:
            let changedFields = state.fields.map((field) => {
                if (field.id === action.field.id){
                    return action.field;
                }
                return field
            });
            return {...state, ...{fields: changedFields}};
        case RESET_FIELDS_FLEXIBLE_FORM:
            let resetFields = state.fields.map((field) => {
                return { ...field, ...{value : "", error: ""} }
            });
            return {...state, ...{fields: resetFields, errors: {}, currentStep: 0}
            };
        case SUBMIT_FLEXIBLE_FORM_ERRORS:
            let errors = {};
            let erroredFields = state.fields.map((field) => {
                let error = action.errors[`field_error_${field.id}`];
                if (!!error){
                    return { ...field, ...{ error: error} }
                }
                return { ...field, ...{ error: ""} }
            });
            if (action.errors['base']) { errors['base'] = action.errors['base']; }
            return {...state, ...{fields: erroredFields, errors: errors}
            };
        default:
            return state;
    }
}