import {actions} from "react-fields";

class AvatarApi {

    static save(formData, onSuccess, onError){
        const url = '/api/avatars/save';
        let token = document.querySelector("meta[name='csrf-token']").content;

        const options = {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': token } };

        fetch(url, options)
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async(response) => {
            onSuccess(response);
        }).catch(async error => {
            await error.json().then((value) => {
                onError(value);
            });
        });
    }

}
export default AvatarApi;
