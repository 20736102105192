import React, {useEffect,useState, useRef} from "react";

export default function ColorSelectDropDown(props) {

    const [isOpen, setIsOpen] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(
        () => {
            return () => {
                if (isOpen) {
                    window.removeEventListener('scroll', close);
                    document.removeEventListener('click', onClickOutside);
                }
            };
        },[]
    );

    const open = () => {
        window.addEventListener('scroll', close);
        document.addEventListener('click', onClickOutside);
        setIsOpen(true);
    };

    const close = () => {
        window.removeEventListener('scroll', close);
        document.removeEventListener('click', onClickOutside);
        setIsOpen(false);
    };

    const onClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            close();
        }
    };

    const onSelect = (newSelected) => {
        props.onSelect({target: {name: props.name, value: newSelected}});
        setIsOpen(false);
    };

    const collection = props.options.filter((c) => c.value !== props.selected.toUpperCase());
    const selectedObj = props.options.find((c) => c.value === props.selected.toUpperCase());
    const displayLabel = props.displayLabel || props.optionsLabel;

    const renderOption = (item) => {
        return (<React.Fragment>
            <span style={{backgroundColor: (item['displayColor'] || item['value']), minWidth: '20px', minHeight: '10px', display: 'inline-block'}}> </span>
            {item['title']} {item['value']}
        </React.Fragment>)
    };

    return (
        <React.Fragment>
            <div className={`dropdown-container${(isOpen ? ' is-open' : '')}`} ref={wrapperRef}>
                <div className={`dropdown-label${(selectedObj ? ' is-selected' : '')}`} onClick={() => isOpen ? close() : open()}>{selectedObj ? renderOption(selectedObj) : props.placeholder}</div>
                <div className={`dropdown-pane${(selectedObj ? ' is-selected' : '')}`}>
                    {props.placeholder && <div key={`colorNone`} className={'item'} onClick={() => onSelect('')}>{props.placeholder}</div>}
                    {collection.map((c, i) => {
                        return (
                            <div key={`color${i}`} className={'item'} onClick={() => onSelect(c['value'])}>
                                {renderOption(c)}
                            </div>
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    );
}