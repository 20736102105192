import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Navigation from './navigation';
import {setInitialTab} from '../actions/navigationActions';
import store from '../store/store';

export default class Root extends Component {

    componentDidMount() {
        store.dispatch(setInitialTab());
    }


    render() {
        return (
            <Provider store={store}>
                <Navigation user={this.props.user} />
            </Provider>
        );
    }
}