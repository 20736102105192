import React, { Component } from 'react';
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import * as navigationActions from '../../actions/pressNavigationActions';
import NavigationTabs from '../../navigation/navigationTabs';
import LeftBar from './leftBar';

class NavigationBar extends Component {

    render(){
        let currentTab = this.props.pressNavigation.currentTab;
        const tabs = {
            press: {title: "Press Releases", link: "/press"},
            study: {title: 'Studies', link: "/study", margin: true},
            news: {title: 'In the News', link: "/news"}
        };
        const leftBar = <LeftBar/>;

        return (
            <NavigationTabs currentTab={currentTab}
                            tabs={tabs}
                            mobileActiveTitle={'Press'}
                            addTabsClass={'float-right-on-stuck'}
                            isActiveClass={'is-active-bold'}
                            hoverClass={'hover-bold'}
                            leftBar={leftBar}
                            navHeight={"small"}
            >
                <div className="press__banner press__banner--default">
                    <h3>Press</h3>
                    <p className="press__banner-desc text-center">Explore press releases, studies, reports and recent news about CareerAddict.</p>
                </div>
            </NavigationTabs>
        )
    }
}
NavigationBar.propTypes = {
    pressNavigation: PropTypes.object.isRequired

};
function mapStateToProps(state) {
    return {
        pressNavigation: state.pressNavigation

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, navigationActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);