import initialState from './initialState';
import {SET_INITIAL_HTML_BLOCK, CHANGE_HTML_BLOCK} from "./../actions/actionTypes";

export default function htmlBlock(state = initialState.htmlBlock, action) {
    switch (action.type) {
        case SET_INITIAL_HTML_BLOCK:
            return { ...action.htmlBlock,
                errors: {}
            };
        case CHANGE_HTML_BLOCK:
            return Object.assign({}, state, action.htmlBlock);
        default:
            return state;
    }
}



