export function removeFormatBlock(blockSelector, keepContent) {
    let block = tinymce.activeEditor.selection.getNode().closest(blockSelector);
    if (!!block) {
        let content = block.innerHTML;
        block.remove();
        if (keepContent) {
            tinymce.activeEditor.execCommand('mceInsertContent', false, content);
        }
    }
}

export function applyFormatBlock(formatName) {
    const ed = tinymce.activeEditor;
    let tempBeforeElemId = null, tempAfterElemId = null, originalRange = ed.selection.getRng(), newRange = document.createRange();

    if (!ed.selection.isCollapsed() && ed.selection.getStart().closest('ul,ol')) {
        tempBeforeElemId = `pb-${Math.random().toString(36).substring(7)}`;
        const newParagraph = ed.dom.create('p', {id: tempBeforeElemId}, '<br/>');
        (ed.dom.getRoot()).insertBefore(newParagraph, ed.selection.getStart().closest('ul,ol'));
        newRange.setStart(newParagraph, 0);
    } else {
        newRange.setStart(originalRange.startContainer, originalRange.startOffset);
    }

    if (!ed.selection.isCollapsed() && ed.selection.getEnd().closest('ul,ol')) {
        tempAfterElemId = `pa-${Math.random().toString(36).substring(7)}`;
        const newParagraph = ed.dom.create('p', {id: tempAfterElemId}, '<br/>');
        ed.dom.insertAfter(newParagraph, ed.selection.getEnd().closest('ul,ol'));
        newRange.setEnd(newParagraph, 0);
    } else {
        newRange.setEnd(originalRange.endContainer, originalRange.endOffset);
    }

    if (tempBeforeElemId || tempAfterElemId) { ed.selection.setRng(newRange); }

    ed.execCommand('FormatBlock',true,formatName);

    if (tempBeforeElemId) {ed.dom.remove(tempBeforeElemId)}
    if (tempAfterElemId) {ed.dom.remove(tempAfterElemId)}
}

export function replaceTag(element, newTag) {
    let newElement = document.createElement(newTag);
    while(element.firstChild) {
        newElement.appendChild(element.firstChild);
    }
    element.getAttributeNames().map((attrName) => newElement.setAttribute(attrName, element.getAttribute(attrName)));
    element.parentNode.replaceChild(newElement,element);
    return newElement
}