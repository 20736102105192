import React from "react";
import Field from "./field";
import * as formActions from "../actions/flexibleFormActions";
//import * as stepsActions from '../order_form_steps/actions/oderFormStepsActions';
import {bindActionCreators} from "redux";
import { connect } from 'react-redux';


class FieldsList extends React.Component {

    onChangeField = (field) => {
        this.props.actions.changeField(field);
    };

    renderFields = () => {
        let currentStep = this.props.orderFormStep;
        let _this =this;
        let fields = [];
        let i = 0;
        let currentFields = this.props.flexibleForm.fields; //this.props.flexibleForm.fields.filter((f) => f.step === currentStep.index + 1);
        while (i < currentFields.length) {
            let field = currentFields[i];
            let nextField = currentFields[i + 1];
            if (field.style === 'half' && nextField && nextField.style === 'half') {
                fields.push(
                    <React.Fragment key={i}>
                        <div className='medium-6 cell padR5'>
                        <Field field={field} key={field.id}
                               onChange = {_this.onChangeField}
                              />
                        </div>
                        <div className='medium-6 cell padL5'>
                        <Field field={nextField} key={nextField.id}
                               onChange = {_this.onChangeField}
                               />
                        </div>
                    </React.Fragment>);
                i++;
            } else {
                fields.push(
                    <div className='cell' key={i}>
                        <Field field={field} key={field.id}
                               onChange = {_this.onChangeField}
                               />
                    </div >);
            }

            i++;
        }
        return fields;
    };


    render() {
        return (
            <React.Fragment>

                { this.renderFields()}
            </React.Fragment>);

    }
}
function mapStateToProps(state) {
    return {
        flexibleForm: state.flexibleForm,
        //orderFormStep: state.orderFormStep
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, formActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FieldsList);
