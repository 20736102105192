import React, {Component, Fragment} from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as articleActions from '../../actions/articleActions';
import FieldWrapper from "../../partials/adminFieldWrapper";

import {TextField, ErrorMessage, SelectField} from "react-fields";
import PropTypes from "prop-types";
import cf from "../../common";

const RELS =  [
    {title: 'Dofollow', value: 'follow'},
    {title: 'No Follow & Sponsored', value: 'nofollow_sponsored'},
    {title: 'Sponsored', value: 'sponsored'},
    {title: 'No Follow', value: 'nofollow'}
];
const TARGET = [{title: 'Current window', value: 'current'}, {title: 'New window', value: 'new'}];
class AdvertorialButton extends Component {


    changeArticle = (e) => {
        let article = this.props.article;
        let field = e.target.name;
        article[field] = e.target.value;
        article['errors'][cf.camelize(field)] = '';
        this.props.actions.changeArticle(article);
    };

    render() {
        let article = this.props.article;

        return(
            <div className={'split-section'}>
                <h5 className="subsection">Advertorial Button</h5>
                <FieldWrapper label='Anchor Text' name='advertorial_button_text'>
                    <TextField onChange={this.changeArticle} name='advertorial_button_text'
                               value={article.advertorial_button_text}
                               errorMessage={article.errors.advertorialButtonText} errorType="tooltip" />
                </FieldWrapper>

                <FieldWrapper label='URL' name='advertorial_button_url'>
                    <TextField onChange={this.changeArticle} name='advertorial_button_url'
                               placeholder={'http://'}
                               value={article.advertorial_button_url}
                               errorMessage={article.errors.advertorialButtonUrl} errorType="tooltip" />
                </FieldWrapper>

                <FieldWrapper label='Rel' name='advertorial_button_rel'>
                    <SelectField name="advertorial_button_rel" placeholder="Select rel..." className='label-icon-right select-label'
                                 onChange={this.changeArticle}
                                 options={RELS} optionsValue='value' optionsLabel='title'
                                 selected={article.advertorial_button_rel}
                                 errorMessage={article.errors.advertorialButtonRel} errorType="tooltip" />
                </FieldWrapper>

                <FieldWrapper label='Open in' name='advertorial_button_target'>
                    <SelectField name="advertorial_button_target" placeholder="Select open in..." className='label-icon-right select-label'
                                 onChange={this.changeArticle}
                                 options={TARGET} optionsValue='value' optionsLabel='title'
                                 selected={article.advertorial_button_target}
                                 errorMessage={article.errors.advertorialButtonTarget} errorType="tooltip" />
                </FieldWrapper>


            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        article: state.article
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions), dispatch)
    };
}

AdvertorialButton.contextTypes = {
    store: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvertorialButton);
