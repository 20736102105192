import initialState from './initialState';
import {
    ADD_FIELD, CHANGE_FIELD, SET_INITIAL_FIELDS, DELETE_FIELD,
    SAVE_FIELD_ERRORS, RESET_ERRORS_FIELDS, ADD_SELECTED_VALUE_FIELD,
    DELETE_SELECTED_VALUE_FIELD, REORDER_SELECTED_VALUES_FIELD, REORDER_FIELDS
} from "../actions/actionTypes";
import CommonFunctions from "../common";

export default function fields(state = initialState.fields, action) {
    switch (action.type) {
        case SET_INITIAL_FIELDS:
            return action.fields.map((item,i) => {
                let selectable_values = item.selectable_values ? item.selectable_values.split(',') : [];
                let selectableArray = selectable_values.map((val) => {
                    let [value, email] = val.split('|');
                    return {key:  CommonFunctions.generateUniqKey(), value: value, email: (email || null)}
                });

                let attributes = {
                    key: CommonFunctions.generateUniqKey(),
                    errors: {},
                    order: item.order || i + 1,
                    style: item.style || 'full',
                    step: item.step || 1,
                    content_type: item.content_type || 'string',
                    selectable_values: selectableArray
                };
                return { ...item, ...attributes }
            });
        case REORDER_FIELDS:
            return action.fields;
        case ADD_FIELD:
            let newField = new Object({
                key: CommonFunctions.generateUniqKey(),
                errors: {},
                order: state.length + 1,
                content_type: 'string',
                style: 'full',
                step: 1,
                selectable_values: []
            });
            return [...state, newField];
        case ADD_SELECTED_VALUE_FIELD:
            let selectable_values = action.field.selectable_values;
            selectable_values.push({key:  CommonFunctions.generateUniqKey(), value: action.value, email: action.email});
            return state.map(item => {
                if(item.key === action.field.key){
                    return { ...action.field, ...{selectable_values: selectable_values} }
                }
                return item
            });
        case REORDER_SELECTED_VALUES_FIELD:
                   return state.map(item => {
                if(item.key === action.field.key){
                    return { ...action.field, ...{selectable_values: action.values} }
                }
                return item
            });
        case DELETE_SELECTED_VALUE_FIELD:
            let nextSelectableValues = action.field.selectable_values.filter((v) => v.key !== action.value.key);
            return state.map(item => {
                if(item.key === action.field.key){
                    return { ...action.field, ...{selectable_values: nextSelectableValues} }
                }
                return item
            });
        case RESET_ERRORS_FIELDS:
            return state.map(item => {
                return { ...item, ...{errors: {}} }
            });
        case CHANGE_FIELD:
            return state.map(item => {
                if(item.key === action.field.key){
                    return { ...item, ...action.field }
                }
                return item
            });
        case SAVE_FIELD_ERRORS:
            let erroredField = action.field;
            erroredField.errors = action.errors;
            return state.map(item => {
                if(item.key === action.field.key){
                    return { ...item, ...erroredField }
                }
                return item
            });
        case DELETE_FIELD:
            let fields = Object.assign([], state);
            let index = state.findIndex(f => f.key === action.field.key);
            fields.splice(index, 1);
            return fields;
        default:
            return state;
    }
}