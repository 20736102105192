import React from "react";
import Variant from './variant';
import * as db from './questions'
import Results from './results';
import QuestionnaireApi from "../api/questionnaireApi";
import {FadeInSection} from "../fadeIn";

class Index extends React.Component {

    state = {
        answers: [],
        currentStep: 1,
        steps: [1,2,3],
        result: null,
        loadedData: null
    };

    componentDidMount() {
        QuestionnaireApi.loadResultsDetails(this.onLoadResultsData);
    }

    onLoadResultsData = (data) => {
       this.setState({loadedData: data})
    };

    onAnswer = (index) => {
     let answers = [...this.state.answers];
     let step = this.state.currentStep;
     answers[step - 1] = index;
     let result = null;
     if((step + 1) === this.state.steps.length){
         result = this.getAnswers(answers);
         console.log(result)
     }

     this.setState({currentStep: step + 1, answers: answers, result: result})
    };

    goPrev = () => {
        let answers = [...this.state.answers];
        answers.pop();
        this.setState({currentStep: this.state.currentStep - 1, answers: answers})
    };

    findQuestion = () => {
        return db.questions.find((q) => q.step === this.state.currentStep && (this.state.currentStep === 1 || q.parent_answer === `${this.state.answers.join('')}`))
    };

    goTo = (e) => {
      let progressBar = document.getElementsByClassName('progress-bar-track')[0];
      let offsetLeft = progressBar.offsetLeft;
      let barClientWidth = progressBar.clientWidth;

        console.log(e.clientX)
        console.log(offsetLeft)
      let point = e.clientX - offsetLeft;


      if (point/barClientWidth < 0.4) {
          this.setState({currentStep: 1, answers: []})
      } else if (point/barClientWidth >= 0.4 && this.state.currentStep === 3) {
          this.goPrev();
      }
    };

    getAnswers = (answers) => {
        let answers_key=`${answers.join('')}`;
        let result = db.results.find((res) => res.key === answers_key);
        if(!result) {
            // get random unless ready results
            result = db.results[Math.floor(Math.random() * db.results.length)];
        }


        result.results.map((res) => {
            if(res.article_id){
                let article = this.state.loadedData.find((article) => article.id === res.article_id);
                if(article){
                    res.link = article.path;
                    res.image = article.image_small_url;
                }

            }
        });
        return result;
    };

    render(){
        let question = this.findQuestion();
        let percentage = Math.floor(((this.state.currentStep - 1)/2)*100);
        return (
            <FadeInSection>
                <div className="questionnaire">
                    <div className="grid-container transparent">
                        <div className="side-bubble is-on-right"/>
                        <div className="side-bubble is-on-left"/>
                                   <div className="grid-x questionnaire-container">
                                       <div className="small-12 cell">
                                           <h1 className="questionnaire-title">LET OUR TOOL GUIDE YOU</h1>
                                           <p className="questionnaire-info">  {this.state.currentStep === this.state.steps.length && this.state.result ?
                                               this.state.result.title : question.title}</p>
                                           <div className="progress-bar-wrapper">
                                               <div className="progress-percentage">{percentage}%</div>
                                               <div className="progress-bar">
                                                   <div className="progress-bar-track" style={{width: `${percentage}%`}} onClick={this.goTo}>
                                                       {this.state.steps.map((step) => {
                                                           if(step > this.state.currentStep ){
                                                               return null;
                                                           }
                                                           if(step === this.state.currentStep ){
                                                               return <div className="progress-bar-dot"/>
                                                           }
                                                           return <div style={{left: `${Math.floor(((step - 1)/2)*100)}%`}} className="progress-bar-dot progress-bar-dot--hidden"/>

                                                       })}

                                                   </div>
                                               </div>
                                           </div>
                                           {this.state.currentStep === this.state.steps.length && this.state.result ?
                                               <div className="grid-x questionnaire-img-list">
                                                   <Results result={this.state.result} />
                                               </div>
                                               :
                                               <div className="questionnaire-list">
                                                   <div className="grid-x">
                                                       <div className="small-12">
                                                           {question.answers.map((answer, index) => {
                                                               return <Variant text={answer} key={index} index={index} selected={answer===this.state.answers[index]} onClick={this.onAnswer}/>
                                                           })}
                                                       </div>
                                                   </div>
                                               </div>
                                           }

                                           {this.state.currentStep !== 1 && <span onClick={this.goPrev}  className="questionnaire-button" role="button" tabIndex="0"><span className="back-arrow"/>Previous</span>}
                                       </div>
                                   </div>



                    </div>
                </div>
            </FadeInSection>
        )
    }

}

export default Index;