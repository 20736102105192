import {actions} from 'react-fields'

class ImagesApi {

    static saveImage(url, assetable_id, options ={}) {
        let data = {
            assetable_id: assetable_id,
            cache_name: options.cache_name,
            alt: options.alt
        };

        if (options.assetable_version_id) {
            data['assetable_version_id'] = options.assetable_version_id;
        }

        return fetch(actions.createOrUpdateRequest(url, data))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static destroyImage(url) {
        return fetch(actions.deleteRequest(url, {}))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            });
    }


}


export default ImagesApi