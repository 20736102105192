import React from "react";
import Img from '../submition-circles.png';
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "../subscriptionActionHOC";

const SubscriptionForm = SubscriptionActionHOC(props => {
    return (<div className="subscription__ad-input-block subscription--archive">
        <InputTypeField className={'subscription__ad-input'} type='email' name="email" value={props.form.email}
                        onChange={props.setEmail} errorMessage={props.form.errors.email}
                        placeholder="Your email..."
                        onKeyPress={props.submitOnEnter}
        />
        <label className="subscription__ad-submit" onClick={props.submitForm}><span className="send-icon"/></label>
    </div>)
});

//SubscribeAd id={'SubscribeArchiveAd'} for GTM!
class SubscriptionAd extends React.Component {

    render() {

        return (<div className="subscription__ad-block SubscribeAd" id={'SubscribeArchiveAd'}>
            <h3 className="subscription__ad-title">Accelerate your career in 7 days</h3>
            <p className="subscription__ad-desc">Daily hacks, trending news, giveaways, plus more — <strong>straight to your inbox,</strong> for free!</p>
            <SubscriptionForm formType='banner' design={'ad_list'} adBannerId={this.props.adBannerId} />
        </div>)
    }
}

export default SubscriptionAd;
