import React, { Component, Fragment } from 'react';

class ArticleMediaFile extends Component {

    render() {
        let item = this.props.item;
        return (<Fragment>
            <img src={item.original_path} alt={item.alt} className={`article-img${ !!item.shadow ? ' article-img--with_shadow' : '' }`} contentEditable="true" />
            {this.props.skipRenederButtons ? null : <span className="edit-buttons">
                <span className="button button-delete" data-delete><span className="tooltip">Delete</span></span>
                <span className="button button-edit" data-edit>Edit</span>
            </span>}
            {item.source_text ? <span className="source-text">{item.source_link ? <a href={item.source_link}>{item.source_text}</a> : item.source_text}</span> : null }
            {item.caption ? <span className="caption-text">{item.caption}</span> : null }
        </Fragment>);
    }

}

export default ArticleMediaFile;