import React, { Component } from 'react';
import PopUp from '../modals/popup';

class SimpleModal extends Component {

    constructor(props) {
        super(props);

        this.popup = React.createRef();
    }

    open = () => {
        this.popup.current.open();
    };

    close = (onCloseAction) => {
        if (typeof this.props.onClose !== 'undefined') {this.props.onClose();}
        if (onCloseAction && typeof onCloseAction !== 'undefined') {onCloseAction();}
        this.popup.current.close();
    };


    renderButton = () => {
        if (!this.props.button) { return null; }

        return (<div className="text-center">
            <button onClick={() => this.close(this.props.onButtonClick) } className="button-orange">{this.props.button}</button>
        </div>)
    };

    render() {
        return (
            <PopUp ref={this.popup} className={this.props.className}>
                {this.props.sprite ?
                <div style={{margin: 'auto'}} className={this.props.sprite}/> : null}
                <h1 className="text-center">{this.props.title}</h1>
                <div className="grid-x grid-padding-x">
                    <div className="small-12 cell text-center">
                        <div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
                {this.renderButton()}

                <button className="close-button" aria-label="Close modal" type="button" onClick={() => this.close(null)}>
                    <div aria-hidden="true"/>
                </button>
            </PopUp>
        );
    }
}
export default SimpleModal;