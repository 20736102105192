import React from "react";
import LazyLoad from 'react-lazyload';
import Carousel from '../carousels/studies';

export default class sidebarPressLazy extends React.Component {

    render(){
        return (
            <LazyLoad>
                <Carousel {...this.props} />
            </LazyLoad>
        )
    }

}