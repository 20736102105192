import React from "react";

const match = 'match';
const notMatch = 'not_match';
const messages = {match: "Matching", not_match: "Not Match"};

class FieldMatch extends React.Component {
    state = {
        code: ""
    };


    checkValue = (value, confirmationValue) => {
        if (!confirmationValue || confirmationValue.length === 0){
            return this.setState({code: ""})
        }
        if (value !==  confirmationValue) {
            return this.setState({code: notMatch});
        }

        return this.setState({code: match});

    };




    render(){
        return(
            this.state.code ?
                <div className={`strong-validation ${this.state.code}`}>
                    <div className="line"/>
                    {messages[this.state.code]}
                </div> : null
        );

    }
}
export default FieldMatch;