import React, {useEffect,useState} from "react";

export default function CustomDropDown(props) {

    const [selected, setSelected] = useState(props.selected || null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(
        () => {
            return () => {
                if (isOpen) {window.removeEventListener('scroll', close);}
            };
        },[]
    );

    const open = () => {
        window.addEventListener('scroll', close);
        setIsOpen(true);
    };

    const close = () => {
        window.removeEventListener('scroll', close);
        setIsOpen(false);
    };

    const onSelect = (newSelected) => {
        setSelected(newSelected);
        props.onSelect(newSelected);
        setIsOpen(false);
    };

    const collection = props.options.filter((c) => c.value !== selected);
    const selectedObj = props.options.find((c) => c.value === selected);
    const displayLabel = props.displayLabel || props.optionsLabel;
    return (
        <React.Fragment>
            <div className={(props.className || '') + (isOpen ? ' is-open' : '') + (selectedObj ? ' is-selected' : '')} onClick={() => isOpen ? close() : open()}>{selectedObj ? selectedObj[displayLabel] : props.placeholder}</div>
            <div className={"dropdown-pane" + (isOpen ? ' is-open' : '')  + (selectedObj ? ' is-selected' : '')}>
                {collection.map((c, i) => {
                    return (
                        <div key={`currency${i}`} className={props.optionClassName} onClick={() => onSelect(c[props.optionsValue])}>{c[props.optionsLabel]}</div>
                    )
                })}
            </div>
        </React.Fragment>
    );
}