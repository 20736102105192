
import * as types from './actionTypes';


export function initialArticleList(articlesList) {
    return {
        type: types.INITIAL_ARTICLES_LIST,
        articlesList: articlesList
    };
}

export function reloadArticleList(articles, totalPages, count) {
    return {
        type: types.RELOAD_ARTICLES_LIST,
        articles: articles,
        totalPages: totalPages,
        count: count

    };
}


export function loadMoreArticleList(articles, page, totalPages, count) {
    return {
        type: types.LOAD_MORE_ARTICLE_LIST,
        articles: articles,
        totalPages: totalPages,
        count: count,
        page: page

    };
}