import React from 'react';

class ArticleBodyImagesProcessing {

    static replaceImages(value) {
        let content = document.createElement("div");
        content.innerHTML = value;
        console.log(content);
        content.querySelectorAll('span[data-item]').forEach(child => {
            let item = JSON.parse(child.dataset.item);
            console.log(item);
            let img = child.getElementsByTagName("img")[0];
            console.log(img);
            child.replaceWith(`[body_image #${item['id']} alt_text='${item['alt']}' caption='${item['caption'] || ''}' 
             source_link='${item['source_link'] || ''}' source_text='${item['source_text'] || ''}'${!item.source_blank ? " source_blank='false'" : ''}${!item.source_nofollow ? " source_nofollow='false'" : ''} 
             link_to='${item['link_to'] || ''}'${item.link_to_nofollow ? " link_to_nofollow='true'" : ''}${!item.link_to_blank ? " link_to_blank='false'" : ''}
             ${!!img && !!img.getAttribute('width') ? ` width='${img.getAttribute('width')}'` : ''}
             ${!!img && !!img.getAttribute('height') ? ` height='${img.getAttribute('height')}'` : ''}
             ${item['shadow'] ? " shadow='true'" : ''}
             ${item['is_bg'] ? " is_bg='true'" : ''}]`);
        });
        content.querySelectorAll('[contenteditable]').forEach(child => {
            child.removeAttribute('contenteditable');
        });
        return content.innerHTML;
    }

}

export default ArticleBodyImagesProcessing;