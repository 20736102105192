import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Loader, actions, TextareaField} from 'react-fields';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import * as userActions from '../actions/profileActions';
import userApi from '../api/user';
import AvatarUploader from './avatarUploader';
import TextFieldWithIcon from './textFieldWithIcon';
import SimpleModal from "../modals/simpleModal";
import {setProfileTab} from '../actions/navigationActions';
import store from '../store/store';

const CHAR_LIMIT_BIO = 700;



class Profile extends Component {
    state = {
        isLoading: false,
        isSmallerScreen: false,
        charLeft: CHAR_LIMIT_BIO - (this.props.profile.bio || '').length
    };

    componentDidMount() {
        store.dispatch(setProfileTab());
        this.onWindowResize();
        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        this.setState({ isSmallerScreen: window.innerWidth < 1421 });
    };


    submitForm = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        userApi.save(this.props.profile, this.afterSave, this.onError)
    };

    onError = (errors) => {
        this.setState({isLoading: false});
        let profile = this.props.profile;
        profile.errors = errors;
        this.props.actions.changeAuthorProfile(profile);
    };

    afterSave = () => {
        this.setState({isLoading: false});
        this.refs.afterSaveModal.open();
    };

    handleFieldChange = (e) => {
        let field_name = e.target.name;
        if(field_name === 'bio'){
            let leftChars = CHAR_LIMIT_BIO - e.target.value.length;
            if (leftChars < 0) {
                return;
            }
            this.setState({charLeft: leftChars})
        }

        let profile = this.props.profile;
        profile[field_name] = e.target.value;
        profile.errors[actions.camelize(field_name)] = "";
        this.props.actions.changeAuthorProfile(profile);
        if(field_name === 'bio')
          this.setState({charLeft: (CHAR_LIMIT_BIO - e.target.value.length)})
    };




    render() {
        let profile = this.props.profile;
        return (
            <React.Fragment>
                <form onSubmit={this.submitForm} style={{position: "relative", minHeight: "calc(100vh - 503px"}}>
                    {this.state.isLoading ? <Loader size="small"/> : null}
                    <div className="grid-x">
                        <div className="avatar-cell-large cell">
                            <div className="avatar-cell">
                            <AvatarUploader image={profile.avatar} />
                            {this.state.isSmallerScreen ? <h3>{profile.first_name} {profile.last_name}</h3> : null}
                            </div>
                        </div>
                        <div className="large-6 medium-6 cell">
                            <div className="pad-column bio">
                                {this.state.isSmallerScreen ? null : <h3>{profile.first_name} {profile.last_name}</h3>}
                                <label>Bio</label>
                                <div className="bio-text">
                                    <TextareaField name='bio' onChange={this.handleFieldChange}
                                                   value={profile.bio} rows={4}
                                                   placeholder="Add a short bio about yourself."
                                                   hintText={this.state.charLeft}
                                                   errorMessage={profile.errors.bio}/>

                                </div>
                            </div>
                        </div>
                        <div className="large-auto medium-6 cell">
                            <div className="pad-column social">
                                <label>Social</label>
                                <TextFieldWithIcon name="linkedin_acc"
                                                   onChange={this.handleFieldChange}
                                                   value={profile.linkedin_acc}
                                                   placeholder='http://'
                                                   errorMessage={profile.errors.linkedinAcc}
                                                   className="label-icon-left linkedIn"
                                                   title="Linkedin Profile"
                                />
                                <TextFieldWithIcon name="facebook_acc"
                                                   onChange={this.handleFieldChange}
                                                   value={profile.facebook_acc}
                                                   errorMessage={profile.errors.facebookAcc}
                                                   placeholder='http://'
                                                   className="label-icon-left facebook"
                                                   title="Facebook Profile"
                                />
                                <TextFieldWithIcon name="twitter_acc"
                                                   onChange={this.handleFieldChange}
                                                   value={profile.twitter_acc}
                                                   errorMessage={profile.errors.twitterAcc}
                                                   placeholder='http://'
                                                   className="label-icon-left twitter"
                                                   title="Twitter Profile"
                                />
                                <TextFieldWithIcon name="website"
                                                   onChange={this.handleFieldChange}
                                                   errorMessage={profile.errors.website}
                                                   value={profile.website}
                                                   placeholder='http://'
                                                   title="Website"
                                                   className="label-icon-left dribble"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid-x">
                        <div className="small-12 cell">
                            <div className="buttons-area profile">
                                <a href={`/author${profile.path}`} target="_blank" className="button button-grey" style={{margin:'5px',lineHeight: '1.6'}}>View Profile</a>
                                <button className="button-orange" onClick={this.submitForm}>Save</button>
                            </div>
                        </div>
                    </div>
                </form>
                <SimpleModal ref='afterSaveModal'
                              title="Changes saved successfully."
                              sprite="ad-icon popUp"
                              button="Close"
                               />

            </React.Fragment>
        )
    }

}
Profile.propTypes = {
    profile: PropTypes.object.isRequired

};
function mapStateToProps(state) {
    return {
        profile: state.profile

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, userActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);