import React, {useEffect} from "react";

export default function ScrollToHowToStep() {

    //test compile

    useEffect(() => {
        let step = window.location.href.split('#')[1];
        if (step) {
            let element = document.querySelector(`#${step}`);
            window.scrollTo({top: element.offsetTop - 100, behavior: 'smooth'});
        }
    }, []);

    return null;
}
