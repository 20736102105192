import React  from "react";

export default function (props) {

    return (
        <div className={`questionnaire-list-item `} onClick={() => props.onClick(props.index)}>
            <div className="grid-x">
                <div className="questionnaire-options-wrapper">
                    <div className="questionnaire-option">
                        <p className="questionnaire-option-text">{props.text}</p>
                    </div>
                </div>
            </div>
            <div className={`questionnaire-selection${props.selected ? ' selected' : ''}`}>
                <div className="questionnaire-tick"/>
            </div>
        </div>
    )

}