import React from 'react';

export default function LeftBarNavigation(props) {

    return (
        <div className={'tabs-navigation--show-on-stuck'}>
            <div className="text-left  press__submenu--title ">
                Press
            </div>
        </div>
    )
}