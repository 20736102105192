import React, { Component } from 'react';
import GalleryApi from "../api/galleryApi";
import PopUp from '../../components/modals/popup';
import Content from './deleteItemModalContent';

class DeleteFromGalleryModal extends Component {

    state = {isRemoved: false, isLoading: false};

    galleryDeleteModal = React.createRef();
    galleryDeleteSuccessModal = React.createRef();

    open = (galleryItemId) => {
        this.setState({galleryItemId: galleryItemId}, () => {
            this.galleryDeleteModal.current.open();
        })
    };

    onDelete = (onSuccess, onError) => {
        GalleryApi.delete(this.state.galleryItemId, onSuccess, onError)
    };

    close = () => {
        this.galleryDeleteModal.current.close();
    };

    closeConfirmation = () => {
        this.galleryDeleteSuccessModal.current.close();
    };

    render() {
        return (
            <PopUp ref={this.galleryDeleteModal} className={'custom-form'}>
                <Content onDelete={this.onDelete} close={() => {this.galleryDeleteModal.current.close()}} />
            </PopUp>
        )
    }

}
export default DeleteFromGalleryModal;