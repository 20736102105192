import {removeFormatBlock, replaceTag} from "./tynyFunctions";
import {renderToStaticMarkup} from "react-dom/server";

import React from "react";

export function regFaq(ed) {
    ed.ui.registry.addToggleButton('faqBtn', {
        icon: 'help',
        tooltip: 'FAQ block',
        onAction: function () {
            if (ed.formatter.match('faqFormat')) {
                removeFormatBlock('div.faq-blk', false);
            } else {
                ed.execCommand('FormatBlock', true, 'faqFormat');

                console.log(ed.selection);
                let answerP = ed.selection.getNode();
                answerP.setAttribute('contenteditable', true);
                let content =  ed.selection.getContent();
                let headerContent;

                console.log('!!! 1');
                console.log(answerP);
                console.log('!!! 2');

                let wrapper = answerP.closest(".faq-blk");

                console.log(content);
                if (answerP.tagName === 'DIV' && answerP.classList.contains('faq-blk')) {
                    // wrapper = document.createElement('div');
                    //
                    // replaceTag(answerP.closest(".faq-blk"), wrapper);
                    content = answerP.querySelector('p').textContent;
                    headerContent = answerP.querySelector('h1, h2, h3, h4, h5, h6').textContent;
                } else {
                    answerP.remove();
                }

                wrapper.setAttribute('contenteditable', false);
                ed.dom.setHTML(wrapper, '');

                wrapper.appendChild(getFaqBlkControllBtn('btn-faq-del-blk'));
                wrapper.appendChild(getFaqBlkControllBtn('btn-faq-add-question'));
                wrapper.appendChild(getFaqBlkControllBtn('btn-faq-accordion'));

                faqInsertQuestion(ed, wrapper, content, headerContent);

                // let answerDiv = document.createElement("div");
                // answerDiv.classList.add('faq-blk__question__answer');
                // answerDiv.appendChild(answerP);
                //
                // let questionH4 = document.createElement("h4");
                // questionH4.setAttribute('contenteditable', true);
                //
                // let questionDiv = document.createElement("div");
                // questionDiv.classList.add('faq-blk__question');
                // questionDiv.appendChild(questionH4);
                // questionDiv.appendChild(answerDiv);
                // wrapper.appendChild(questionDiv);
                // wrapper.setAttribute('contenteditable', false);
            }
        },
        onSetup: function (api) {
            ed.formatter.formatChanged('faqFormat', function (state) {
                api.setActive(state);
            });
        }
    });
}

export function faqInsertQuestion(ed, blk, answer, question) {
    let questionDiv = document.createElement("div");
    questionDiv.classList.add('faq-blk__question');
    blk.appendChild(questionDiv);
    ed.dom.setHTML(questionDiv, renderToStaticMarkup(getQuestionTemplate(answer, question)));
}

export function faqHandleAccordionBtn(ed, blk) {
    blk.classList.toggle('faq-blk--accordion');
}

export function faqOnSetContent(ed) {
    ed.getBody().querySelectorAll('.faq-blk').forEach(blk => {
        blk.setAttribute('contenteditable', false);
        blk.appendChild(getFaqBlkControllBtn('btn-faq-del-blk'));
        blk.appendChild(getFaqBlkControllBtn('btn-faq-add-question'));
        blk.appendChild(getFaqBlkControllBtn('btn-faq-accordion'));
        blk.querySelectorAll('.faq-blk__question').forEach(questionBlk => {
            questionBlk.appendChild(getFaqBlkControllBtn('btn-faq-del-question'));
            questionBlk.querySelectorAll('h4').forEach(p => {p.setAttribute('contenteditable', true)});
            questionBlk.querySelectorAll('.faq-blk__answer p').forEach(p => {p.setAttribute('contenteditable', true)});
        });
    });
}

function getQuestionTemplate(answer, question) {
    return(
        <React.Fragment>
            <span className="btn-faq-del-question" />
            <h4 contenteditable="true">{question || <br data-mce-bogus="1" />}</h4>
            <div className={'faq-blk__answer'}>
                <p contenteditable="true">{answer || <br data-mce-bogus="1" />}</p>
            </div>
        </React.Fragment>
    )
}

function getFaqBlkControllBtn(className) {
    let btn = document.createElement("span");
    btn.classList.add(className);
    return btn;
}