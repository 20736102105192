import React from "react";


const shortPass = 'short';
const badPass = 'bad';
const goodPass = 'good';
const strongPass = 'strong';
const messages = {short: 'Too short', bad: 'Ok', good: 'Good', strong: 'Strong'}

class PasswordStrength extends React.Component {
    state = {
     code: ""
    };


    checkValue = (value) => {
      if (!value || value.length === 0){
          return this.setState({code: ""})
      }
        if (value.length < 8 ) {
            return this.setState({code: shortPass});
        }

       let score = this.calculateScore(value);

        if ( score < 0 ){score = 0;}
        if ( score > 100 ){score = 100;}

        if (score < 34 ){
            return this.setState({code: badPass});
        }
        if (score < 68 ){
            return this.setState({code: goodPass});
        }

        return this.setState({code: strongPass});

    };

    calculateScore = (value) => {
        let score = 0;

        //password length
        score += value.length * 4;
        [1,2,3,4].map((i) => {
            score += ((this.checkRepetition(i,value).length - value.length ));
        });
        //password has 3 numbers
        if (value.match(/(.*[0-9].*[0-9].*[0-9])/)){ score += 5;}

        //password has 2 symbols
        if (value.match(/(.*[!,@,#,$,%,^,&,*,?,_,~].*[!,@,#,$,%,^,&,*,?,_,~])/)){ score += 5 ;}

        //password has Upper and Lower chars
        if (value.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)){  score += 10;}

        //password has number and chars
        if (value.match(/([a-zA-Z])/) && value.match(/([0-9])/)){  score += 15;}
        //
        //password has number and symbol
        if (value.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && value.match(/([0-9])/)){  score += 15;}

        //password has char and symbol
        if (value.match(/([!,@,#,$,%,^,&,*,?,_,~])/) && value.match(/([a-zA-Z])/)){score += 15;}

        //password is just a numbers or chars
        if (value.match(/^\w+$/) || value.match(/^\d+$/) ){ score -= 10;}

        return score;
    };

    checkRepetition = (pLen,str) =>  {
        let res = "";
        for (let i=0; i<str.length ; i++ )
        {
            let repeated=true;

            for (let j=0;j < pLen && (j+i+pLen) < str.length;j++){
                repeated=repeated && (str.charAt(j+i)===str.charAt(j+i+pLen));
                if (j<pLen){repeated=false;}
            }

            if (repeated) {
                i+=pLen-1;
                repeated=false;
            }
            else {
                res+=str.charAt(i);
            }
        }
        return res;
    };


    render(){
        return(
            this.state.code ?
            <div className={`strong-validation ${this.state.code}`}>
                <div className="line"/>
                {messages[this.state.code]}
            </div> : null
        );

    }
}
export default PasswordStrength;