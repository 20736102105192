
import * as types from './actionTypes';


export function setInitialTab(tab) {
    return {
        type: types.SET_INITIAL_PRESS_TAB,
        tab: tab

    };
}

export function setNewsTab() {
    return {
        type: types.SET_NEWS_PRESS_TAB

    };
}
export function setStudyTab() {
    return {
        type: types.SET_STUDY_PRESS_TAB

    };
}
export function setPressTab() {
    return {
        type: types.SET_PRESS_RELEASE_PRESS_TAB

    };
}
