import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Navigation from './navigationBar';
import {setInitialTab} from '../../actions/pressNavigationActions';
import store from '../../store/store';

export default class Root extends Component {

    componentDidMount() {
        store.dispatch(setInitialTab(this.props.tab));
    }


    render() {
        return (
            <Provider store={store}>
                <Navigation />
            </Provider>
        );
    }
}