import { SET_CV_WRITING_PACKAGE, CHANGE_CV_WRITING_PACKAGE,
    ADD_CV_WRITING_PACKAGE_EXTRAS, DELETE_CV_WRITING_PACKAGE_EXTRAS,
    CHANGE_CV_WRITING_PACKAGE_EXTRAS, SET_ERRORS_CV_WRITING_PACKAGE_EXTRAS,
    SET_ID_CV_WRITING_PACKAGE_EXTRAS, SET_ERRORS_WRITING_PACKAGE, SET_IDS_CV_WRITING_PACKAGE,
    REORDER_CV_WRITING_PACKAGE_EXTRAS} from '../actions/actionTypes';
import initialState from './initialState';
import CF from '../common';

export default function cv_writing_package(state = initialState.cv_writing_package, action) {
    switch (action.type) {
        case SET_CV_WRITING_PACKAGE:
            let initialPack = action.cv_writing_package;
            let extras = initialPack.extras.map((ex) => {
              return {...ex, ...{key: CF.generateUniqKey(), errors: {}}}
            });
            return { ...initialPack,
                    extras: extras,
                    deletedExtras: [],
                    cv_templates: prepareTemplates(action.recommendedTemplates, action.cv_writing_package.cv_templates),
                    deletedTemplates: [],
                    errors: {}
            };
        case CHANGE_CV_WRITING_PACKAGE:
            return Object.assign({}, state, action.cv_writing_package);
        case SET_ERRORS_WRITING_PACKAGE:
            return Object.assign({}, state, {errors: action.errors});
        case SET_IDS_CV_WRITING_PACKAGE:
            return Object.assign({}, state, {id: action.pack.id,
                image: action.pack.image,
                image_options: null,
                cv_templates: setTemplateIds(action.recommendedTemplates, state.cv_templates)});
        case CHANGE_CV_WRITING_PACKAGE_EXTRAS:
            let nextExtras = state.extras.map((ex) => {
                if(ex.key === action.extra.key){
                    return action.extra;
                }
                return ex;
            });
            return Object.assign({}, state, {extras: nextExtras});
        case DELETE_CV_WRITING_PACKAGE_EXTRAS:
            let extrasAfterDelete = state.extras.filter((ex) => ex.key !== action.extra.key);
            let nextDeletedExtras = state.deletedExtras;
            nextDeletedExtras.push(action.extra);
            return Object.assign({}, state, {extras: extrasAfterDelete, deletedExtras: nextDeletedExtras});
        case ADD_CV_WRITING_PACKAGE_EXTRAS:
            let newExtras = [...state.extras, {key: CF.generateUniqKey(), errors: {}}];
            return Object.assign({}, state, {extras: newExtras});
        case SET_ERRORS_CV_WRITING_PACKAGE_EXTRAS:
            let nextExtrasErrors = state.extras.map((ex) => {
                if(ex.key === action.extra.key)
                    ex.errors = action.errors;
                return ex;
            });
            return Object.assign({}, state, {extras: nextExtrasErrors});
        case SET_ID_CV_WRITING_PACKAGE_EXTRAS:
            let nextExtrasSaved = state.extras.map((ex) => {
                if(ex.key === action.extra.key)
                    ex.id = action.id;
                return ex;
            });
            return Object.assign({}, state, {extras: nextExtrasSaved});
        case REORDER_CV_WRITING_PACKAGE_EXTRAS:
            let reorderedExtras = action.extras.map((ex, i) => {
                ex.order = i;
                return ex;
            });
            return {...state, extras: reorderedExtras};
        default:
            return state;
    }
}

function prepareTemplates(recommendedTemplates, templates) {
    return recommendedTemplates.map((t) => {
        let template = templates.find((te) => te.id === t.cv_template_id);
        return {...template, ...{cv_template_id: template.id, id: t.id}}
    });
}

function setTemplateIds(serverTemplates, templates) {
    return templates.map((t) => {
        let template = serverTemplates.find((te) => te.cv_template_id === t.cv_template_id);
        return {...t, ...{id: template.id}}
    });
}