import React from "react";
import {TextField, Loader, ErrorMessage} from 'react-fields';
import PasswordApi from '../api/password';

class ResetPasswordForm extends React.Component {
    state = {
        isLoading: false,
        email: "",
        errors:{}
    };


    handleFieldChange = (e) => {
        this.setState({email: e.target.value, errors: {}});
    };

    renderErrors = (errors) => {
        this.setState({isLoading: false, errors: errors});

    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        PasswordApi.resetPassword(this.state.email, this.onSuccess, this.renderErrors)
    };

    onSuccess = (response) => {
        this.setState({isLoading: false, email:"", errors: {}});
        this.props.onSuccess();
    };


    render(){
        return(
            <form>
                {this.state.isLoading ? <Loader size="small"/> : null}
                <div className="grid-x">
                    <div className="small-12 cell">
                        <TextField name="email" onChange={this.handleFieldChange} value={this.state.email}
                                   placeholder="Email*" />
                    </div>
                    <ErrorMessage message={this.state.errors.base}/>
                </div>
                <div className="small-6 cell text-center form-buttons">
                    <button className="button-orange" onClick={this.handleSubmit}>Reset Password</button>
                </div>
            </form>
        );
    }
}
export default ResetPasswordForm;