import React, { Component } from 'react';
import {TextField, Loader, ErrorMessage, actions, InputTypeField} from 'react-fields';

import RegisterApi from '../../api/register';

class ChangeEmail extends Component {
    state = {
        isLoading: false,
        isChanged: false,
        user: {errors: {}}
    };

    changeEmailSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        RegisterApi.changeEmail(this.state.user, this.onSuccess, this.onError)
    };

    onSuccess = () => {
        this.setState({isLoading: false, isChanged: true, user: {errors: {}}});
    };

    handleFieldChange = (e) => {
        let field_name = e.target.name;
        let user = this.state.user;
        user[field_name] =  e.target.value;
        user.errors[actions.camelize(field_name)] = "";
        if (field_name === 'new_email')
         user.errors[actions.camelize('email')] = "";
        this.setState({user: user});
    };


    onError = (errors) => {
        let user = this.state.user;
        user.errors = errors;
        this.setState({isLoading: false, user: user});
    };

    onPressEnter = (e) =>{
        if (e.key === 'Enter') {
            this.changeEmailSubmit(e);
        }
    };


    render(){
        let user = this.state.user;
        return(
            <React.Fragment>
                {this.state.isChanged ?
                    <React.Fragment>
                        <div style={{margin: 'auto'}} className='ad-icon popUp'/>
                        <h1 className="text-center">Check your email</h1>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell text-center">
                                <div>
                                    <p className="lastText">Your changes will take effect after email confirmation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button onClick={this.props.close} className="button-orange">Close</button>
                        </div>
                    </React.Fragment>
                    :
                    <form style={{position: 'relative'}} className="reveal-form">
                        {this.state.isLoading ? <Loader size="small"/> : null}
                        <label>New Email</label>
                        <InputTypeField type="email" name="new_email"
                                        value={user.new_email}
                                        errorType="tooltip"
                                        onChange={this.handleFieldChange}
                                        errorMessage={user.errors.email}
                        />
                        <label>Email Confirmation</label>
                        <InputTypeField type="email" name="email_confirmation"
                                        value={user.email_confirmation}
                                        errorType="tooltip"
                                        onChange={this.handleFieldChange}
                                        errorMessage={user.errors.emailConfirmation}
                        />
                        <label>Current Password</label>
                        <InputTypeField type="password" name="current_password"
                                        value={user.current_password}
                                        errorType="tooltip"
                                        onChange={this.handleFieldChange}
                                        errorMessage={user.errors.currentPassword}
                                        onKeyPress={this.onPressEnter}
                        />
                        <div className="text-center">
                            <button className="button-orange" onClick={this.changeEmailSubmit}>Save</button>
                        </div>
                    </form>
                }
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close}>
                    <div aria-hidden="true"/>
                </button>
            </React.Fragment>
        )
    }

}

export default ChangeEmail;