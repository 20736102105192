import CF from "../../../common";
import {renderToStaticMarkup} from "react-dom/server";
import ArticleMediaFile from "../bodyMediaFile";
import React from "react";


export const removeRecommendedProduct = (onChange, recommendedProductModal) => {
    let selectedBlock = tinymce.activeEditor.selection.getNode();
    let section = selectedBlock.closest(".recommended-product__container");
    if (!!section){
        section.remove();
    }
    onChange(tinymce.activeEditor.getContent());
    recommendedProductModal.current.close()
};

export const insertRecommendedProduct = (image, image_key, button) => {
    let selectedBlock = tinymce.activeEditor.selection.getNode();
    let section = selectedBlock.closest(".recommended-product__container");
    if (!!section){
        section.remove();
    }
    let url = button.url;
    if (url.indexOf('http')< 0) {
        url = `https://${url}`;
    }
    let newKey = image_key || CF.generateUniqKey();
    const WrapperTag = button.use_aside_tag ? 'aside' : 'section';
    tinymce.activeEditor.execCommand('mceInsertContent', false, renderToStaticMarkup(
        <WrapperTag className={'recommended-product__container'}>
            <p className={'recommended-product__img'}>
                        <span className='article-img-conatainer' data-item={JSON.stringify(image)} id={newKey} contentEditable="false">
                            <ArticleMediaFile item={image} />
                        </span>
            </p>
            <p className={'recommended-product__button'}>
                    <span className={'recommended-product__button--wrapper'}>
                         <a className={'button button-orange button--rounded'}
                            href={url} target={button.target_blank ? '_blank' : ''}
                            rel={button.rel}>{button.label}
                             <span className="recommended-product__button__small-label">{button.small_label}</span></a>
                    </span>

            </p>
        </WrapperTag>));
};

export const onActionRecommendedProductButton = (recommendedProductForm, recommendedProductModal) => {

    const node = tinymce.activeEditor.selection.getNode().closest(".recommended-product__container");
    if (!!node) {
        let img = {};
        let img_key = null;
        let a = node.querySelector('.recommended-product__button--wrapper').querySelector('a');
        let small_label_tag = node.querySelector('.recommended-product__button--wrapper').querySelector('.recommended-product__button__small-label');
        let img_tag = node.querySelector('.article-img-conatainer');
        if(!!img_tag) {
            let img_attributes= JSON.parse(img_tag.getAttribute('data-item'));
            img_key = img_tag.getAttribute('id');
            img = img_attributes;
        }
        let small_label = small_label_tag.textContent;
        let label = a.textContent.replace(small_label,'').trim();
        let rel = a.getAttribute('rel');
        let href = a.getAttribute('href');
        let target = a.getAttribute('target') === 'blank';

        recommendedProductModal.current.open();

        let button = {label: label, small_label: small_label, rel: rel, target_blank: target, url: href};
        recommendedProductForm.current.set(img, img_key, button, 'edit');

    } else {
        recommendedProductModal.current.open();
    }

}