import React from "react";
import NewsItem from './newsItem';

export default function NewsCollection(props) {

    return (
            props.collection.map((article) => {
                return <NewsItem key={article.id} article={article}/>
            })

    )
}