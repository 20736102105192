import React, {Component} from "react";
import Slider from "react-slick";
import SliderArrow from "../../base/sliderArrow";

const DEFAULT_SLIDER_SETTINGS = {
    dots: true,
    dotsClass: 'slider-bar',
    infinite: false,
    arrows: true,
    speed: 600,
    cssEase:  'ease-out',
    variableWidth: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    className: 'about-us__slider about-us__slider--team',
    nextArrow: <SliderArrow direction="slider-arrow slider-arrow--next"/>,
    prevArrow: <SliderArrow direction="slider-arrow slider-arrow--prev"/>,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 1165,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 950,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: true,
                dots: true,
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                variableWidth: true,
                dots: true,
            }
        }
    ]
};

export default class TeamCarousel extends Component {

    state = {
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS
    };

    renderItem = (item, index) => {
        return (<div key={`${this.state.keyPrefix}${index}`} className={'team__profile' + (this.state.active === index ? ' fade-in' : '')} onClick={() => this.handleClick(index)}>
            <div className={'team__profile--block'}>
                <div className={'team__profile--pic'} style={{background: `url(${item.image.original})`}}>
                    <div className="team__hover-block show-only-on-hover">
                        <p className="team__hover-desc">{item.description}</p>
                        {!!item.url ? <a href={item.url} className="team__hover-view">View Profile</a> : null}
                    </div>
                </div>
            </div>

            <p className="team__profile--name">{item.name}</p>
            <p className="team__profile--position">{item.title}</p>
        </div>)
    };


    render() {
        return(
            <div className='grid-x'>
                <Slider {...this.state.settings}>
                    {this.props.collection.map((item, index) => this.renderItem(item, index))}
                </Slider>
            </div>
        )
    }

}