import React, { Component } from 'react';
import { Provider } from 'react-redux';
import AuthorForm from './authorForm';

import {setArticle} from "../../admin_components/actions/articleActions";
import {setSubmitTab} from '../actions/navigationActions';

import store from '../../admin_components/store/store';
import {setGallery} from "../../admin_components/actions/galleryActions";

export default class Root extends Component {

    componentDidMount() {
        store.dispatch(setArticle(this.props.article, this.props.content, 'author', 'Europe/Athens'));
        store.dispatch(setGallery({}));
        store.dispatch(setSubmitTab());
    }

    render() {
        return (
            <Provider store={store}>
                <AuthorForm disabled={this.props.disabled} tinyMceCss={this.props.tinyMceCss} />
            </Provider>
        );
    }
}