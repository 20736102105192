import {actions} from 'react-fields';
import ArticleBodyImagesProcessing from '../articles/articleBodyImagesProcessing';
import moment from 'moment';
import imagesApi from "./imagesApi";

class ArticlesApi {

    static async submit(article, mode, articleState, onSuccess, onError){
        let valid = true;
        let errorRes = '';
        await this.saveArticle(article, mode, articleState).then( async (response) => {
            let id = response['id'];

            await this.saveTopics(id, response['saved_version_id'], article.topics, article.topicAutocomplete, mode).then( async (topicsResponse) => {
                response['topics'] = topicsResponse['topics'];
                valid = valid && true;
            });

            if (mode === 'admin' && !!article.sponsor_logo_options){
                if(article.sponsor_logo_options.delete_logo_id){
                    // await imagesApi.destroyImage(`/api/sponsor_logos/${article.sponsor_logo_options.delete_logo_id}`);
                    response.sponsor_logo = null;
                } else {
                    // let logo_options = article.sponsor_logo_options;
                    // if (response['saved_version_id']) { logo_options['assetable_version_id'] = response['saved_version_id'] }
                    await imagesApi.saveImage('/api/sponsor_logos/save', response.id, {...article.sponsor_logo_options, assetable_version_id: response['saved_version_id']}).then((res) => {
                        response.sponsor_logo = res;
                    });
                }
            }

            if (mode === 'admin' && !!article.company_logo_options){
                if(article.company_logo_options.delete_logo_id){
                    // await imagesApi.destroyImage(`/api/company_logos/${article.company_logo_options.delete_logo_id}`);
                    response.company_logo = null;
                } else {
                    await imagesApi.saveImage('/api/company_logos/save', response.id, {...article.company_logo_options, assetable_version_id: response['saved_version_id']}).then((res) => {
                        response.company_logo = res;
                    });
                }
            }

            if (valid) {
                onSuccess(response, articleState);
            }
        }).catch(error => {
            valid = false;
            errorRes = error;
        });

        if (!valid) {
            if (errorRes.status === 409) {
                console.log('409: stale object error');
                onError({stale: 'Article was updated after page instantiation. Please, reload page.'});
            } else {
                console.log(errorRes);
                errorRes.json().then(function (value) {
                    onError(value);
                });
            }
        }
    }

    static async saveArticle(article, mode, articleState){
        const url = '/api/articles';
        let data = {state: articleState};
        const videoFields = ['video_url', 'video_id', 'video_name', 'video_description', 'video_thumbnail_url', 'video_duration', 'video_upload_date'];
        ['name', 'video_url', 'video_type', 'share_message', 'meta_title', 'meta_description'].map((k) => {
            data[k] = !!article[k] ? article[k].trim() : article[k];
        });
        ['lock_version', 'category_id'].map((k) => {
           data[k] = article[k];
        });

        if (!!article.video_url) {
            videoFields.map(f => (data[f] = article[f]));
        } else {
            videoFields.map(f => (data[f] = ''));
        }

        data['content'] = article['content'] ? ArticleBodyImagesProcessing.replaceImages(article['content']) : '';

        ['industry_id', 'job_title', 'job_description'].map((k) => {
            data[k] = article.cpCategory ? article[k] : '';
        });

        if (article.featured_image) {
            data['featured_image_id'] = article.featured_image.id;
            [['featured_image_id', 'id'], ['image_alt', 'alt'], ['image_source_link', 'source_link'],
                ['image_source_text', 'source_text'], ['image_source_blank', 'source_blank'], ['image_source_nofollow', 'source_nofollow']].map((k) => {
                data[k[0]] = article.featured_image[k[1]];
            });
        } else {
            data['featured_image_id'] = '';
        }

        data['sources_attributes'] = [];
        article.sources.map(function (source, i) {
            data['sources_attributes'].push({id: source.id, name: source.name, url: source.url, date: source.date, nofollow: source.nofollow, _destroy:  '0'});
        });
        article.sourcesDeleted.map(function (source, i) {
            if (source.id)
                data['sources_attributes'].push({id: source.id, name: source.name, url: source.url, date: source.date, nofollow: source.nofollow, _destroy:  '1'});
        });
        if (mode === 'admin') {
            ['path', 'notes', 'adult', 'user_id', 'reviewed_by_id', 'reject_reason', 'how_to', 'version_comment_prefix',
                'advertorial', 'advertorial_label_display', 'author_name', 'footer_disclaimer', 'subtitle', 'show_newsletter_block'].map((k) => {
                data[k] = article[k];
            });

            ['sponsored', 'sponsored_display', 'sponsor_name', 'sponsor_url', 'sponsor_desc', 'sponsor_url_target_blank',
                'sponsor_url_rel', 'sponsor_logo_id'].map((k) => {
                    console.log(k);
                data[k] = article[k];
            });

            ['advertorial_button_text', 'advertorial_button_url', 'advertorial_button_rel', 'advertorial_button_target',
                'company_url', 'company_name', 'company_rel', 'company_logo_id'].map((k) => {
                console.log(k);
                data[k] = article.advertorial ? article[k] : '';
            });

            let moment = require('moment-timezone');
            const timeNow = moment().tz(article.timeZone).format('YYYY-MM-DD HH:mm');

            let date = '';

            if (articleState === 'published' && !!article.current_publish_date && !moment(timeNow).isBefore(article.current_publish_date) && moment(timeNow).isBefore(`${article.publish_date} ${article.publish_time}`)) {
                // console.log('set up republish date');
                date = [article.publish_date, article.publish_time].filter(Boolean).join(' ');
                data['republish_date'] = `${date}:00 ${moment().tz(article.timeZone).format('ZZ')}`;
            } else {
                data['republish_date'] = '';
                const format = 'YYYY/MM/DD HH:mm:ss ZZ';

                date = [article.publish_date, article.publish_time].filter(Boolean).join(' ');
                // console.log(timeNow);
                // console.log(date);
                // console.log(moment(date, format).tz(article.timeZone));
                // console.log(moment.tz(date, article.timeZone));
                // console.log(moment(moment(date, format).tz(article.timeZone), format).tz('Europe/Athens'));
                // console.log(moment(moment(date, format).tz(article.timeZone), format).tz('Europe/Athens').format('YYYY-MM-DD HH:mm'));
                data['publish_date'] = `${date}:00 ${moment().tz(article.timeZone).format('ZZ')}`;
                // console.log(data['publish_date']);
            }
        }
        console.log(data);
        return fetch(actions.createOrUpdateRequest(url, {article: data, mode: mode}, article.id ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static async saveTopics(articleId, versionId, topics, autocompleteTopic, mode){
        const url = '/api/article_topics';
        let data = {topic_ids: [], new_topics: [], mode: mode, version_id: versionId};
        //let data = {topic_ids: topics.map((t) => {return t.id}), mode: mode};
        topics.map((t) => {t.id ? data['topic_ids'].push(t.id) : data['new_topics'].push(t)});
        if (autocompleteTopic.selectedItem && autocompleteTopic.selectedItem.id) {
            data['topic_ids'].push(autocompleteTopic.selectedItem.id)
        } else if (autocompleteTopic.name) {
            data['new_topics'].push({name: autocompleteTopic.name})
        }
        return fetch(actions.createOrUpdateRequest(url, data, articleId ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static async getVideoInfo(videoId, onSuccess, onError) {
        const url = `/api/articles/get_video_info?video_id=${videoId}`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        })
    }

    static delete(articleId, mode, onSuccess, onError) {
        const url = `/api/articles/${articleId}`;
        let data = {mode: mode};
        fetch(actions.deleteRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static search(params){
        let q ='';
        if (params)
          q = '?' + Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
        const url = '/api/articles/search' + q;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }


}
export default ArticlesApi;