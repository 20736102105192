import initialState from './initialState';
import CF from "../common";
import moment from 'moment';

import {
    SET_INITIAL_ARTICLE, UPDATE_ARTICLE, CHANGE_ARTICLE,
    CHANGE_ARTICLE_SOURCE, ADD_ARTICLE_SOURCE, DELETE_ARTICLE_SOURCE, SAVE_ARTICLE_ERRORS
} from "../actions/actionTypes";

export default function article(state = initialState.article, action) {
    switch (action.type) {
        case SET_INITIAL_ARTICLE:
            let attributes = {
                hasChanges: false,
                cpCategory: action.article.category ? action.article.category.career_path : false ,
                errors: {},
                skipIntroValidation: action.article.state === 'published' && !action.article.subtitle,
                sources: prepareSources(action.article.sources),
                featured_image: action.article.featured_image ? Object.assign({}, action.article.featured_image, {
                    alt: action.article.image_alt || action.article.featured_image.alt || '',
                    source_link: action.article.image_source_link || action.article.featured_image.source_link || '',
                    source_text: action.article.image_source_text || action.article.featured_image.source_text ||  '',
                    source_blank: action.article.image_source_blank, source_nofollow: action.article.image_source_nofollow
                }) : null,
                path: !action.article.path && action.article.name && action.mode === 'admin' ? CF.setUrl(action.article.name) : action.article.path,
                topicAutocomplete: {name: '', selectedItem: null},
                sourcesDeleted: prepareSources(action.article.deleted_sources || [])
            };
            if (action.mode === 'admin') {
                attributes['wordsCount'] = null;
                let publishDate = action.article.republish_date || action.article.publish_date;
                let momentDate = moment(publishDate);
                if (action.timeZone) {
                    let moment = require('moment-timezone');
                    momentDate = moment(publishDate).tz(action.timeZone);
                    attributes['timeZone'] = action.timeZone;
                }
                attributes['publish_date'] = publishDate ? momentDate.format('YYYY-MM-DD') : '';
                attributes['publish_time'] = publishDate ? momentDate.format('HH:mm') : '';
                attributes['current_publish_date'] = (action.article.publish_date ? moment(action.article.publish_date).tz(action.timeZone).format('YYYY-MM-DD HH:mm') : null);
                attributes['initialPath'] = action.article.path;
            }
            return { ...action.article, ...attributes };
        case UPDATE_ARTICLE:
            let updatedAttributes = {
                hasChanges: false,
                errors: {},
                id: action.article.id,
                lock_version: action.article.lock_version,
                sources: prepareSources(action.article.sources),
                sourcesDeleted: prepareSources(action.article.deleted_sources),
                topics: action.article.topics,
                topicAutocomplete: {name: '', selectedItem: null},
                state: action.article.state,
                state_name: action.article.state_name,
                reject_reason: action.article.reject_reason,
                republish_date: action.article.republish_date,
                sponsor_logo_options: null,
                sponsor_logo: action.article.sponsor_logo,
                company_logo: action.article.company_logo,
                company_logo_options: null,
                initialPath: action.article.path,
                version_comment_prefix: null
            };
            if (state.mode === 'admin' && action.article.publish_date) {
                let moment = require('moment-timezone');
                updatedAttributes['current_publish_date'] = (action.article.publish_date ? moment(action.article.publish_date).tz(state.article.timeZone).format('YYYY-MM-DD HH:mm') : null);
            }
            ['video_id', 'video_name', 'video_description', 'video_thumbnail_url', 'video_duration',
                'video_upload_date'].map(f => (updatedAttributes[f] = action.article[f]));
            return Object.assign({}, state, updatedAttributes);
        case CHANGE_ARTICLE:
            return Object.assign({}, state, action.article, {hasChanges: true});
        case SAVE_ARTICLE_ERRORS:
            let errorAttributes = {errors: action.errors};
            let sourcesErrors = action.errors['sources'];
            if (sourcesErrors) {
                errorAttributes['sources'] = state.sources.map(item => {
                    if (sourcesErrors[item.key]) {
                        return { ...item, ...{errors: sourcesErrors[item.key]}}
                    }
                    return item
                });
                delete errorAttributes['errors']['sources'];
            }
            return Object.assign({}, state, errorAttributes);
        case ADD_ARTICLE_SOURCE:
            let newSource = new Object({ key: CF.generateUniqKey(), errors: {} });
            return Object.assign({}, state, {sources: [...state.sources, newSource]});
        case CHANGE_ARTICLE_SOURCE:
            return Object.assign({}, state, {sources: state.sources.map(item => {
                    if(item.key === action.source.key){
                        return { ...item, ...action.source}
                    }
                    return item
                })});
        case DELETE_ARTICLE_SOURCE:
            let sources = Object.assign([], state.sources);
            let index = state.sources.findIndex(f => f.key === action.source.key);
            let deletedSource = state.sources[index];
            sources.splice(index, 1);
            return Object.assign({}, state, {sources: sources, sourcesDeleted: [...state.sourcesDeleted, deletedSource]});
        default:
            return state;
    }
}

function prepareSources(sources) {
    return sources.map((item) => {
        let attributes = { key: CF.generateUniqKey(), errors: {}, date: (item.date ? moment(item.date).format('DD/MM/YYYY') : '') };
        return { ...item, ...attributes }
    });
}
