import React, {Component, Fragment} from 'react';
import {SelectField, TextField} from "react-fields";
import {CheckboxField} from "react-fields/src";

class ButtonShortcodeModal extends Component {

    state = {
        id: this.props.id || Math.random().toString(36).substring(7),
        button: { errors: []},
        isOpened: false
    };

    handleFieldChange = (e) => {
        let button = this.state.button;
        const name = e.target.name;
        button[name] = ['target_blank', 'nofollow', 'sponsored', 'paid_link', 'small_font'].includes(name) ? e.target.checked :  e.target.value;
        button.errors[e.target.name] = '';
        this.setState({button: button})
    };

    isValid = () => {
        let button = this.state.button;
        let valid = true;
          if(!button.name) {
              button.errors.name = "Anchor Text can't be blank";
              valid = false;
          }
        if(!button.url) {
            button.errors.url = "Url can't be blank";
            valid = false;
        }
        if(!button.style) {
            button.errors.style = "Style can't be blank";
            valid = false;
        }
        this.setState({button: button});
        return valid;
    };

    submit = () => {

        if (this.isValid()) {
            this.props.onSave(this.state.button);
            this.props.close();
        }
    };

    render() {
        return(
            <div className={'custom-form'}>
                <h1>Select Button Options</h1>
                 <TextField name={'name'}
                            onChange={this.handleFieldChange}
                            placeholder={'Anchor text'}
                            className={'mar-b-10'}
                            value={this.state.button.name}
                            errorMessage={this.state.button.errors.name} />
                <TextField name={'url'}
                           onChange={this.handleFieldChange}
                           placeholder={'URL'}
                           className={'mar-b-10'}
                           value={this.state.button.url}
                           errorMessage={this.state.button.errors.url} />
                <CheckboxField onChange={this.handleFieldChange}
                               name='nofollow'
                               className={'mar-b-10'}
                               value={this.state.button.nofollow}
                               label='nofollow'
                />
                <CheckboxField onChange={this.handleFieldChange}
                               name='sponsored'
                               className={'mar-b-10'}
                               value={this.state.button.sponsored}
                               label='sponsored'
                />
                <CheckboxField onChange={this.handleFieldChange}
                               name='target_blank'
                               className={'mar-b-10'}
                               value={this.state.button.target_blank}
                               label='Open in new window'
                               />

                <CheckboxField onChange={this.handleFieldChange}
                               name='paid_link'
                               className={'mar-b-10'}
                               value={this.state.button.paid_link}
                               label="Add 'Paid link' label"
                />
                <SelectField name={'style'}
                             onChange={this.handleFieldChange}
                             placeholder={'Select button style'}
                             className={'mar-b-10'}
                             selected={this.state.button.style}
                             options={['orange', 'grey', 'green']}
                             errorMessage={this.state.button.errors.style} />
                <SelectField name={'size'}
                             onChange={this.handleFieldChange}
                             placeholder={'Select button size'}
                             className={'mar-b-10'}
                             selected={this.state.button.size}
                             options={['normal', 'full']}
                             errorMessage={this.state.button.errors.size} />
                <CheckboxField onChange={this.handleFieldChange}
                               name='small_font'
                               className={'mar-b-10'}
                               value={this.state.button.small_font}
                               label="Use small font size"
                />
              <SelectField name={'icon'}
                             onChange={this.handleFieldChange}
                             placeholder={'Select button icon'}
                             className={'mar-b-10'}
                             selected={this.state.button.icon}
                             options={['arrow-white-right']}
                             errorMessage={this.state.button.errors.icon} />
                <button className="button-orange" onClick={this.submit}>Insert into Post</button>
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </div>
        )
    }

}
export default ButtonShortcodeModal;