import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as articleActions from '../../actions/articleActions';

import Autocomplete from 'react-autocomplete';
import {actions, ErrorTooltip} from "react-fields";
import Topic from "./topic";

class TopicsArea extends Component {

    state = { topicsList: [] };

    updateTopicsList = (value) => {
        if (!value){
            this.setState({topicsList: []});
            return;
        }

        let _this = this;
        let url = '/api/topics/autocomplete?';
        fetch(actions.getData(`${url}q=${value}`))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({topicsList: data});
            });
    };

    changeArticle = (attribute, value) => {
        let article = this.props.article;
        article[attribute] = value;
        article['errors']['topics'] = '';
        this.props.actions.changeArticle(article);
    };

    handleTopicKey = (e) => {
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            this.handleAddTopic()
        }
    };

    handleAutocompleteSelect = (value, item) => {
        //this.setState({topicValue: value, currentSelectedTopic: item});
        this.changeArticle('topicAutocomplete', {name: value, selectedItem: item});
    };

    handleTopicAutocompleteChange = (e) => {
        //this.setState({topicValue: e.target.value, currentSelectedTopic: null});
        this.changeArticle('topicAutocomplete', {name: e.target.value, selectedItem: null});
        this.updateTopicsList(e.target.value);
    };

    handleAddTopic = () => {
        if (!(this.props.article.topicAutocomplete.selectedItem || this.props.article.topicAutocomplete.name)) { return }
        if (this.props.mode !== 'admin' && !this.props.article.topicAutocomplete.selectedItem) {
            this.props.actions.changeArticle({...this.props.article, errors: {...this.props.article.errors, topics: 'You are not allowed to create new topics'}});
            return;
        }
        let newTopic = this.props.article.topicAutocomplete.selectedItem || {name: this.props.article.topicAutocomplete.name};
        this.handleTopicChange(newTopic, true);
    };

    handleTopicChange = (topic, add) => {
        if (!topic) { return }
        let topics = this.props.article.topics;
        if (add) {
            if (!topics.find(t => topic.id ? t.id === topic.id : t.name.toLowerCase() === topic.name.toLowerCase())) {
                topics.push(topic);
            }
            this.changeArticle('topicAutocomplete', {name: '', selectedItem: null});
            //this.setState({topicValue: '', currentSelectedTopic: null});
        } else {
            topics = topics.filter(function(v) {return topic.id !== v.id;});
        }
        this.changeArticle('topics', topics);
    };

    renderTopicInput = (props) => {
        return (
            <Fragment>
                <input {...props} /><span className='label-icon-right plus' onClick={this.handleAddTopic} style={{marginBottom:'-30px',zIndex:3}} />
            </Fragment>
        )
    };

    renderMenu = (items, value, style) => {
        if (items.length === 0) {return <div />;}
        return <div style={{ ...style, ...this.menuStyle }} children={items}/>
    };

    render() {
        let article = this.props.article;
        let _this = this;
        return(
            <Fragment>
                <div className={article.errors.topics ? 'err' : ''}>
                    <Autocomplete
                        getItemValue={(item) => item.name}
                        items={this.state.topicsList}
                        inputProps={{placeholder: "Add a topic", type: 'text', onKeyUp: this.handleTopicKey}}
                        wrapperStyle={{position: 'relative'}}
                        renderInput={this.renderTopicInput}
                        renderMenu={this.renderMenu}
                        renderItem={(item, isHighlighted) =>
                            <div key={`topic${item.id}`} style={{ background: isHighlighted ? 'lightgray' : '#f6f6f6',zIndex:2, padding:'10px 20px',color:'#4D4D4D',fontFamily:'Sofia-Pro-Light' }}>
                                {item.name}
                            </div>}
                        value={this.props.article.topicAutocomplete.name}
                        onChange={this.handleTopicAutocompleteChange}
                        onSelect={this.handleAutocompleteSelect}
                    />
                    <ErrorTooltip message={article.errors.topics} />
                </div>

                <div className="grid-x grid-padding-x selected-topics-wrapper">
                    {article.topics.map(function (t, i) {
                        return (<Topic key={`topic${i}`} name={t.name} delete={() => _this.handleTopicChange(t, false)} />)
                    })}
                </div>
            </Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        article: state.article
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions), dispatch)
    };
}

TopicsArea.contextTypes = {
    store: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicsArea);