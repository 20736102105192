import React, { Component } from 'react';
import { Loader, actions, InputTypeField} from 'react-fields';

import RegisterApi from '../../api/register';

class ChangePassword extends Component {

    state = {
        isLoading: false,
        isChanged: false,
        user: {errors: {}}
    };

    changePasswordSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true, user: {errors: {}}});
        RegisterApi.changePassword(this.state.user, this.onSuccess, this.onError)

    };

    onSuccess = () => {
        this.setState({isLoading: false, isChanged:true});
    };

    handleFieldChange = (e) => {
        let field_name = e.target.name;
        let user = this.state.user;
        user[field_name] =  e.target.value;
        user.errors[actions.camelize(field_name)] = "";
        this.setState({user: user});
    };


    onError = (errors) => {
        let user = this.state.user;
        user.errors = errors;
        this.setState({isLoading: false, user: user});
    };


    render(){
        let user = this.state.user;
        return(
            <React.Fragment>
                {this.state.isChanged ?
                    <React.Fragment>
                        <div style={{margin: 'auto'}} className='ad-icon popUp'/>
                        <h1 className="text-center">Changes saved successfully.</h1>
                        <div className="grid-x grid-padding-x">
                            <div className="small-12 cell text-center">
                                <div/>
                            </div>
                        </div>
                        <div className="text-center">
                            <button onClick={this.props.close} className="button-orange">Close</button>
                        </div>
                    </React.Fragment>
                    :
                    <form onSubmit={this.changePasswordSubmit} style={{position: 'relative'}} className="reveal-form">
                        {this.state.isLoading ? <Loader size="small"/> : null}
                        <label>New Password</label>
                        <InputTypeField type="password"
                                        errorType="tooltip"
                                        name="new_password" value={user.new_password}
                                        onChange={this.handleFieldChange}
                                        errorMessage={user.errors.password}
                        />
                        <label>Password Confirmation</label>
                        <InputTypeField type="password"
                                        name="password_confirmation"
                                        errorType="tooltip"
                                        value={user.password_confirmation}
                                        onChange={this.handleFieldChange}
                                        errorMessage={user.errors.passwordConfirmation}
                        />
                        <label>Current Password</label>
                        <InputTypeField type="password"
                                        name="current_password"
                                        errorType="tooltip"
                                        value={user.current_password}
                                        errorMessage={user.errors.currentPassword}
                                        onChange={this.handleFieldChange}/>
                        <div className="text-center">
                            <button onClick={this.changePasswordSubmit} className="button-orange">Save</button>
                        </div>
                    </form>
                }
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close}>
                    <div aria-hidden="true"/>
                </button>
            </React.Fragment>
        )
    }

}


export default ChangePassword;