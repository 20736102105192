import React, { Component } from 'react';
import PropTypes from "prop-types";
import {TextField, Loader, actions, InputTypeField} from 'react-fields';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import * as userActions from '../actions/profileActions';
import userApi from '../api/user';
import SimpleModal from "../modals/simpleModal";
import {setSettingsTab} from '../actions/navigationActions';
import store from '../store/store';
import CustomCheckboxField from "../customFields/checkbox";
import ChangeEmail from './settings/changeEmail';
import ChangePassword from "./settings/changePassword";

import PopUp from '../modals/popup';

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };

        this.editEmailModal = React.createRef();
        this.editPasswordsModal = React.createRef();
    }


    componentDidMount() {
        store.dispatch(setSettingsTab());
    }

    submitForm = (e) => {
        e.preventDefault();
        let _this = this;
        this.setState({isLoading: true}, () => {
            userApi.save(_this.props.profile, _this.afterSave, _this.onError)
        });

    };

    afterSave = () => {
        this.setState({isLoading: false});
        this.refs.afterSaveModal.open();
    };


    handleFieldChange = (e) => {
        let field_name = e.target.name;
        let profile = this.props.profile;
        profile[field_name] = field_name === 'receive_article_comments' ? e.target.checked : e.target.value;
        profile.errors[actions.camelize(field_name)] = "";
        this.props.actions.changeAuthorProfile(profile);
    };


    onError = (errors) => {
        let profile = this.props.profile;
        profile.errors = errors;
        this.props.actions.changeAuthorProfile(profile);
        this.setState({isLoading: false});
    };



    render(){
        let profile = this.props.profile;
       return(
           <div style={{position: 'relative'}}>
               {this.state.isLoading ? <Loader size="small"/> : null}
               <div className="grid-x">
                   <div className="large-6 cell pad-column settings">
                       <div className="grid-x name-row">
                           <div className="medium-6 cell padR5">
                               <label>First name</label>
                               <TextField name="first_name" onChange={this.handleFieldChange}
                                          errorMessage={profile.errors.firstName} errorType="tooltip"
                                          value={profile.first_name} placeholder="First Name"/>
                           </div>
                           <div className="medium-6 cell padL5">
                               <label>Last name</label>
                               <TextField name="last_name" onChange={this.handleFieldChange}
                                          errorMessage={profile.errors.lastName} errorType="tooltip"
                                          value={profile.last_name} placeholder="Last Name"/>
                           </div>
                       </div>
                       <div className="grid-x">
                           <div className="medium-6 cell padR5">
                               <label>Email address</label>
                               <TextField name="email" onChange={this.handleFieldChange}
                                          errorMessage={profile.errors.email} errorType="tooltip"
                                          value={profile.email} placeholder="Email" disabled={true}/>
                               <p onClick={() => {this.editEmailModal.current.open()}} className="text-button secondary-link">Change</p>
                               <PopUp ref={this.editEmailModal}>
                                  <ChangeEmail close={() => {this.editEmailModal.current.close()}} />
                               </PopUp>
                           </div>
                           <div className="medium-6 cell padL5">
                               <label>Password</label>
                               <InputTypeField name="password" onChange={this.handleFieldChange}
                                               disabled={true} type="password"
                                               value={"password"} placeholder="Password"/>
                               <p className="text-button secondary-link" onClick={() => {this.editPasswordsModal.current.open()}}>Change</p>
                               <PopUp ref={this.editPasswordsModal}>
                                   <ChangePassword close={() => {this.editPasswordsModal.current.close()}} />
                               </PopUp>
                           </div>
                       </div>
                   </div>
                   <div className="large-6 cell notifications">
                       <label>Notifications</label>
                       <CustomCheckboxField label="Yes, I would like to receive email alerts for any comments left on my articles."
                                            onChange={this.handleFieldChange}
                                            value={profile.receive_article_comments}
                                            name="receive_article_comments"
                       />

                   </div>
               </div>
               <div className="grid-x">
                   <div className="small-12 cell">
                       <div className="buttons-area text-center">
                           <button className="button-orange" onClick={this.submitForm}>Save</button>
                       </div>
                   </div>
               </div>

               <SimpleModal ref='afterSaveModal'
                             title="Changes saved successfully."
                             sprite="ad-icon popUp"
                             button="Close"
               />
           </div>
       )

    }
}
Settings.propTypes = {
    profile: PropTypes.object.isRequired

};
function mapStateToProps(state) {
    return {
        profile: state.profile

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, userActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);