import {actions} from 'react-fields'

class CvTemplatesApi {

    static getList(onLoad, package_id = null, page = null) {
        let url = `/api/cv_templates`;
        if (package_id){
            url = `${url}?package_id=${package_id}`
        }
        if (page){
            url = `${url}${package_id ? '&' : '?'}page=${page}`
        }
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

    static getFeatured(onLoad) {
        let url = '/api/cv_templates/featured';
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

    static search(onLoad, options) {
        let url = `/api/cv_templates/search`;
        let paramsStr = Object.keys(options).map(function(k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(options[k]);
        }).join('&');
        fetch(actions.getData(`${url}?${paramsStr}`))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response, options);
        }).catch(error => {
            console.log(error);
        })
    }

    static getCategories(onLoad) {
        let url = '/api/cv_template_categories';
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

    static getInitialPrice(onLoad) {
        const url = `/api/cv_templates/initial_price`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

    static getBundlePrice(onLoad) {
        const url = `/api/cv_templates/bundle_price`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

}

export default CvTemplatesApi;