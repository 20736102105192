
import * as types from './actionTypes';


export function setAuthorProfile(profile) {
    return {
        type: types.SET_AUTHOR_PROFILE,
        profile: profile,

    };
}

export function changeAuthorProfile(profile) {
    return {
        type: types.CHANGE_AUTHOR_PROFILE,
        profile: profile,

    };
}
