import {actions} from 'react-fields'

class PasswordApi {

    static resetPassword(email, onSuccess, onError) {
        const url = '/password';
        let data = { user: {
                email: email,
            }};

        fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static updatePassword(user, onSuccess, onError) {
        const url = '/password';
        let data = { user: {
                password: user.new_password,
                password_confirmation: user.password_confirmation || "",
                reset_password_token: user.reset_password_token
            }};

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

}

export default PasswordApi;