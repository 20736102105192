import {actions} from 'react-fields';

class RelatedArticlesApi {

    static related(id){
        const url = `/api/articles/${id}/related`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static featured(portlet_type){
        let url = '/api/articles/featured';
        if (!!portlet_type) {
            url = `${url}?${actions.paramsToURI({portlet_type: portlet_type})}`;
        }
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

}
export default RelatedArticlesApi;