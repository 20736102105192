import {actions} from 'react-fields'

class CommentApi {

    static update(key, comment) {
        const url = '/api/comments/' + key;
        let data = { comment: {
                notify_replies: comment.notify_replies
            } };

        fetch(actions.putRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {

            });
        });
    }

}

export default CommentApi;