import {actions} from 'react-fields'

class LoginApi {

    static login(user, onSuccess, onError) {
        const url = '/login';
        let data = { user: {
                email: user.email,
                password: user.password
            }, remember_me: user.remember_me };

        fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }

    static delete() {
        const url = `/login`;
        let data = {};

        return fetch(actions.deleteRequest(url, data ))
            .then(actions.status)
            .then(actions.json)

    }

}

export default LoginApi;