
export default {
    profile: {errors: {}},
    account: {},
    pressNavigation: {},
    article: {errors: {}, sources: [], topicAutocomplete: {}, topics: []},
    directPayment: {  userDetails: {errors: {}},
        cardDetails: {errors: {}},
        payPalDetails: {errors: {}},
        applePayDetails: {errors: {}}
    },
    cvWriting: {
        cv_writing_package: {extras: []},
        currency: {},
        userDetails: {cv_file: {}, errors: {}},
        cardDetails: {errors: {}},
        payPalDetails: {errors: {}},
        applePayDetails: {errors: {}}
        },
    flexibleForm: {fields: [], errors: {}},
    adBanners: {collection: []},
    cvTemplate: {
        cv_template: {images: []},
        userDetails: {errors: {}},
        currency: {},
        cardDetails: {errors: {}},
        payPalDetails: {errors: {}},
        applePayDetails: {errors: {}}
    },
    gallery: {deletedIds: [], uploadedIds: []}
}