import React, {Component, Fragment} from 'react';
import {actions, Loader} from "react-fields";

import cf from "../common";
import ListTop from "../partials/listTop";
import List from "./list";
import NoResults from './noResults';
import AdsLoader from '../adBanners/adsLoader';
import DropDownButton from "../dropDownButton";

import Pagination from "../partials/seoPaginationButtons";
import PaginationMeta from '../paginationMeta';

const FILTER_SETTINGS = {
    industry: {field: 'industry_id', url: '/api/industries', label: 'All Industries'},
    video_category: {field: 'category_id', url: '/api/categories/categories_with_video', label: 'All categories'},
    topic: {field: 'topic_id', url: '/api/topics/by_category', label: 'All Topics'}
};

export default class Root extends Component {

    state = {
        currentFilter: null,
        currentCategoryFilter: null,
        q: this.props.q || '',
        qTerm: this.props.q || '',
        rendered: this.props.preloaded,
        isLoading: !this.props.preloaded,
        collection: this.props.collection
    };

    contentRef = React.createRef();

    componentDidMount() {
        this.renderStyles();
        const curPage = !!this.props.page ? parseInt(this.props.page) : 1;
        if (!this.props.preloaded) {
            this.loadArticles(curPage);
        } else {
            this.loadCommentCounts();
        }
        if (this.props.filterType) {
            let _this = this;
            let url = FILTER_SETTINGS[this.props.filterType]['url'];
            if (this.props.filterType === 'topic') {
                url = `${url}?category_id=${this.props.params['category_id']}`;
            }
            fetch(actions.getData(url))
                .then(actions.status)
                .then(actions.json)
                .then(function(data) {
                    if (data && data.length > 0) {
                        let item = null;
                        const filedName = FILTER_SETTINGS[_this.props.filterType]['field'];
                        if (!!_this.props[filedName]) {
                            const item_id = parseInt(_this.props[filedName]);
                            item = data.find((c) => c.id === item_id)
                        }
                        _this.setState({filterCollection: [{id: '', name: 'All'}].concat(data), currentFilter: item});
                        let navigationParams = {page: curPage};
                        if (!!item) { navigationParams[FILTER_SETTINGS[_this.props.filterType]['field']] = item.id; }
                        history.replaceState(navigationParams, '', document.location.href);
                    }

                });
        }
        history.replaceState({page: curPage}, '', document.location.href);
        window.onpopstate = (event) => {
            let stateParams = {isLoading: true, q: event.state['q'] || ''};
            if (this.props.filterType) { stateParams[FILTER_SETTINGS[this.props.filterType]['field']] = event.state[FILTER_SETTINGS[this.props.filterType]['field']]; }
            this.setState(stateParams, () => { this.loadArticles((event.state.page || 1), true) });
        };
    }

    loadArticles = (page, skipUpdateHistory) => {
        let _this = this;
        const url = this.props.url || '/api/articles/list';
        const urlParams = this.prepareOptionsSearch(page);
        const paramsStr = actions.paramsToURI(Object.assign({}, this.props.params, urlParams));
        const updateHistory = !!this.state.rendered && !skipUpdateHistory;
        const needScroll = this.state.rendered;
        fetch(actions.getData(`${url}?${paramsStr}`))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                _this.setState({rendered: true, isLoading: false, collection: data}, () => { if (needScroll) {_this.scrollToTopOfList();} });
                if(!!_this.props.seo){
                    let perPage = _this.props.params && _this.props.params.per_page ? _this.props.params.per_page : 14;
                    PaginationMeta.update(urlParams, data.count, data.total, perPage, updateHistory);
                }

            });
    };

    loadCommentCounts = () => {
        let _this = this;
        const paramsStr = actions.paramsToURI({ids: this.state.collection.articles.reduce((a, o) => (a.push(o.id), a), [])});
        fetch(actions.getData(`/api/comments/for_articles?${paramsStr}`))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                let updatedCollection = _this.state.collection.articles.map((a) => {return {...a, comments_count: data[a.id] || 0}});
                _this.setState({collection: {..._this.state.collection, articles: updatedCollection}});
            });
    };

    prepareOptionsSearch = (page) => {
        let params = page === 1 ? {} : {page: page};
        if (this.props.filterType && this.state.currentFilter && this.state.currentFilter.id) {
            params[FILTER_SETTINGS[this.props.filterType]['field']] = this.state.currentFilter.id;
        }

        return params;
    };

    goToPage = (e, page, skipUpdateHistory) => {
        if (e) { e.preventDefault(); }
        this.setState({isLoading: true}, () => { this.loadArticles(page, skipUpdateHistory) });
    };

    scrollToTopOfList = () => {
        const scrollTo = this.contentRef.current.getBoundingClientRect().top + document.documentElement.scrollTop - 110;
        window.scrollTo({top: scrollTo, behavior: 'smooth'});
    };

    handleFilterChange = (filter) => {
        this.setState({currentFilter: filter}, () => { this.loadArticles(1); });
    };

    renderStyles = () => {
        // не используется на этой странице!!!
        if (!this.props.btnColor) { return null; }
        let styles = [`.category-color {background-color:${this.props.btnColor} !important;}`,
            `.category-color:hover {background-color: ${cf.colorLuminance(this.props.btnColor, -0.15)} !important;}`];
        const style = document.createElement('style');
        style.innerText = styles.join(' ');
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(style);
       // return (<style>{styles.join(' ')}</style>);
    };

    renderPreList = () => {
        return null;
    };

    render() {
        if (!this.state.rendered) {
            return (<Loader/>)
        }

        let seoProps = !!this.props.seo ? {itemType: 'http://schema.org/CollectionPage', itemScope: true} : {};

        return (
            <React.Fragment>
            {this.renderPreList()}
            <div ref={this.contentRef} className={this.props.forSearch ? 'grid-container search-results' : ''}>
                {this.state.isLoading ? <Loader size="small unconfirmed"/> : null}

                {this.props.hideListTop ? null :
                <ListTop count={this.state.collection.count} header='Article' headerPostfix={this.props.header} >
                    {this.props.filterType && this.state.filterCollection ? <div className="filter-buttons text-right">
                        <DropDownButton name={FILTER_SETTINGS[this.props.filterType]['label']} icon='caret-down' value={this.state.currentFilter} onChange={this.handleFilterChange}
                                        options={this.state.filterCollection} optionsLabel='name' whiteFilter={this.props.whiteFilter} />
                    </div> : null}
                </ListTop>}

                {this.state.collection.count > 0 ?
                <Fragment>
                    <div className="grid-x grid-padding-x list-articles" {...seoProps}>
                        {!!this.props.seo ? <React.Fragment>
                            <meta itemProp="name" content={this.props.seo.headline || "Latest Articles"}/>
                            <meta itemProp="headline" content={this.props.seo.headline || "Latest Articles"}/>
                            <meta itemProp="description" content={this.props.seo.description}/>
                            {!!this.props.pagePath ? <meta itemProp="url" content={this.props.pagePath}/> : null }
                        </React.Fragment> : null}

                        <List collection={this.state.collection.articles} spots={this.state.collection.spots}
                               careerPath={this.props.careerPath} withSeo={!!this.props.seo} itemColumnsClass={this.props.itemColumnsClass} useLargeImage={this.props.useLargeImage} />
                    </div>

                    <div className="grid-x">
                        <div className="small-12 cell">
                            <Pagination page={parseInt(this.state.collection.page)} options={this.prepareOptionsSearch(1)} path={this.props.pagePath}
                                        totalPages={this.state.collection.total}
                                        disablePaginationLinks={this.props.disablePaginationLinks}
                                        goToPage={this.goToPage}/>
                        </div>
                    </div>

                    <AdsLoader params ={{banner_type: 'article_list'}} />
                </Fragment>
                :
                <NoResults text={this.props.noResultText}/> }
            </div>
            </React.Fragment>
        );
    }
}