import React from "react";
import CustomCheckboxField from "../customFields/checkbox";
import {ErrorTooltip} from "react-fields";

export default function Multiselect(props) {

    const handleFieldChange = (e, option) => {
        let newValues = [...props.value];
        let checked = e.target.checked;
        if (checked) {
            newValues.push(option)
        } else {
            newValues = newValues.filter((v) => v !== option)
        }
      props.onChange(newValues);
    };

    return <div className={"multeselect-area" + (props.errorMessage ? ' err' : '')}>
        <label className={'multeselect-area__label'}>{props.placeholder}</label>
        <div className={'multeselect-area__collection'}>
            {props.options.map((option, index) => {
                return   <CustomCheckboxField label={option}
                                              key={index}
                                              onChange={(e) => handleFieldChange(e, option)}
                                              value={props.value.indexOf(option) >= 0}
                                              name={option}
                />
            })}
        </div>
        <ErrorTooltip message={props.errorMessage}  />

    </div>

}