import {actions} from 'react-fields'
import * as db from '../Questionnaire/questions'

class QuestionnaireApi {

    static loadResultsDetails(onLoad) {
        let articles = db.results.map((res) => res.results.map((answ) => answ.article_id)).flat().filter( Number );
        let url = `/api/questionnaires/result_data?${actions.paramsToURI({articles: articles})}`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }



}

export default QuestionnaireApi;