import React from "react";
import InlineInputSubscription from "../../subscriptions/inlineInput";

export default function AdBannerNewsletter(props) {
    return (<div className="light-side-ad-container pad-top-side-20 side-ad-subscription tablet-flex
            subscription-cv-download-ad">
        <div className="side-ad-inner-block">
            <div className="side-ca-left-block">
                <div className="subscription-cv-download__title fnt-31">
                    GRAB YOUR <u>FREE</u> RESUME TEMPLATE</div>
                <InlineInputSubscription {...props.passedAttrs} />
            </div>
        </div>
        <div className="text-center mar-top-10 article-card-banner__img">
            <img src="/images/ads/sidebar-archive.png" alt="resume template"/>
        </div>
    </div>)
}