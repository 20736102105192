import React from "react";
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "./subscriptionActionHOC";
import LazyLoad from 'react-lazyload';

const SubscriptionForm = SubscriptionActionHOC(props => {
    let divClasses = 'input-with-button-download';
    if (props.formAttrs.small) {divClasses += ' input-with-button-download--small input-with-button--max-width-422';}
    if (props.formAttrs.big) {divClasses += ' subscribe__big';}
    if (props.formAttrs.dark) {divClasses += ' subscribe__dark';}
    if (props.formAttrs.gray) {divClasses += ' subscribe__gray';}
    if (props.formAttrs.icon) {divClasses += ' subscribe__icon';}

    let btnText = props.formAttrs.btnText || (props.formAttrs.hasGift ? 'DOWNLOAD' : 'Subscribe');
    let subText = props.formAttrs.subText || (props.formAttrs.hasGift ? 'By submitting your email, you agree to receive our daily newsletter.' : 'Sent 3 times per week. Unsubscribe whenever you want.');

    return (
        <div className={divClasses}>
            <InputTypeField type='email' name="email" value={props.form.email} errorMessage={props.form.errors.email}
                            onChange={props.setEmail}
                            onKeyPress={props.submitOnEnter}
                            placeholder="Your email" />
            <button className="button-orange" onClick={props.submitForm}>{props.formAttrs.icon ? <span className="send-icon"/> : <React.Fragment>{btnText} <span className="small-icon arrow-white-right-small" /></React.Fragment>}</button>
            {!props.formAttrs.skipSubText && <div className={'subscription-cv-download__desc'}
                                                  dangerouslySetInnerHTML={{__html: subText}} />}
        </div>
    )
});

export default function LazyInlineInputSubscription(props) {
    return (<LazyLoad><SubscriptionForm {...props} /></LazyLoad>)
}
