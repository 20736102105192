import React, {useState} from 'react';
import Category from './Category';

export default function CategoriesSettings(props) {
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);

    const expandCategory = (categoryId) => {
        setExpandedCategoryId(categoryId === expandedCategoryId ? null : categoryId);
    };

    return (
            props.categories.map((category) => {
                return  <Category key={category.id}
                                  category={category}
                                  expandCategory={expandCategory}
                                  expanded={category.id === expandedCategoryId}
                                  selectCategory={props.selectCategory} />;
            })

    )
}