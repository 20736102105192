import initialState from './initialState';
import { SET_INITIAL_GALLERY, CHANGE_GALLERY_SETTINGS, SET_GALLERY_ITEM, CHANGE_GALLERY_ITEM, UPLOAD_GALLERY_ITEM, DELETE_GALLERY_ITEM } from '../actions/actionTypes';

export default function gallery(state = initialState.gallery, action) {
    switch (action.type) {
        case SET_INITIAL_GALLERY:
            let initialMode = action.gallery.mode || 'view';
            let attributes = {
                mode: initialMode,
                item: prepareItem(action.gallery.item || {}, initialMode, []),
                uploadedIds: [],
                deletedIds: [],
                screen: action.gallery.screen || (!action.gallery.item ? 'upload' : 'index')
            };
            return { ...action.gallery, ...attributes };
        case CHANGE_GALLERY_SETTINGS:
            return Object.assign({}, state, action.settings);
        case SET_GALLERY_ITEM:
            return Object.assign({}, state, {item: prepareItem(action.item || {}, state.mode, state.uploadedIds), screen: 'index'});
        case CHANGE_GALLERY_ITEM:
            return Object.assign({}, state, {item: action.item});
        case UPLOAD_GALLERY_ITEM:
            let uploadedIds = state.uploadedIds;
            uploadedIds.push(action.item.id);
            return Object.assign({}, state, {uploadedIds: uploadedIds, itemType: state.itemType || '', reUploadItem: null});
        case DELETE_GALLERY_ITEM:
            let deletedIds = state.deletedIds;
            deletedIds.push(action.item.id);
            return Object.assign({}, state, {item: prepareItem({}, state.mode, state.uploadedIds),
                                                            screen: 'index',
                                                            deletedIds: deletedIds});
        default:
            return state;
    }
}

function prepareItem(item, galleryMode, uploadedImageIds) {
    return Object.assign(item, {
        errors: {},
        isAllowedToEdit: (galleryMode === 'admin' || (item.id && uploadedImageIds.includes(item.id))),
        link_type: !item.link_to ? 'none' : 'url',
        link_to_nofollow: !!item.link_to_nofollow,
        link_to_blank: item.link_to_blank !== false,
        source_nofollow: item.source_nofollow !== false,
        source_blank: item.source_blank !== false,
        shadow: !!item.shadow})
}