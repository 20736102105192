import React from "react";
import articlesApi from "./relatedAPi";
import ArticleCard from "../articles/partials/articleCard";

class Index extends React.Component {

    state = {
        isLoading: true,
        articles: [],
        articlesContainerId: 'test'
    };

    componentDidMount() {
        this.getRelated();
    }


    getRelated = () => {
        let _this = this;
        let promise = this.props.featured ? articlesApi.featured(this.props.portlet_type) : articlesApi.related(this.props.article_id);
        promise.then((response) => {
            this.setState({isLoading: false, articles: response});
        });
    };

    render(){
        return(
            <div className="grid-x related-articles content-width" id={this.state.articlesContainerId}>
                {this.state.articles.map((article) => {
                    return (<div className="large-3 medium-6 small-12 cell" key={`article${article.id}`} >
                        <ArticleCard article={article} lazyLoading={true} wrapInDiv={true} shortInfo={true} />
                    </div>)
                })}
            </div>
        )
    }

}

export default Index;