import React, {Fragment} from 'react';
import {getButtonShortcode} from "./tinymceShrtocdesHelper";

export const TEMPLATES =  [
    {title: 'Promotion', value: 'promotion', settings: {bgColor: null, color: null, borderStyle: 'stndt-blk--dashed', borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Key Features', value: 'key_features', settings: {bgColor: null, color: null, borderStyle: 'stndt-blk--line', borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Example Box', value: 'example_box', settings: {bgColor: '#F4F4F4', color: '#222222', borderStyle: null, borderColor: null, icon: null, smallPadding: true, greenLinks: null, withImage: null}},
    {title: 'Blue BG', value: 'blue_bg', settings: {bgColor: '#D5E4E8', color: null, borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Pink BG', value: 'pink_bg', settings: {bgColor: '#FBE4D4', color: null, borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'BOXES: Wrong', value: 'boxes_wrong', settings: {bgColor: '#FFC8C8', color: null, borderStyle: null, borderColor: null, icon: 'stndt-blk--wrong', smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'BOXES: Right', value: 'boxes_right', settings: {bgColor: '#BDDFC4', color: null, borderStyle: null, borderColor: null, icon: 'stndt-blk--right', smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'BOXES: Pro Tip', value: 'boxes_prop-tip', settings: {bgColor: '#BDD6DF', color: null, borderStyle: null, borderColor: null, icon: 'stndt-blk--prop-tip', smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'BOXES: Warning', value: 'boxes_warning', settings: {bgColor: '#FCD7BF', color: null, borderStyle: null, borderColor: null, icon: 'stndt-blk--warning', smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'BOXES: Example', value: 'boxes_example', settings: {bgColor: '#FCEABF', color: null, borderStyle: null, borderColor: null, icon: 'stndt-blk--example', smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'BOXES: Summary', value: 'boxes_summary', settings: {bgColor: '#D5D5D5', color: null, borderStyle: null, borderColor: null, icon: 'stndt-blk--summary', smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Primary Promo: Light', value: 'primary_promo_l', settings: {bgColor: '#EBEDEE', color: null, borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Primary Promo: Dark - Orange', value: 'primary_promo_do', settings: {bgColor: '#1A4059', color: '#FFFFFF', borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Primary Promo: Dark - Green', value: 'primary_promo_dg', settings: {bgColor: '#0A1E2E', color: '#FFFFFF', borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: true}},
    {title: 'Multiple/helpful resources: Light', value: 'helpful_resources_l', settings: {bgColor: '#EBEDEE', color: null, borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Multiple/helpful resources: Darker', value: 'helpful_resources_d', settings: {bgColor: '#D5E4E8', color: null, borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: null}},
    {title: 'Individual Product Feature', value: 'product_feature', settings: {bgColor: '#EBEBEB', color: null, borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: true}},
    {title: 'Individual Product Feature (Logo)', value: 'product_feature_logo', settings: {bgColor: '#EBEBEB', color: null, borderStyle: null, borderColor: null, icon: null, smallPadding: null, greenLinks: null, withImage: true, logo: true}}
];

export function getSettings(templateCode) {
    const template = TEMPLATES.find(t => t.value === templateCode);
    return template ? template.settings : {};
}

export function getDefaultContent(templateCode, finalHtml, withImage) {
    switch (templateCode) {
        case 'promotion':
            return <p style={{textAlign: 'center'}} data-mce-style="text-align: center;"><span style={{fontSize: '20px'}} data-mce-style="font-size: 20px;"><strong>PROMOTION</strong> - Enjoy <span style={{textDecoration: 'underline'}} data-mce-style="text-decoration: underline;">20% OFF</span> Full Access to CareerHunter for a limited time only! </span></p>;
        case 'key_features':
            return <Fragment>
                <p><span style={{fontSize: '20px'}} data-mce-style="font-size: 20px;"><strong>Key benefits and features of CareerHunter</strong></span></p>
                <ul className={'list--tick'}>
                    <li><strong>Get accurate career matches:</strong> Discover careers that fully suit and interest you by letting CareerHunter’s unique algorithm do all the work and match you to popular and emerging careers.</li>
                    <li><strong>Reveal your strengths and weaknesses:</strong> Learn about your true talents, abilities and weaknesses, and see how they can affect your career choice.</li>
                </ul>
            </Fragment>;
        case 'example_box':
            return <Fragment>
                <p>Dear [manager’s name],</p>
                <p>Thank you for meeting with me earlier to discuss the possibility of my promotion to [position name].</p>
                <p>As discussed, I feel that I’m ready to take on this challenge and bring even more desired results for the business. As per the attached presentation, I have faced plenty of obstacles this year and overcome them, helping me develop the skills necessary for a managerial role.</p>
                <p>Please let me know if there’s anything else that you need from my behalf.</p>
                <p>I look forward to hearing about your final decision.</p>
                <p>Regards,</p><p>[Your name]</p>
            </Fragment>;
        case 'blue_bg':
            return <p><span style={{fontSize: '20px'}} data-mce-style="font-size: 20px;"><strong>Unsure if this career suits you?</strong> Take our much-loved career test which will align your interests and abilities over at <a data-mce-href="https://www.careerhunter.io/" href="https://www.careerhunter.io/" target="_blank" rel={'nofollow'}><strong>CareerHunter</strong></a>.</span></p>;
        case 'pink_bg':
            return <p><span style={{fontSize: '20px'}} data-mce-style="font-size: 20px;"><strong>Prefer expert assistance?</strong> Let one of our professional writers at CareerAddict help you create a job winning CV. <a data-mce-href="/cv-writing" href="/cv-writing" target="_blank"><strong>See CV Writing options.</strong></a></span></p>;
        case 'boxes_wrong':
            return getBoxTemplate('WRONG');
        case 'boxes_right':
            return getBoxTemplate('RIGHT');
        case 'boxes_prop-tip':
            return getBoxTemplate('PRO TIP');
        case 'boxes_warning':
            return getBoxTemplate('WARNING');
        case 'boxes_example':
            return getBoxTemplate('EXAMPLE');
        case 'boxes_summary':
            return getBoxTemplate('SUMMARY');
        case 'primary_promo_l':
        case 'primary_promo_do':
        case 'primary_promo_dg':
            return getPromo(templateCode, finalHtml, withImage);
        case 'product_feature':
            return productFeature(finalHtml);
        case 'product_feature_logo':
            return productFeatureLogo(finalHtml);
        case 'helpful_resources_l':
            return <Fragment>
                <p><span style={{fontSize: '30px'}} data-mce-style="font-size: 30px;"><strong>Require expert help?</strong></span></p>
                <p><span style={{fontSize: '20px'}} data-mce-style="font-size: 20px;"><strong>Utilise the expertise of a coach or mentor and take your networking skills to the next level.</strong></span></p>
                <p>Check out the following highly recommended options:</p>
                <ul>
                    <li><a data-mce-href="https://www.careerhunter.io/" href="https://www.careerhunter.io/" target="_blank" rel={'nofollow'}>CareerHunter</a></li>
                </ul>
            </Fragment>;
        case 'helpful_resources_d':
            return <Fragment>
                <p><span style={{fontSize: '30px'}} data-mce-style="font-size: 30px;"><strong>Looking to join this rich list?</strong></span></p>
                <p><span style={{fontSize: '20px'}} data-mce-style="font-size: 20px;"><strong>Receive direct, expert advice from successful entrepreneurs and give yourself the best chance of elevating your career and becoming the next rich kid.</strong></span></p>
                <p>Check out the following highly recommended learning materials to get you started:</p>
                <ul>
                    <li><a data-mce-href="https://www.careerhunter.io/" href="https://www.careerhunter.io/" target="_blank" rel={'nofollow'}>CareerHunter</a> (online testing)</li>
                </ul>
            </Fragment>;
        default:
            return null;
    }
}

function getBoxTemplate(header) {
    return(
        <React.Fragment>
            <p>{header}</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodsassdss temp incididunt ut labore et dolore magna aliqua. Ut enim ad minim vasasdsss quiss nostrud exercitation ullamco laboris nisi ut aliquip ex.</p>
        </React.Fragment>
    )
}

function getPromo(type, finalHtml, withImage) {
    let header = 'Need further CV assistance?';
    let subheader = <Fragment>CareerAddict’s expert CV and Resume writers can help you to land your next job through our <a data-mce-href="/cv-writing" href="/cv-writing" target="_blank">Professional CV Writing Service</a>.</Fragment>;
    let text = 'Simply browse our packages, customise your additional documents, and proceed to speaking directly via email to your designated writer. It’s that simple to get started.';
    let btn = getButtonCode(finalHtml, 'Explore CV packages', '/cv-writing', '', 'true', 'orange', 'normal', '', false, false);
    let btnLebel = 'Starting from just $99.95';
    if (type === 'primary_promo_dg') {
        header = 'Need further career advice?';
        subheader = <Fragment>We have worked with experts to develop a scientific and accurate career matching platform in <a data-mce-href="https://www.careerhunter.io/" href="https://www.careerhunter.io/" target="_blank" rel={'nofollow'}>CareerHunter</a>.</Fragment>;
        text = 'Start by taking our free career interests test to understand where your passion truly lies, then move on to the remaining tests to find careers which match all your attributes.';
        btn = getButtonCode(finalHtml,'Take our test!', 'https://www.careerhunter.io/tests/interests/take', 'nofollow', 'true', 'green', 'normal', '', false, false);
        btnLebel = 'Approximately 15 minutes';
    }
    const ColumnTag = finalHtml ? 'div' : 'section';
    const column_attrs = finalHtml ? {className: 'cell medium-4'} : null;
    return(
        <Fragment>
            <p className={'promo-header'}>{header}</p>
            <p><span style={{fontSize: '20px'}} data-mce-style="font-size: 20px;"><strong>{subheader}</strong></span></p>
            <p>{text}</p>
            <ColumnTag className={`${ finalHtml ? 'grid-x' : 'article-grid-container' } article-grid-${withImage ? 6 : 4}`}>
                <div {...column_attrs}><p key={'column1'}>{btn}</p></div>
                <div {...column_attrs}><p key={'column2'}><span style={{color: 'rgb(140, 156, 171)', fontSize: '17px'}} data-mce-style="color: #8C9CAB;font-size: 17px;">{btnLebel}</span></p></div>
                {!withImage && <div {...column_attrs}><p key={'column3'}/></div>}
            </ColumnTag>
        </Fragment>
    )
}

function productFeature(finalHtml) {
    const ColumnTag = finalHtml ? 'div' : 'section';
    const column_attrs = finalHtml ? {className: 'cell medium-6'} : null;
    return(
        <Fragment>
            <p className={'promo-header'}>Need extra motivation?</p>
            <p>Go on a journey with Tony Robbins to accelerate your path to financial freedom with his highly acclaimed <strong>‘Unshakable’</strong> eGuide.</p>
            <ColumnTag className={`${ finalHtml ? 'grid-x' : 'article-grid-container' } article-grid-6`}>
                <div {...column_attrs}><p key={'column1'}><span style={{color: 'rgb(121, 146, 157)'}} className={'promo-best-seller'} data-mce-style="color: #79929D">#1 Best Seller in eBooks</span></p></div>
                <div {...column_attrs}><p key={'column2'}></p></div>
            </ColumnTag>
            <p>{getButtonCode(finalHtml, 'Get the book on Amazon', 'https://careerhunter.io/tests/interests/take', 'nofollow', 'true', 'orange', 'normal', '', true, false)}</p>
        </Fragment>
    )
}

function productFeatureLogo(finalHtml) {
    return(
        <Fragment>
            <p className={'promo-header'}>Get interview preparation help</p>
            <p>We offer customised one-to-one interview preparation and salary negotiation support that gets your best job offer.</p>
            <p>{getButtonCode(finalHtml, 'Learn more', 'https://www.careerhigher.co/services/interview-preparation/?utm_source=careeraddict&utm_medium=article_banner&utm_campaign=ca_pilot', 'nofollow', 'true', 'orange', 'normal', 'arrow-white-right', false, false)}</p>
        </Fragment>
    )
}

function getButtonCode(finalHtml) {
    if (finalHtml) {
        const [finalHtml, name, url, rel, tragetBlank, style, size, icon, paid_link, small_font] = arguments;
        let options = {href: url, className: `button button-${style || 'orange'} button--rounded${paid_link ? ' button--with-paid-link' : ''}`};
        if (!!rel) { options['rel'] = rel; }
        if (tragetBlank === 'true') { options['target'] = '_blank'; }
        let link = <a {...options}>
            {name}
            {!!icon ? <span className={`smal-icon`} /> : null}
        </a>;
        if (paid_link) {
            return <div className={'button-container-paid-link'}>
                {link}
                <span className={'paid-link-tag'}>Paid link</span>
            </div>
        } else {
            return link;
        }
    }
    else {
        const [finalHtml, ...params] = arguments;
        return getButtonShortcode(...params);
    }
}