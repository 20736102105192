import React from "react";
import SwitchButton from '../../components/customFields/switchButton';
import CommentsApi from '../api/comment';

class Settings extends React.Component {

    state = {
        comment: this.props.comment
    };

    onChangeNotifyReplies = (name, value) => {
        let _this = this;
        let comment = this.state.comment;
        comment[name] = value;
        this.setState({comment: comment}, () => {
            CommentsApi.update(_this.props.encrypted_key, comment)
        });
    };



    render(){
        return (
            <React.Fragment>
                <div className="white-bg-block">
                    <div className="grid-x">
                        <div className="small-12 cell">
                            <h4 className="inline">Notify Comment Replies</h4>
                            <SwitchButton className="comment-setting-switch" name="notify_replies"
                                          value={this.state.comment.notify_replies}
                                          onChange={this.onChangeNotifyReplies}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Settings;