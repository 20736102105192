import React from "react";

class SwitchButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: this.props.color || "#ED8A47",
            className: ` ${this.props.className}` || "",
            valueOn: this.props.valueOn || true,
            valueOff: this.props.valueOff || false,
            id: "switch_" + Math.random().toString(36).substring(7)
        }
    }

    handleChange = (e) => {
        let value = this.props.value === this.state.valueOn ? this.state.valueOff : this.state.valueOn;
        this.props.onChange(e.target.name, value);
    };

    hexToRgbA = (opacity) => {
        let hex = this.state.color;
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${opacity})`;
        }
        throw new Error('Bad Hex');
    };

    render() {

        return (
            <React.Fragment>
                <style>
                    {`.switch-toggle-wrapper input:checked ~ .switch-paddle.${this.props.name} {background-color: ${this.hexToRgbA(0.2)}}`}
                    {`.switch-toggle-wrapper input:checked ~ .switch-paddle.${this.props.name}:after {background-color:${this.state.color} !important;}`}
                </style>
                <div className="switch-toggle-wrapper text-right" >
                    <fieldset className="switch round">
                        <input className="switch-input" name={this.props.name} id={this.state.id} onChange={this.handleChange} type="checkbox"
                               checked={(this.props.value === this.state.valueOn ? 'checked' : '')} />
                        <label className={`switch-paddle ${this.props.name}`} htmlFor={this.state.id}>

                        </label>
                    </fieldset>
                </div>
            </React.Fragment>
        );
    }
}
export default SwitchButton;