import { SET_PROFILE_AUTHOR_TAB, SET_INITIAL_AUTHOR_TAB, SET_SETTINGS_AUTHOR_TAB, SET_ARTICLES_AUTHOR_TAB, SET_SUBMIT_AUTHOR_TAB} from '../actions/actionTypes';
import initialState from './initialState';

export default function account(state = initialState.account, action) {
    switch (action.type) {
        case SET_INITIAL_AUTHOR_TAB:
            return {currentTab: 'profile'};
        case SET_PROFILE_AUTHOR_TAB:
            return {currentTab: 'profile'};
        case SET_SETTINGS_AUTHOR_TAB:
            return {currentTab: 'settings'};
        case SET_ARTICLES_AUTHOR_TAB:
            return {currentTab: 'articles'};
        case SET_SUBMIT_AUTHOR_TAB:
            return {currentTab: 'submit'};
        default:
            return state;
    }
}