import React, { Component } from 'react';
import SimpleModal from "../../modals/simpleModal";

export default class ConfirmDeleteModal extends SimpleModal {

    open = (articleId) => {
        this.setState({articleId: articleId});
        this.popup.current.open();
    };

    close= (onCloseAction) => {
        if (typeof onCloseAction !== 'undefined') {onCloseAction(this.state.articleId);}
        this.popup.current.close();
    };

    renderButton = () => {
        if (!this.props.button) { return null; }

        return (<div className="buttons-area text-center" style={{marginTop:'5px'}}>
            <button onClick={() => this.close(this.props.onButtonClick) } className="button-orange">Confirm</button>
            {' '}
            <button onClick={() => this.close() } className="button-grey">Cancel</button>
        </div>)
    };

}