import React, {Fragment} from "react";

import {ErrorMessage} from "react-fields";
import CategoriesSettings from "../Categories/CategoriesSettings";

export default function SettingsHandler(props) {

    return (
        <Fragment>
            {/*<div className="white-bg-block">*/}
                {/*<CategoriesSettings categories={props.categories} selectCategory={props.selectCategory}/>*/}

                {/*<ErrorMessage message={props.form.errors.categories}/>*/}
                {/*<br clear="all" />*/}
                {/*<div className="medium-text-right text-center">*/}
                    {/*<button onClick={(e) => {e.preventDefault(); props.submitForm()}} className="button-orange">Save Settings</button>*/}
                {/*</div>*/}
            {/*</div>*/}
            <div>
                <h3 style={{marginTop: "40px"}}>Your Email Address</h3>
                <div className="white-bg-block" style={{padding: "15px", margin: "20px 0"}}>
                    <div className="grid-x">
                        <div className="small-12 cell">
                            <p style={{margin: 0}}>{props.form.email}</p>
                        </div>
                    </div>
                </div>
                <a className='secondary-link unsubscribe-link' onClick={props.unsubscribe}>Unsubscribe from all emails</a>
            </div>
        </Fragment>
    )
}
