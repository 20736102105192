import React, {Component, Fragment} from 'react';
import {SelectField, TextField, InputTypeField, CheckboxField, ErrorMessage} from "react-fields";
import FieldWrapper from '../../../partials/adminFieldWrapper';
import {TEMPLATES, getSettings, getDefaultContent} from './tinymceColoredBoxModalTemplates';

import ColorSelectDropDown from '../editorHelpers/colorSelectDropDown';

const BORDER_STYLES =  [
    {title: 'Line', value: 'stndt-blk--line'},
    {title: 'Dashed', value: 'stndt-blk--dashed'}
];

const ICONS =  [
    {title: 'Wrong', value: 'stndt-blk--wrong'},
    {title: 'Right', value: 'stndt-blk--right'},
    {title: 'Pro Tip', value: 'stndt-blk--prop-tip'},
    {title: 'Warning', value: 'stndt-blk--warning'},
    {title: 'Example', value: 'stndt-blk--example'},
    {title: 'Summary', value: 'stndt-blk--summary'}
];

const BG_COLORS = [
    {title: 'Wrong', value: '#FFC8C8'},
    {title: 'Right', value: '#BDDFC4'},
    {title: 'Pro Tip', value: '#BDD6DF'},
    {title: 'Warning', value: '#FCD7BF'},
    {title: 'Example', value: '#FCEABF'},
    {title: 'Summary', value: '#D5D5D5'},
    {title: 'Light Gray', value: '#F4F4F4'},
    {title: 'Gray 1', value: '#EBEBEB'},
    {title: 'Gray 2', value: '#EBEDEE'},
    {title: 'Light Blue', value: '#D5E4E8'},
    {title: 'Blue', value: '#1A4059'},
    {title: 'Dark Blue', value: '#0A1E2E'},
    {title: 'Pink', value: '#FBE4D4'}
];

const FONT_COLORS = [
    {title: 'Dark Blue (Default)', value: '', displayColor: '#083A50'},
    {title: 'Dark Gray', value: '#222222'},
    {title: 'White', value: '#FFFFFF'}
];

const BORDER_COLORS = [
    {title: 'Dark Gray', value: '#222222'},
    {title: 'White', value: '#FFFFFF'}
];

class ColoredBoxModal extends Component {

    state = {
        id: this.props.id || Math.random().toString(36).substring(7),
        box: { errors: []},
        isNew: true,
        isOpened: false
    };

    handleFieldChange = (e) => {
        let box = this.state.box;
        const fieldName = e.target.name;
        box[fieldName] = ['greenLinks', 'templateContent', 'smallPadding', 'withImage', 'withBgImage', 'use_aside_tag', 'logo'].includes(fieldName) ? e.target.checked : e.target.value;
        if (fieldName === 'bgColor' || fieldName === 'borderStyle') {
            box.errors['base'] = '';
        }
        if (fieldName === 'templateCode') {
            box = {...box, ...(getSettings(e.target.value))}
        }
        if (fieldName === 'logo' && e.target.checked) {box['withBgImage'] = false;}
        if (fieldName === 'withBgImage' && e.target.checked) {box['logo'] = false;}
        this.setState({box: box})
    };

    rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`;

    getValue = (className, list) => {
        const mRes = className.match(list.map(v => v.value).join('|'));
        return mRes ? mRes[0] : null;
    };

    set = (bgColorRGB, colorRGB, borderColorRGB, classList, className, tagName, image) => {
        const bgColor = bgColorRGB ? this.rgb2hex(bgColorRGB) : null;
        const color = colorRGB ? this.rgb2hex(colorRGB) : null;
        let borderColor = borderColorRGB ? this.rgb2hex(borderColorRGB) : null;
        const borderStyle = this.getValue(className, BORDER_STYLES); //classList.contains('stndt-blk--line') ? 'stndt-blk--line' : (classList.contains('stndt-blk--dashed') ? 'stndt-blk--dashed' : null);
        const icon = this.getValue(className, ICONS);
        const smallPadding = classList.contains('stndt-blk--small');
        const greenLinks = classList.contains('green-stndt-blk');
        const withImage = classList.contains('stndt-blk--with-featured-img');
        const withBgImage = withImage && image && image.is_bg;
        const logo = withImage && image && image.is_logo;
        this.setState({box: {...this.state.box, bgColor, color, borderStyle, borderColor, icon, smallPadding, greenLinks, withImage, withBgImage, image, logo, use_aside_tag: (tagName === 'ASIDE')}, isNew: false})
    };

    isValid = () => {
        let box = this.state.box;
        let valid = true;
        if(!box.bgColor && !box.borderStyle) {
            box.errors.base = "Background color or border style should be selected";
            valid = false;
        }
        if(box.withImage && (!box.image || !box.image.original_path)) {
            box.errors.image = "Select or upload image";
            valid = false;
        }
        this.setState({box: box});
        return valid;
    };

    openGallery = (galleryItem) => {
        this.props.openGallery(galleryItem, this.insertMediaItem);
    };

    insertMediaItem = (item, key) => {
        this.setState({box: {...this.state.box, image: {...item, caption: '', source_link: '', source_text: ''}, errors: {...this.state.box.errors, image: ''}}, image_key: key});
    };

    submit = () => {
        if (this.isValid()) {
            let settings = this.state.box;
            if (this.state.box.templateContent && this.state.box.templateCode) {
                settings['defaultContent'] = getDefaultContent(this.state.box.templateCode, this.props.finalHtml, this.state.box.withImage)
            }
            if (settings.withImage && settings.image) {
                settings.image['is_bg'] = settings.withBgImage;
            }
            this.props.onSave(settings);
            this.props.close();
        }
    };

    delete = (keepContent) => {
        this.props.onRemove(keepContent);
        this.props.close();
    };

    render() {
        const box = this.state.box;
        return(
            <div className={'custom-form'}>
                <h1>Add Colored Box</h1>

                {this.state.isNew ?
                    <Fragment>
                        <FieldWrapper label='Select Template' name='templateCode'>
                            <SelectField name={'templateCode'}
                                         onChange={this.handleFieldChange}
                                         placeholder={'Select Template'}
                                         className={'mar-b-10'}
                                         selected={box.templateCode}
                                         options={TEMPLATES} optionsValue='value' optionsLabel='title' />
                        </FieldWrapper>
                        {!!box.templateCode ?
                            <CheckboxField onChange={this.handleFieldChange} name='templateContent'  className="linkTo"
                                           value={box.templateContent} label='Insert Default Content' />
                        : null }
                    </Fragment>
                : null}

                <FieldWrapper label='Background color' name='bgColor'>
                    {/*<InputTypeField name={'bgColor'} type="color" onChange={this.handleFieldChange}*/}
                                {/*placeholder={'Background Color'} value={box.bgColor || "#FFFFFF"}*/}
                                {/*className="inline" />*/}
                    {/*<TextField onChange={this.handleFieldChange} name='bgColor' className="inline mar-l-10" value={box.bgColor || ''}/>*/}

                    {/*<ColorDropDown name={'bgColor'} selected={box.bgColor} onChange={this.handleFieldChange} options={BG_COLORS} placeholder={' '} />*/}
                    <ColorSelectDropDown name={'bgColor'} selected={box.bgColor || ''} onSelect={this.handleFieldChange} options={BG_COLORS} placeholder={'Select Background Color'} />
                </FieldWrapper>
                <FieldWrapper label='Font color' name='color'>
                    {/*<InputTypeField name={'color'} type="color" onChange={this.handleFieldChange}*/}
                                    {/*placeholder={'Font Color'} value={box.color || "#083A50"}*/}
                                    {/*className="inline" />*/}
                    {/*<TextField onChange={this.handleFieldChange} name='color' className="inline mar-l-10" value={box.color || ''}/>*/}

                    <ColorSelectDropDown name={'color'} selected={box.color || ''} onSelect={this.handleFieldChange} options={FONT_COLORS} />
                </FieldWrapper>
                <FieldWrapper label='Border style' name='borderStyle'>
                    <SelectField name={'borderStyle'}
                                 onChange={this.handleFieldChange}
                                 placeholder={'Select border style'}
                                 className={'mar-b-10'}
                                 selected={box.borderStyle}
                                 options={BORDER_STYLES} optionsValue='value' optionsLabel='title'  />
                </FieldWrapper>
                {/*<FieldWrapper label='Border color' name='borderColor'>*/}
                    {/*<InputTypeField name={'borderColor'} type="color" onChange={this.handleFieldChange}*/}
                               {/*placeholder={'Border Color'} value={box.borderColor || "#ED8A47"}*/}
                               {/*className="inline"  errorMessage={box.errors.borderColor} />*/}
                    {/*<TextField onChange={this.handleFieldChange} name='borderColor' className="inline mar-l-10" value={box.borderColor || ''}/>*/}
                {/*</FieldWrapper>*/}
                <FieldWrapper label='Icon' name='icon'>
                    <SelectField name={'icon'}
                                 onChange={this.handleFieldChange}
                                 placeholder={'Select icon'}
                                 className={'mar-b-10'}
                                 selected={box.icon}
                                 options={ICONS} optionsValue='value' optionsLabel='title' />
                </FieldWrapper>
                <CheckboxField onChange={this.handleFieldChange} name='smallPadding'  className="linkTo"
                               value={box.smallPadding} label='Block with Small Padding' />
                <CheckboxField onChange={this.handleFieldChange} name='greenLinks'  className="linkTo"
                               value={box.greenLinks} label='Use green color for links' />

                <div className="field with-tooltip">
                    <CheckboxField onChange={this.handleFieldChange} name='use_aside_tag'  className="linkTo"
                                   value={box.use_aside_tag} label='Use Aside Tag' />
                    <div className="tooltip-trigger">?
                        <span className="tooltip top">To be selected when this component is NOT required to understand the content. So in cases of promotions/advertising, comments, pullquotes, glossary, footnotes that’s tangentially related to the page or content.</span>
                    </div>
                </div>

                <CheckboxField onChange={this.handleFieldChange} name='withImage'  className="linkTo"
                               value={box.withImage} label='With featured image (product)' />

                { box.withImage ?
                    <Fragment>
                        <CheckboxField onChange={this.handleFieldChange} name='withBgImage'  className="linkTo"
                                       value={box.withBgImage} label='Background image' />
                        <CheckboxField onChange={this.handleFieldChange} name='logo'  className="linkTo"
                                       value={box.logo} label='Logo' />

                        <FieldWrapper label='Featured Image' name='image'>
                            {box.image && box.image.original_path &&
                            <img src={box.image.original_path} style={{maxWidth: '150px'}} className={'mar-b-10'}/>}
                            <button className="button-orange mar-b-10" onClick={() => this.openGallery(box.image)}>Upload/Select
                                Image
                            </button>
                            <ErrorMessage message={box.errors.image} />
                        </FieldWrapper>
                    </Fragment>: null
                }

                <ErrorMessage message={box.errors.base} />
                <button className="button-orange" onClick={this.submit}>Insert into Post</button>
                {this.state.isNew ? null : <Fragment>
                    <button className="button-orange" onClick={() => this.delete(false)}>Remove from Post</button>
                    <button className="button-orange" onClick={() => this.delete(true)}>Remove Block Formatting</button>
                </Fragment>}
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </div>
        )
    }

}
export default ColoredBoxModal;