import {actions} from 'react-fields';
import cf from "../common";

class GalleryApi {

    static search(params){
        let q ='';
        if (params)
            q = '?' + Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
        const url = '/api/gallery_files' + q;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static getItemInfo(itemId, onGet) {
        fetch(actions.getData(`/api/gallery_files/${itemId}`))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                onGet(data.item);
            });
    }

    static upload(formData, onSuccess, onError){
        const url = '/api/gallery_files/upload';
        let token = document.querySelector("meta[name='csrf-token']").content;

        const options = {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': token } };

        fetch(url, options)
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async(response) => {
            onSuccess(response);
        }).catch(async error => {
            try {
                await error.json().then((value) => {
                    onError(value);
                });
            } catch (e) {
                console.log(e);
                onError("Something went wrong, please try again");
            }
        });
    }

    static async update(item, onSuccess, onError) {
        await this.updateFile(item).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) { onError(value); }).catch(err => {
                console.error('Server Response Error parsing failure:', error);
                console.info(err);
                console.log('after error:');
                onError({base: error.status === 404 ? 'Item was removed from gallery' : `Server response: ${error.status}`, responseStatus: error.status});
            });
        });
    }

    static delete(fileId, onSuccess, onError) {
        const url = `/api/gallery_files/${fileId}`;
        let data = {};
        fetch(actions.deleteRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            error.json().then(function (value) {
                onError(value);
            }).catch(err => {
                console.error('Server Response Error parsing failure:', error);
                console.info(err);
                console.log('after error:');
                onError({base: `Server response: ${error.status}`, responseStatus: error.status});
            });
        });
    }

    static updateFile(item) {
        const url = '/api/gallery_files';
        let data = {};
        ['name', 'alt', 'source_link', 'source_text'].map((k) => {
            data[k] = item[k];
        });
        return fetch(actions.createOrUpdateRequest(url, {file: data}, item.id ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            })
    }

    static validate(item, fileMode = null) {
        let errors = {};

        ['name', 'alt'].map((name) => {
            if (!item[name])
                errors[cf.camelize(name)] = `${cf.capitalize(name)} can't be blank`;
        });

        if(fileMode === 'insert') {
            ['display_text'].map((name) => {
                if (!item[name])
                    errors[cf.camelize(name)] = `${cf.capitalize(name)} can't be blank`;
            });
        }



        if (item['source_link']) {
            if (!item['source_text']) {errors['sourceText'] = `Source Text can't be blank`;}
            if (!cf.is_valid_url(item['source_link'])) { errors['sourceLink'] = 'URL is not valid'; }
        }

        if (item['link_type'] && item['link_type'] === 'url') {
            if (!item['link_to']) {
                errors['linkTo'] = `Link To URL can't be blank`;
            } else if (!cf.is_valid_url(item['link_to'])) {
                errors['linkTo'] = 'Link To URL is not valid';
            }
        }

        return errors;
    }

}
export default GalleryApi;