import {actions} from 'react-fields'

class PressApi {

    static getList(onLoad, type, page = null) {
        let url = `/api/${type}`;

        if (page){
            url = `${url}?page=${page}`
        }
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response.articles, response.meta);
        }).catch(error => {
            console.log(error);
        })
    }



}

export default PressApi;