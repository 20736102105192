import React, {Component, Fragment} from "react";
import {actions} from "react-fields";
import Carousel from './carousel';

export default class TeamTabs extends Component {

    state = {
        isLoaded: false,
        currentTab: 'experts',
        collection: {}
    };

    componentDidMount() {
        let _this = this;
        fetch(actions.getData('/api/carousels/team'))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            _this.setState({collection: response, isLoaded: true})
        }).catch(error => {
            console.log(error);
        })
    }

    setTab = (tab) => {
        if (tab === this.state.currentTab) {return}
        this.setState({currentTab: tab});
    };

    renderTab = () => {
        // fix for scrolling to first slide when switch tab (slickGoTo doesn't scroll when there are different amount of slides in sliders...)
        let tab = this.state.currentTab;
        switch(tab) {
            case 'experts':
                return <Carousel key={'expertsCarousel'} collection={this.state.collection['experts']} />;
            default:
                return <Carousel key={'teamCarousel'} collection={this.state.collection['team']} />;
        }
    };

    render() {
        if (!this.state.isLoaded) {
            return null;
        }

        return(
            <Fragment>
                <ul className="about__team-tabs">
                    <li className={`about__team-tab${this.state.currentTab === 'experts' ? ' is-active' : ''}`} onClick={() => {this.setTab('experts')}}>Our experts</li>
                    <li className={`about__team-tab${this.state.currentTab === 'team' ? ' is-active' : ''}`} onClick={() => {this.setTab('team')}}>Our team</li>
                </ul>

                {this.renderTab()}
            </Fragment>
        )
    }

}