import React, {useEffect, useState} from 'react';
import {actions} from "react-fields";
import Carousel from '../carousels/articles';

const CategoryArticlesCarousel = (props) => {

    const [articles, setArticles] = useState(props.articles);

    useEffect(
        () => {
            const paramsStr = actions.paramsToURI({ids: articles.reduce((a, o) => (a.push(o.id), a), [])});
            fetch(actions.getData(`/api/comments/for_articles?${paramsStr}`))
                .then(actions.status)
                .then(actions.json)
                .then(function(data) {
                    console.log(data);
                    let updatedCollection = articles.map((a) => {return {...a, comments_count: data[a.id] || 0}});
                    setArticles(updatedCollection);
                });
        }, []
    );

    return (
        <Carousel articles={articles} careerPath={props.careerPath} />
    );
};

export default CategoryArticlesCarousel;