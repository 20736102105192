import React, {Component} from "react";
import Slider from "react-slick";
import SliderArrow from "../base/sliderArrow";
import {actions} from "react-fields";

const DEFAULT_SLIDER_SETTINGS = {
    dots: true,
    dotsClass: 'slider-bar',
    infinite: false,
    arrows: true,
    speed: 600,
    cssEase:  'ease-out',
    variableWidth: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: 'about-us__slider about-us__slider--history',
    nextArrow: <SliderArrow direction="slider-arrow slider-arrow--next"/>,
    prevArrow: <SliderArrow direction="slider-arrow slider-arrow--prev"/>,
    responsive: [
        {   breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows:  true,
                variableWidth: false,
                dots: true
            }
        },
        {
            breakpoint: 1365,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: false,
                dots: true
            }
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: true,
                variableWidth: false,
                dots: true
            }
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                variableWidth: false,
                dots: true
            }
        }
    ]
};

export default class HistoryCarousel extends Component {

    state = {
        settings: this.props.settings ? {...DEFAULT_SLIDER_SETTINGS, ...this.props.settings} : DEFAULT_SLIDER_SETTINGS,
        keyPrefix: Math.random().toString(36).substring(7),
        active: null,
        isLoaded: false,
        collection: []
    };

    componentDidMount() {
        let _this = this;
        fetch(actions.getData('/api/carousels/milestones'))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            _this.setState({collection: response, isLoaded: true})
        }).catch(error => {
            console.log(error);
        })
    }

    handleClick = (index) => {
        this.setState({ active: index });
    };

    renderItem = (item, index) => {
        return (<div key={`${this.state.keyPrefix}${index}`} className={'history__wrapper' + (this.state.active === index ? ' fade-in' : '')} onClick={() => this.handleClick(index)}>
            <div className='history__block'>
                <p className="history__date">{item.date_formatted}</p>
                <div className='history__line'></div>
                <div className="history__pic">
                    <img src={item.image.original} alt={item.name} />
                </div>
                <p className="history__desc">{item.subheader}</p>
                <p className="history__title">{item.name}</p>
                <div className="show-only-on-hover">
                    <h2 className="history__hover-desc">{item.description}</h2>
                </div>
            </div>
        </div>)
    };

    render() {
        if (!this.state.isLoaded) {
            return null;
        }
        return(
            <div className='grid-x'>
                <Slider {...this.state.settings}>
                    {this.state.collection.map((item, index) => this.renderItem(item, index))}
                </Slider>
            </div>
        )
    }

}