import React from 'react';
import PropTypes from "prop-types";

export const AdminFieldWrapper = props => {
return (<div className="field" >
        <label className="label" htmlFor={props.name}>{props.label}{props.required ? <abbr title="required">*</abbr> : null}</label>
        <div className="input">
            {props.children}
        </div>
    </div>);
};

AdminFieldWrapper.propTypes = { label: PropTypes.string, name: PropTypes.string, required: PropTypes.bool };

export default AdminFieldWrapper;