import React from "react";

export default function Chevron(props) {

    return (
        <svg viewBox="0 0 32 32"
             className={props.className}
             height={20}
             width={22}>
            <path  fill={props.fill} d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z"/>
        </svg>

    );
}