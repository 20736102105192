import React, { Component } from 'react';

class PaginationButtons extends Component {

    goToPrev = () => {
        if (this.props.currentPage > 1)
            this.goToPage(this.props.currentPage-1);
    };
    goToNext = () => {
        if (this.props.currentPage < this.props.totalPages)
            this.goToPage(this.props.currentPage+1);
    };

    goToPage = (page) => {
        this.props.onChangePage(page);
    };

    render() {
        if (this.props.totalPages < 2)
            return null;

        let pages = [];
        let paginationButtons = {};
        if (this.props.totalPages <= 3) {
            paginationButtons = {from: 1, to: this.props.totalPages}
        } else if (this.props.currentPage <= 2) {
            paginationButtons = {from: 1, to: 3}
        } else if ((this.props.currentPage + 1) <= this.props.totalPages) {
            paginationButtons = {from: (this.props.currentPage - 1), to: (this.props.currentPage + 1)}
        } else {
            paginationButtons = {from: (this.props.totalPages - 3), to: this.props.totalPages}
        }

        for (let i = paginationButtons.from; i <= paginationButtons.to; i++) {
            if (i === this.props.currentPage)
                pages.push(<li key={'paginationPrev'+i} className='current'><span className="show-for-sr">You're on page</span> {i}</li>);
            else
                pages.push(<li key={'paginationPrev'+i}><a onClick={() => this.goToPage(i)} aria-label={"Page "+i}>{i}</a></li>);

            pages.push(' ');
        }

        return (<nav aria-label="Pagination">
            <ul className="pagination text-center">
                <li key='paginationPrev' className="pagination-previous"><a className={this.props.currentPage === 1 ? 'disabled' : ''} onClick={this.goToPrev} aria-label="Prev page"/></li>
                {' '}
                {pages}
                <li key='paginationNext' className="pagination-next"><a className={this.props.currentPage === this.props.totalPages? 'disabled' : ''} onClick={this.goToNext} aria-label="Next page"/></li>
            </ul>
        </nav>)
    }
}

export default PaginationButtons;