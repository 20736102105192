import React from "react";
import articlesApi from "../../admin_components/api/articlesApi";
import ArticlesList from '../articlesList/index';
import {actions} from "react-fields";
import PaginationMeta from '../paginationMeta';
import {updateMeta} from "../utils";

class Index extends ArticlesList {

    textInput = React.createRef();

    componentDidMount() {
        // this.textInput.current.focus();
        const curPage = !!this.props.page ? parseInt(this.props.page) : 1;
        if (!this.props.preloaded) { this.loadArticles(curPage); }
        history.replaceState({page: curPage, q: this.state.q}, '', document.location.href);
        window.onpopstate = (event) => {
            this.setState({isLoading: true, q: event.state.q}, () => { this.loadArticles((event.state.page || 1), true) });
        };
    }

    loadArticles = (page, skipUpdateHistory) => {
        let _this = this;
        const url = this.props.url || '/api/articles/list';
        const urlParams = this.prepareOptionsSearch(page);
        const params = Object.assign({}, this.props.params, urlParams);
        const updateHistory = !!this.state.rendered && !skipUpdateHistory;
        articlesApi.search(params).then((response) => {
            _this.setState({rendered: true, isLoading: false, collection: response}, () => { _this.scrollToTopOfList(); });
            updateMeta(`${this.state.q} results on CareerAddict`, `Browse advice and tips associated with the search term ‘${this.state.q}’ on CareerAddict.`);
            PaginationMeta.update(urlParams, response.count, response.total, 14, updateHistory);
        });
    };

    prepareOptionsSearch = (page) => {
        let params = page === 1 ? {} : {page: page};
        if (this.state.q) {
            params['q'] = this.state.q;
        }
        return params;
    };


    handleKeyWordChange = (e) => {
        this.setState({qTerm: e.target.value})
    };

    onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
           this.submitSearch()
        }
    };

    submitSearch = () => {
        this.setState({isLoading: true, q: this.state.qTerm}, () => { this.loadArticles(1) });
    };

    renderPreList = () => {
        return (<React.Fragment>
        <div className="article-search">
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="small-12 text-center cell">
                        <nav className="search">
                            <ul>
                                <li>
                                    <div className="input-group search">
                                        <div className="input-group-field">
                                            <input     name="qTerm"
                                                       autoComplete="off"
                                                       value={this.state.qTerm}
                                                       ref={this.textInput}
                                                       onKeyPress={this.onPressEnter}
                                                       placeholder="Search"
                                                       onChange={this.handleKeyWordChange} />
                                        </div>
                                        <span className="input-group-label" onClick={this.submitSearch}>
                                                     <div className="small-icon grey-magnify"/>
                                                    </span>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        {/*Ezoic - top_of_page_search - top_of_page*/}
        <div id="ezoic-pub-ad-placeholder-631"> </div>
        {/*End Ezoic - top_of_page_search - top_of_page*/}
        </React.Fragment>);
    };

}

export default Index;