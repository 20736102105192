import React, { Component, Fragment } from 'react';
import * as galleryActions from "../actions/galleryActions";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {actions} from "react-fields";
import GalleryApi from "../api/galleryApi";

import PaginationButtons from "../partials/paginationButtons";
import DropDownButton from "../../components/dropDownButton";

const FILTER_OPTIONS = [
    {value: 'article_featured_image', label: 'Featured Images'},
    {value: 'article_image', label: 'Body Images'},
    {value: 'backend_image', label: 'Backend Images'},
    {value: 'gallery_file', label: 'Files'}
];

const filterOptions = (mode, itemType) => {
    let options = FILTER_OPTIONS;
    if(itemType && itemType === 'article_image') {
        options = FILTER_OPTIONS.slice(0, 3);
    }
    if(mode === 'admin')
        return options;
    return options.slice(0,2);
};

const ASSIGNED_OPTION = [
    {value: '', label: 'Any Assigned'},
    {value: 'true', label: 'Assigned'},
    {value: 'false', label: 'Not Assigned'}
];

class GalleryIndex extends Component {

    state = {
        itemsList: [],
        currentPage: 1,
        hideFilters: !!this.props.gallery.itemType && this.props.gallery.itemType !== 'article_image',
        filterApplied: null,
        filterOptions: filterOptions(this.props.gallery.mode || this.props.mode, this.props.gallery.itemType),
        assignedFilter: null,
        q: ''
    };

    componentDidMount() {
        console.log(this.props.gallery);
        this.loadGalleryItems(1);
    }

    loadGalleryItems = (page) => {
        let params = {page: page, q: this.state.q || '', mode: this.props.gallery.mode || this.props.mode};
        if (this.state.assignedFilter && this.state.assignedFilter['value']) {
            params['assigned'] = this.state.assignedFilter['value'];
        }

        let filter = this.props.gallery.itemType || null;
        if (!this.state.hideFilters) {
            filter = (this.state.filterApplied ? this.state.filterApplied['value'] : filter);
        }

        if (filter) { params['file_type'] = filter }

        GalleryApi.search(params).then((data) => {
            this.setState({itemsList: data.gallery_items || [], currentPage: page, totalPages: data.total});
        });
    };

    handleKeyWordChange = (e) => {
        this.setState({q: e.target.value});
    };

    onChangeFilter = (filter) => {
        this.setState({filterApplied: filter}, () => {
            this.loadGalleryItems(1);
        });
    };

    onChangeAssigned = (assignedFilter) => {
        this.setState({assignedFilter: assignedFilter}, () => {
            this.loadGalleryItems(1);
        });
    };

    onPressEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            this.loadGalleryItems(1);
        }
    };

    switchToUploadScreen = () => {
        let gallery = this.props.gallery;
        gallery['screen'] = 'upload';
        this.props.actions.changeGallerySettings(gallery);
    };

    handleItemSelect = (itemId) => {
        GalleryApi.getItemInfo(itemId, this.props.actions.setGalleryItem);
    };

    renderItem = (item) => {
        if (this.props.gallery.deletedIds.includes(item.id)) { return <div className='col5-unit cell' key={`galleryItem${item.id}`} />; }

        return(<div className='col5-unit cell' key={`galleryItem${item.id}`} >
            <div className="file-container" onClick={() => this.handleItemSelect(item.id)}>
                {item.type === 'GalleryFile' ? <div className={'library-file'}>
                    <div className={item.icon} />
                    <p>{item.thumbnail_path}</p>
                </div> :
                    <img src={item.thumbnail_path} className={`library-file${item.id === this.props.currentItemId ? ' active' : ''}`} />
                }

                {item.id === this.props.currentItemId ?
                    <div className="selected-img">
                    <div className="icon" />
                    </div>
                    : null}
            </div>
        </div>)
    };

    render() {
        let _this = this;
        return (
            <Fragment>
                <div className='library-area'>
                <div className="grid-x control-area">
                    <div className="large-3 cell flex-center" style={{height:'50px'}}>
                        <div className="text-button-strong primary-header" onClick={this.switchToUploadScreen} style={{marginRight: '20px'}}>Upload</div>
                        <div className="text-button-strong secondary-header">Library</div>
                    </div>
                    <div className="large-9 cell flex-end">
                            <div className="search-input">
                                <div className="admin-icon search-icon" onClick={() => this.loadGalleryItems(1) } />
                                <input type="text" placeholder="Search something" name="q" value={this.state.q}
                                       onKeyPress={this.onPressEnter} onChange={this.handleKeyWordChange} />
                                {this.state.q ? <div className="small-icon grey-cross" onClick={() => this.setState({q: ''}, () => {this.loadGalleryItems(1)}) } /> : null }
                            </div>

                            { this.state.hideFilters ? null :
                                <Fragment>
                                    <DropDownButton options={this.state.filterOptions} optionsLabel='label'
                                                    value={this.state.filterApplied} onChange={this.onChangeFilter}
                                                    name='Filter' icon='filter-arrow' />
                                    {this.props.gallery.mode === 'admin' && !this.props.gallery.itemType ?
                                        <Fragment>
                                            <DropDownButton options={ASSIGNED_OPTION} optionsLabel='label'
                                                            value={this.state.assignedFilter} onChange={this.onChangeAssigned}
                                                            name='Any Assigned' icon='filter-arrow' />
                                        </Fragment>
                                        : null}
                                </Fragment>
                            }
                    </div>
                </div>
                <div className="library">
                    <div className="grid-x">
                        {this.state.itemsList.map(function (item) {
                            return (_this.renderItem(item))
                        })}
                    </div>
                </div>
                </div>
                <PaginationButtons currentPage={this.state.currentPage} totalPages={this.state.totalPages} onChangePage={this.loadGalleryItems} />
            </Fragment>
        )
    }

}
function mapStateToProps(state) {
    return {gallery: state.gallery, currentItemId: state.gallery.item ? state.gallery.item.id : null};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, galleryActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GalleryIndex);