import React from "react";
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "../subscriptionActionHOC";

const SubscriptionForm = SubscriptionActionHOC(props => {
    return (
        <div className={`subscribe input-with-button`}>
            <InputTypeField type='email' name="email" value={props.form.email} errorMessage={props.form.errors.email}
                            onChange={props.setEmail}
                            onKeyPress={props.submitOnEnter}
                            placeholder="Your email" />
            <button className="button-orange" onClick={props.submitForm}>Subscribe <span className="small-icon arrow-white-right-small"></span></button>
        </div>
    )
});

export default function Subscription(props) {
    return (<div className="subscription subscription-in-article">
        <div className="grid-container subscription-container">
            <div className="grid-x align-middle">
                <div className="large-5 medium-6 small-12 cell">
                    <p className="blue-subscription-desc max-wdt">Join our community to get exclusive hacks, news and giveaways!</p>
                </div>
                <div className="large-auto medium-6 small-12 cell medium-text-right small-text-center">
                    <SubscriptionForm formType={props.formType} design={'article'} adBannerId={props.adBannerId} />
                </div>
            </div>
        </div>
    </div>)
}
