import React from 'react';

export default function SchemaOrganization({itemProp}) {

    return (
        <div itemScope itemType="http://schema.org/Organization" itemProp={itemProp}>
            <meta itemProp="name" content="CareerAddict" />
            <div itemScope itemType="http://schema.org/ImageObject" itemProp="logo">
                <meta itemProp="url" content="/images/CA-Logo-Colour.png" />
                <meta itemProp="width" content="180" />
                <meta itemProp="height" content="23" />
            </div>
        </div>
    )

}