import React, { Component, Fragment } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import CF from "../../common";
import ArticleMediaFile from './bodyMediaFile';
import { renderToStaticMarkup } from 'react-dom/server';
import ProductShortcodeModal from "./editorModals/productShortcodeModal";
import ButtonShortcodeModal from './editorModals/buttonShortcodeModal';
import PopUp from '../../../components/modals/popup';
import DeleteFromGalleryModal from "../../gallery/deleteItemModal";
import TrustPilotReviewModal from "./editorModals/trustPilotReviewModal";
import ColoredBoxModal from "./editorModals/tinymceColoredBoxModal";
import RecommendedArticleModal from './editorModals/recommendedArticleModal';
import RecommendedProductModal from "./editorModals/recommendedProductModal";
import ArticlePopupShortcodeModal from './editorModals/articlePopupShortcodeModal';

import {getButtonShortcode, getProductShortode, getRecommendedArticleShortcode, getTrustPilotShortcode, getArticlePopupShortode} from "./editorModals/tinymceShrtocdesHelper";
import {escapeFromBlock, regStandOutBlocks, insertColoredBox, removeColoredBox} from "./editorHelpers/tinyFormattingBlocks";
import {insertRecommendedProduct, removeRecommendedProduct, onActionRecommendedProductButton} from "./editorHelpers/registerRecommendedProduct";
import {registerButtonIcons} from "./editorHelpers/registerButtonIcons";
import {regCustomBullets} from "./editorHelpers/registerCustomBullets";
import {regQuotes} from "./editorHelpers/registerQuotes";
import {regAsideWrapper} from "./editorHelpers/registerAside";
import {applyFormatBlock} from './editorHelpers/tynyFunctions';
import {regFaq, faqInsertQuestion, faqHandleAccordionBtn, faqOnSetContent} from "./editorHelpers/registerFaq";

class ArticleBodyEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: `tinyMceEditor${CF.generateUniqKey()}`,
            // calculateWords: this.props.mode === 'admin',
            plugins: 'wordcount, noneditable, hr, advlist, lists, table, link, charmap, print, paste, anchor, searchreplace, ' +
                'visualblocks, code, media, insertdatetime, nonbreaking, textcolor, contextmenu',
            menubar: 'edit view insert format tools table',
            height: 480,
            extended_valid_elements: 'a[href|target|id|rel|title],strong/b,' +
                'div[align|class|style],br,ul[*],li[class],ol[start|type],u,em,i,h1,h2[style],h3[style],h4[style],h5[style],h6[style]',
            invalid_styles: 'box-sizing, padding, font-weight, letter-spacing, widows, ' +
                'font-variant-caps, text-rendering, text-decoration-style, text-decoration-color, -webkit-text-stroke-width,' +
                'font-variant-ligatures, font-family, orphans, word-spacing, margin, text-indent, white-space',
            toolbar: 'bold italic underline formatselect styleselect | alignleft aligncenter alignright alignjustify | bullistc numlist outdent indent | forecolor backcolor' +
                ' | asideWrapper customQuote link customBlockQuote coloredBox faqBtn | galleryButton ' + (this.props.mode === 'admin' ? 'fileButton' : '') + ' | anchor customToggle' + (this.props.mode === 'admin' ? ' featuredVideo customProduct customButton |' +
                    'trustPilotReview customGrid2 customGrid3 recommendedArticle recommendedProduct mvVideo articlePopup' : ''),
            contextmenu: "link lists table"
        };

        this.productModal = React.createRef();
        this.buttonModal = React.createRef();
        this.articlePopupModal = React.createRef();
        this.coloredBoxModal = React.createRef();
        this.coloredBoxForm = React.createRef();
        this.trustPilotReviewModal = React.createRef();
        this.galleryDeleteModal = React.createRef();
        this.recommendedArticleModal = React.createRef();
        this.recommendedProductModal = React.createRef();
        this.recommendedProductForm = React.createRef();
    }

    // componentDidMount() {
    //     if (this.state.calculateWords) {
    //         console.log('calculate words');
    //         this.props.onChange(this.props.value);
    //         this.setState({calculateWords: false});
    //     }
    // }

    onEditorChange = (content) => {
        this.props.onChange(content);
    };

    openGalleryModal = (galleryItem, key) => {
        this.props.onGalleryOpen(galleryItem, this.insertMediaItem, key);
    };

    openFileModal = () => {
      this.props.openFileUploadModal(this.insertFileItem)
    };

    openGalleryDeleteModal = (galleryItem, key) => {
        if (galleryItem['new']) {
            this.galleryDeleteModal.current.open(galleryItem.id);
        }
    };

    insertProductShortcode = (productId) => {
        tinymce.activeEditor.execCommand('mceInsertContent', false, getProductShortode(productId));
    };

    insertArticlePopupShortcode = (popupId, label) => {
        tinymce.activeEditor.execCommand('mceInsertContent', false, getArticlePopupShortode(popupId, label));
    };

    insertArticleShortcode = (article) => {
        tinymce.activeEditor.execCommand('mceInsertContent', false, getRecommendedArticleShortcode(article));
    };

    insertButtonShortcode = (button) => {
        let rel = [];
        if (!!button.nofollow) {
            rel.push(`nofollow`)
        }
        if (!!button.sponsored) {
            rel.push(`sponsored`)
        }
        tinymce.activeEditor.execCommand('mceInsertContent', false,
            getButtonShortcode(button.name, button.url, rel.join(' ') || '', button.target_blank || '',
                button.style, button.size, button.icon, button.paid_link, button.small_font)
        );
    };

    insertTrustPilotShortcode = (review) => {
        tinymce.activeEditor.execCommand('mceInsertContent', false, getTrustPilotShortcode(review.name, review.rating, review.description, review.date));
    };

    insertMediaItem = (item, key) => {
        let ed = tinymce.activeEditor;
        if (key) {
            ed.dom.setAttrib(key, 'data-item', JSON.stringify(item));
            ed.dom.setHTML(key, renderToStaticMarkup(<ArticleMediaFile item={item} />));
            this.onEditorChange(ed.getContent());
        } else {
            let newKey = CF.generateUniqKey();
            ed.execCommand('mceInsertContent', false, renderToStaticMarkup(<p>
                <span className='article-img-conatainer' data-item={JSON.stringify(item)} id={newKey} contentEditable="false">
                    <ArticleMediaFile item={item} />
                </span>
            </p>));
        }
    };

    insertFileItem = (item) => {
        let editor = tinymce.activeEditor;
        editor.execCommand('mceInsertContent', false, renderToStaticMarkup(
            <a href={item.original_path}
               target={item.link_to_blank ? '_blank' : ''}
               title={item.alt}
               rel={item.link_to_nofollow ? 'nofollow' : ''}>{item.display_text}</a>))
    };

    render() {
        let _this = this;
        const allowedStndOutBlockClasses = 'stndt-blk stndt-blk--line stndt-blk--dashed stndt-blk--small green-stndt-blk stndt-blk--wrong stndt-blk--right stndt-blk--prop-tip stndt-blk--warning stndt-blk--example stndt-blk--summary stndt-blk--with-featured-img mv-ignore-block-class';
        const allowedFaqBlockClasses = 'faq-blk faq-blk--accordion faq-blk__question faq-blk__answer';
        return(
            <Fragment>
                <Editor value={this.props.value}
                        onEditorChange={this.onEditorChange}
                        id={this.state.id}
                        init={{
                            toolbar_mode: 'wrap',
                            plugins: this.state.plugins,
                            menubar: this.state.menubar,
                            contextmenu: this.state.contextmenu,
                            height: this.state.height,
                            extended_valid_elements : this.state.extended_valid_elements,
                            valid_classes: {
                                '*': '*',
                                'img': 'article-img article-img--with_shadow',
                                'ul': 'list--tick list--cross',
                                'li': 'blue-li',
                                'table': 'with-labels',
                                'section': 'quote article-grid-container article-grid-4  article-grid-6 right-extended recommended-product__container stndt-blk stndt-blk--line stndt-blk--dashed stndt-blk--small stndt-blk--wrong stndt-blk--right stndt-blk--prop-tip stndt-blk--warning stndt-blk--example stndt-blk--summary',
                                'p': 'recommended-product__button recommended-product__img author promo-header featured-img-container disclosure-text',
                                'a': 'button button-orange button--rounded',
                                'span': 'article-img-conatainer article-img-conatainer--bg recommended-product__button__small-label promo-best-seller recommended-product__button--wrapper edit-buttons button button-edit button-delete source-text caption-text tooltip',
                                'div': `${allowedStndOutBlockClasses} grid-x cell medium-8 medium-4 medium-shrink medium-auto medium-order-1 medium-order-2 ${allowedFaqBlockClasses}`,
                                'aside': `${allowedStndOutBlockClasses} grid-x recommended-product__container`
                            },
                            style_formats: [
                                { title: 'Blue li', selector: 'li', classes:  'blue-li'  },
                                { title: '2 columns right extended', selector: '.article-grid-6 ', classes:  'right-extended'  },
                            ],
                            invalid_styles: this.state.invalid_styles,
                            toolbar: this.state.toolbar,
                            fontsize_formats: '10px 12px 14px 16px 17px 18px 20px 22px 25px 30px 40px',
                            color_map: [
                                "000000", "Black",
                                "222222", "Dark Gray",
                                "083A50", "Dark Blue",
                                "ED8A47", "Orange",
                                "59CE61", "Green",
                                "FFFFFF", "White",
                            ],
                            advlist_bullet_styles: "default,circle,square,tick",
                            content_css: this.props.tinyMceCss,
                            relative_urls : false,
                            remove_script_host : false,
                            entity_encoding: 'raw',
                            rel_list: [
                                {title: 'Dofollow', value: ''},
                                {title: 'No Follow & Sponsored', value: 'nofollow sponsored'},
                                {title: 'Sponsored', value: 'sponsored'},
                                {title: 'No Follow', value: 'nofollow'}
                            ],
                            media_live_embeds: true,
                            mobile: {
                                theme: 'silver'
                            },
                            table_class_list: [
                                {title: 'Without Labels', value: 'without-labels'},
                                {title: 'With Labels', value: 'with-labels'}
                            ],
                            setup: function(ed) {
                                ed.on('init', function(e) {
                                    ed.formatter.register('customQuoteFormat', {block: 'section', classes : ['quote'], wrapper: true});
                                    ed.formatter.register('customGrid3Format', {block: 'section', classes : ['article-grid-container article-grid-4'], wrapper: true});
                                    ed.formatter.register('customGrid2Format', {block: 'section', classes : ['article-grid-container article-grid-6'], wrapper: true});
                                    ed.formatter.register('coloredBoxFormat', {block: 'div', classes : ['stndt-blk'], wrapper: true});
                                    ed.formatter.register('coloredBoxAsideFormat', {block: 'aside', classes : ['stndt-blk'], wrapper: true});
                                    ed.formatter.register('asideFormat', {block: 'aside', wrapper: true});
                                    ed.formatter.register('faqFormat', {block: 'div', classes : ['faq-blk'], wrapper: true});

                                    // if (_this.state.calculateWords) {
                                    //     _this.props.onChange(_this.props.value);
                                    //     _this.setState({calculateWords: false});
                                    // }
                                });

                                registerButtonIcons(ed);

                                ed.ui.registry.addToggleButton('customGrid3', {
                                    icon: 'three_columns',
                                    tooltip: 'Insert 3 Columns Grid',
                                    onAction: function (_) {
                                        if (ed.formatter.match('customGrid3Format')) {
                                            removeColumnGrid(ed);
                                        } else {
                                            ed.execCommand('RemoveFormat');
                                            applyFormatBlock('customGrid3Format');
                                            generateColumnsGrid(ed, 3);

                                        }

                                    },
                                    onSetup: function (api) {
                                        ed.formatter.formatChanged('customGrid3Format', function (state) {
                                            api.setActive(state);
                                        });
                                    }
                                });
                                ed.ui.registry.addToggleButton('customGrid2', {
                                    icon: 'two_columns',
                                    tooltip: 'Insert 2 Columns Grid',
                                    onAction: function (_) {
                                        if (ed.formatter.match('customGrid2Format')) {
                                            removeColumnGrid(ed);
                                        } else {
                                            ed.execCommand('RemoveFormat');
                                            applyFormatBlock('customGrid2Format');
                                            generateColumnsGrid(ed, 2);

                                        }

                                    },
                                    onSetup: function (api) {

                                        ed.formatter.formatChanged('customGrid2Format', function (state) {
                                            api.setActive(state);
                                        });
                                    }
                                });
                                ed.ui.registry.addToggleButton('recommendedProduct', {
                                    icon: 'browse',
                                    tooltip: 'Insert Recommended Product',
                                    onAction: function () {
                                      onActionRecommendedProductButton(_this.recommendedProductForm, _this.recommendedProductModal);
                                    },
                                    onSetup: function (api) {
                                        let editorEventCallback = function (eventApi) {
                                            const node = eventApi.element.closest(".recommended-product__container");
                                            api.setActive(!!node);
                                        };
                                        ed.on('NodeChange', editorEventCallback);
                                        return function (api) {
                                            ed.off('NodeChange', editorEventCallback);
                                        };
                                    }
                                });

                                regQuotes(ed);
                                regStandOutBlocks(ed, _this.coloredBoxModal, _this.coloredBoxForm);
                                regCustomBullets(ed);
                                regAsideWrapper(ed);
                                regFaq(ed);

                                ed.ui.registry.addButton('featuredVideo', {
                                    icon: 'embed',
                                    tooltip: 'Featured Video',
                                    onAction: function () {
                                        tinymce.activeEditor.execCommand('mceInsertContent', false, '[Video]');
                                    }
                                });

                                ed.ui.registry.addButton('customToggle', {
                                    icon: 'toggle_icon',
                                    tooltip: 'Toggle Button',
                                    onAction: function () {
                                        tinymce.activeEditor.execCommand('mceInsertContent', false, '[ToggleButton id="" label=""]');
                                    }
                                });

                                ed.ui.registry.addButton('mvVideo', {
                                    icon: 'embed-page',
                                    tooltip: 'MediaVine Video',
                                    onAction: function () {
                                        tinymce.activeEditor.execCommand('mceInsertContent', false, '[MvVideo id="" volume="70" ratio="16:9" disableJsonld="1"]');
                                    }
                                });

                                ed.ui.registry.addButton('customProduct', {
                                    icon: 'product_icon',
                                    tooltip: 'Insert Product Ad Spot',
                                    onAction: function () {
                                        _this.productModal.current.open();
                                    }
                                });

                                ed.ui.registry.addButton('customButton', {
                                    icon: 'button_icon',
                                    tooltip: 'Insert Button',
                                    onAction: function () {
                                        _this.buttonModal.current.open();
                                    }
                                });

                                ed.ui.registry.addButton('articlePopup', {
                                    icon: 'article_popup',
                                    tooltip: 'Insert Article Popup',
                                    onAction: function () {
                                        _this.articlePopupModal.current.open();
                                    }
                                });

                                ed.ui.registry.addButton('trustPilotReview', {
                                    icon: 'trust_pilot_icon',
                                    tooltip: 'Insert Trust Pilot block',
                                    onAction: function () {
                                        _this.trustPilotReviewModal.current.open();
                                    }
                                });

                                ed.ui.registry.addButton('recommendedArticle', {
                                    icon: 'article',
                                    tooltip: 'Insert Recommended Article',
                                    onAction: function () {
                                        _this.recommendedArticleModal.current.open();
                                    }
                                });

                                ed.ui.registry.addButton('galleryButton', {
                                    icon: 'image',
                                    tooltip: 'Insert Media',
                                    onAction: function () { _this.openGalleryModal(); }
                                });
                                ed.ui.registry.addButton('fileButton', {
                                    icon: 'document-properties',
                                    tooltip: 'Insert File',
                                    onAction: function () { _this.openFileModal(); }
                                });

                                ed.on("init", function(e) {
                                    console.log(ed.getBody());
                                    ed.getBody().addEventListener("click", function(e) {
                                        if (e.target.classList.contains('button-edit')) {
                                            let image = e.target.closest('[data-item]');
                                            const inStndOutBlock = !!e.target.closest(".stndt-blk");
                                            if (image)
                                                _this.openGalleryModal({...JSON.parse(image.dataset.item), forStandOutBlock: inStndOutBlock}, image.id);
                                        }
                                        if (e.target.classList.contains('btn-faq-accordion')) {
                                            let faqBlk = e.target.closest('.faq-blk');
                                            faqHandleAccordionBtn(ed, faqBlk);
                                        }
                                        if (e.target.classList.contains('btn-faq-add-question')) {
                                            let faqBlk = e.target.closest('.faq-blk');
                                            faqInsertQuestion(ed, faqBlk);
                                        }
                                        if (e.target.classList.contains('btn-faq-del-blk')) {
                                            let faqBlk = e.target.closest('.faq-blk');
                                            if (faqBlk) { faqBlk.remove(); }
                                        }
                                        if (e.target.classList.contains('btn-faq-del-question')) {
                                            let question = e.target.closest('.faq-blk__question');
                                            if (question) { question.remove(); }
                                        }
                                        if (e.target.classList.contains('button-delete')) {
                                            let image = e.target.closest('[data-item]');
                                            if (image) {
                                                image.remove();
                                                _this.openGalleryDeleteModal(JSON.parse(image.dataset.item), image.id);
                                                ed.focus();
                                            }
                                        }
                                    });
                                });

                                ed.on("SetContent", function(e) {
                                    ed.getBody().querySelectorAll('section.quote').forEach(child => {
                                        child.setAttribute('contenteditable', false);
                                        child.querySelectorAll('p').forEach(p => {p.setAttribute('contenteditable', true)});
                                    });
                                    faqOnSetContent(ed);
                                    ed.getBody().querySelectorAll('.stndt-blk.grid-x').forEach(block => {
                                        block.setAttribute('contenteditable', false);
                                        block.querySelector('.stndt-blk.grid-x > .cell.medium-order-1').setAttribute('contenteditable', true);
                                    });
                                });

                                escapeFromBlock(ed);
                            }
                        }}
                />

                <PopUp ref={this.productModal} className={'custom-form'}>
                    <ProductShortcodeModal onSave={this.insertProductShortcode} close={() => {this.productModal.current.close()}} />
                </PopUp>

                <PopUp ref={this.articlePopupModal} className={'custom-form'}>
                    <ArticlePopupShortcodeModal onSave={this.insertArticlePopupShortcode} close={() => {this.articlePopupModal.current.close()}} />
                </PopUp>

                <PopUp ref={this.recommendedArticleModal} className={'custom-form'}>
                    <RecommendedArticleModal onSave={this.insertArticleShortcode} close={() => {this.recommendedArticleModal.current.close()}} />
                </PopUp>

                <PopUp ref={this.buttonModal} className={'custom-form'}>
                    <ButtonShortcodeModal onSave={this.insertButtonShortcode} close={() => {this.buttonModal.current.close()}} />
                </PopUp>

                <PopUp ref={this.trustPilotReviewModal} className={'custom-form'}>
                    <TrustPilotReviewModal onSave={this.insertTrustPilotShortcode} close={() => {this.trustPilotReviewModal.current.close()}} />
                </PopUp>

                <PopUp ref={this.recommendedProductModal} className={'custom-form'}>
                    <RecommendedProductModal ref={this.recommendedProductForm} onSave={insertRecommendedProduct}
                                             delete={() => {removeRecommendedProduct(this.onEditorChange, this.recommendedProductModal)}}
                                             openGallery={this.props.onGalleryOpen} close={() => {this.recommendedProductModal.current.close()}} />
                </PopUp>

                <PopUp ref={this.coloredBoxModal} className={'custom-form'}>
                    <ColoredBoxModal ref={this.coloredBoxForm}
                                     onSave={(settings) => insertColoredBox(settings, this.onEditorChange) }
                                     onRemove={(keepContent) => removeColoredBox(keepContent, this.onEditorChange)}
                                     close={() => {this.coloredBoxModal.current.close()}}
                                     openGallery={this.props.onGalleryOpen} />
                </PopUp>

                <DeleteFromGalleryModal ref={this.galleryDeleteModal} />

            </Fragment>
        );
    }

}
const generateColumnsGrid = (ed, columns) => {

    let selectedParagraph = ed.selection.getNode();
    let section = selectedParagraph.closest(".article-grid-container");


    let content =  ed.selection.getContent();

    let column_1 = document.createElement("div");
    if(!content){
        selectedParagraph.remove();
        selectedParagraph = document.createElement("p");
        selectedParagraph.textContent = "Column 1";
    }
    column_1.appendChild(selectedParagraph);
    section.appendChild(column_1);

    let column_2 = document.createElement("div");
    let p2 = document.createElement("p");
    column_2.appendChild(p2);
    p2.textContent = "Column 2";
    section.appendChild(column_2);
    if (columns === 3) {
        let column_3 = document.createElement("div");
        let p3 = document.createElement("p");
        column_3.appendChild(p3);
        p3.textContent = "Column 3";
        section.appendChild(column_3);
    }
};

const removeColumnGrid = (ed) => {
    let selectedParagraph = ed.selection.getNode();
    console.log(selectedParagraph);
    let section = selectedParagraph.closest(".article-grid-container");
    console.log(section);
    if (!!section) {
        for (let i = 0; i < section.childNodes.length; i++) {
            section.childNodes[i].removeAttribute('contenteditable');
         }

        let content = section.innerHTML;
        section.remove();
        tinymce.activeEditor.execCommand('mceInsertContent', false, content);
    }
};

export default ArticleBodyEditor;