import React from "react";
import LazyLoad from 'react-lazyload';
import Index from './render';

export default class sidebarPressLazy extends React.Component {

  render(){
      return (
          <LazyLoad>
              <Index {...this.props} />
          </LazyLoad>
      )
  }

}