import { SET_AUTHOR_PROFILE, CHANGE_AUTHOR_PROFILE} from '../actions/actionTypes';
import initialState from './initialState';

export default function profile(state = initialState.profile, action) {
    switch (action.type) {
        case SET_AUTHOR_PROFILE:
            let initialProfile = action.profile;
            return { ...initialProfile, errors: {} };
        case CHANGE_AUTHOR_PROFILE:
            return Object.assign({}, state, action.profile);
        default:
            return state;
    }
}