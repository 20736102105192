import React, {Fragment} from 'react';
import ConfirmImg from "../../../assets/images/confirm-subscription.png"

export default function ConfirmationContent(props) {

    return (<Fragment>
        <div className="grid-x text-center">
            <div className="cell">
                <img src={ConfirmImg} width="160" height="154"  alt="Confirm Subscription Image" style={{textAlign:'center',margin:'0 auto 30px auto'}}/>
                <h1 className="text-center">Confirm your subscription!</h1>
                <div style={{background: '#ED8A47', height: '3px', width: '60px', margin: '0 auto 20px'}}></div>
                <p className="text-center"  style={{margin:'0 auto 10px auto',color:'#083A50',maxWidth:'600px'}}>Please check your inbox to confirm your subscription to CareerAddict's mailing list and to download our <span className={'bold-text'}>'Job Application Checklist'</span>.</p>
                <p className="text-center" style={{margin:'0 auto 40px auto',color:'#083A50',maxWidth:'600px',fontSize:'15px'}}>Don't forget to check your junk and promotions folders and to add CareerAddict.com to your safe sender list.</p>
                <div className="text-center confirmation-space">
                    <button onClick={props.onClick} className="button-orange">Close</button>
                </div>
            </div>
        </div>
            </Fragment>)

}