import React, {Fragment} from 'react';

export function getButtonShortcode(name, url, rel, tragetBlank, style, size, icon, paid_link, small_font) {
    let options = `name="${name}" url="${url}" rel="${rel}" style="${style}"`;
    if(size) options += ` size="${size}"`;
    if(tragetBlank) options += ` target_blank="${tragetBlank}"`;
    if(paid_link) options += ` paid_link="${paid_link}"`;
    if(icon) options += ` icon="${icon}"`;
    if(small_font) options += ` small_font="${small_font}"`;

    return `[Button ${options}]`;
}

export function getProductShortode(productId) {
    return productId ? `[Product #${productId}]` : '[AdSpot]';
}

export function getArticlePopupShortode(popupId, label) {
    return popupId ? `[Popup #${popupId} label="${label}"]` : '';
}

export function getRecommendedArticleShortcode(article) {
    let options = `#${article.id}  title='${article.title.replace(/[\'']/g, "\\'")}' head='${article.head}'`;
    if(article.with_image) options += ` with_image='${article.with_image}'`;
    return `[RecArticle ${options}]`;
}

export function getTrustPilotShortcode(name, rating, description, date) {
    return `[TrustPilot name="${name}" rating="${rating}" description="${description.replace(/[\""]/g, '\\"')}" date="${date}"]`;
}