import React from "react";

import * as formActions from "../actions/flexibleFormActions";
import {bindActionCreators} from "redux";
import { connect } from 'react-redux';
import {ErrorMessage} from 'react-fields';


class Summary extends React.Component {


    render(){

        return(
            <div className="grid-x">
                <div className="small-12">
                    <h3>Summary</h3>
                </div>
                <div className="small-6 cell">

                    <h4>1. Contact Info</h4>
                    {this.props.flexibleForm.fields.filter((f) => f.step === 1).map((field) => {
                      return <p key={field.id}>
                          <b>{field.placeholder}{field.placeholder.indexOf(':') >= 0 ? '': ':'}</b> {field.value}
                          <ErrorMessage message={field.error} className="inline" />
                          </p>;
                    })}

                </div>
                <div className="small-6 cell">
                    <h4>2. Service Type</h4>
                    {this.props.flexibleForm.fields.filter((f) => f.step === 2).map((field) => {
                        return <p key={field.id}><b>{field.placeholder}{field.placeholder.indexOf(':') >= 0 ? '': ':'}</b>
                            {field.value}
                            <ErrorMessage message={field.error} className="inline" />
                            </p>;
                    })}
                </div>
            </div>

        )
    }
}
function mapStateToProps(state) {
    return {
        flexibleForm: state.flexibleForm

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, formActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Summary);