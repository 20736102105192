import React from 'react';

export default function LeftBarNavigation(props) {

        return (
            <li className={'show-for-large-tab-item' + (props.currentTab === 'submit' ? ' is-active' : '')}>
                <a href={"/account/submit"} className="tabs-navigation-item secondary-link" aria-selected={props.currentTab === 'submit'}>
                    <div className="small-icon plus"/>
                    Submit an Article
                </a>
            </li>
        )
}