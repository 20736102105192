import React, {Fragment, useEffect, useState} from "react";
import SubscriptionApi from '../../api/subscription';
import {Loader} from "react-fields";
import SettingsHandler from './settingsHandler';
import SubscriptionActionHOC from "../subscriptionActionHOC";
import {render as reactDomRender} from "react-dom";
const SettingsForm = SubscriptionActionHOC(SettingsHandler);

export default function Index(props) {

    const modalId = `modal-${Math.random().toString(36).substring(7)}`;
    const [isLoading, setLoading] = useState(false);


    const modalDivAttrs = {className: `reveal add-animation`};
    modalDivAttrs['data-close-on-click'] = "false";
    modalDivAttrs['data-close-on-esc'] = "false";
    modalDivAttrs['aria-hidden'] = "false";
    modalDivAttrs['role'] = 'dialog';
    modalDivAttrs['data-animation-in'] = "slide-in-down fast";

    const openModal = (modalName) => {
        renderModal(modalName);
    };

    useEffect(
        () => {
            if (props.modal && props.modal === 'confirmation') {
                let href = `/subscriptions/manage/${props.encrypted_key}`;
                window.history.pushState({href: href}, '', href);
                openModal(props.modal)
            }
            return () => {
                let portalElement = document.getElementById(modalId);
                if (portalElement)
                document.body.removeChild(portalElement);
             };
            },[]
    );

    const closeModal = () => {
        let portalElement = document.getElementById(modalId);
        reactDomRender(null, portalElement);
        document.body.removeChild(portalElement);
    };


    const unsubscribe = async () => {
        closeModal();
        await setLoading(true);
        SubscriptionApi.delete(props.encrypted_key).then(() => {
            setLoading(false);
            openModal('after_unsubscribe')
        }).catch(error => {
            console.log(error);
        });
    };

    const showUnsubscribeConfirmation = () => {
        openModal('unsubscribe_confirmation')
    };

    const afterSave = () => {
        openModal('saved');
    };

    const afterUnsubscribe = () => {
        setLoading(true);
        closeModal();
        document.location.href = '/';
    };

    const saveModalContent = <h1 className="text-center">Changes Saved</h1>;
    const confirmationModalContent = <Fragment>
        <h1 className="text-center">Your email has been confirmed!</h1>
        <p className="text-center"  style={{marginBottom:'10px'}}>Every Tuesday, we’ll send you our weekly roundup of all the latest guides, news and insights to help you start and advance your career, find a job, and succeed at work.</p>
        <p className="text-center"  style={{marginBottom:'40px'}}><b>Your unique CareerHunter™ discount and coupon code has been sent to your email.</b></p>
        <div className="text-center">
          <button onClick={closeModal} className="button-orange">Close</button>
        </div>
    </Fragment>;
    const unsubscribeConfirmationContent = <Fragment>
        <h1 className="text-center">Are you sure?</h1>
        <div className="text-center">
            <button onClick={unsubscribe} className="button-orange">Confirm</button>
        </div>
    </Fragment>;
    const unsubscribeSuccessContent = <Fragment>
        <h1 className="text-center">You have been successfully unsubscribed from the CareerAddict Newsletter.</h1>
    </Fragment>;


    const switchModalContent = (modalName) => {
        switch (modalName) {
            case 'confirmation':
                return confirmationModalContent;
            case 'saved':
                return saveModalContent;
            case 'unsubscribe_confirmation':
                return unsubscribeConfirmationContent;
            case 'after_unsubscribe':
                return unsubscribeSuccessContent;
            default:
                return saveModalContent;
        }

    };

    let modalContent = (modalName) => {
        return  <div className="reveal-overlay" style={{display: 'block'}}>
            <div {...modalDivAttrs} style={{display: 'block'}}>
                {switchModalContent(modalName)}
                <button className="close-button big"
                        aria-label="Close modal"
                        type="button"
                        onClick={modalName === 'after_unsubscribe' ? afterUnsubscribe : closeModal}>
                    <div aria-hidden="true"/>
                </button>
            </div>
        </div>;
    };

    const renderModal = (modalName) => {
        if(document.getElementById(modalId))
            return;
        let p = document.createElement('div');
        p.id = modalId;
        document.body.appendChild(p);

        reactDomRender(modalContent(modalName), p);
    };


    return (
        <React.Fragment>
            {isLoading ? <Loader/> : null}
             <SettingsForm email={props.subscription.email}
                           encrypted_key={props.encrypted_key}
                           settings={props.subscription.settings}
                           afterSave={afterSave}
                           unsubscribe={showUnsubscribeConfirmation}
             />

        </React.Fragment>
    )
}
