import {actions} from 'react-fields'

class CategoryApi {

    static getList(onLoad) {
        const url = `/api/categories`;
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

    static getListParent(onLoad) {
        const url = "/api/categories/parent_categories";
        fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onLoad(response);
        }).catch(error => {
            console.log(error);
        })
    }

}

export default CategoryApi;