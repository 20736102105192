import React, { Component } from 'react';
import Autocomplete from "react-autocomplete";
import {actions,  CheckboxField, TextField} from "react-fields";


interface Props {
        onSave: (article: {}) => void,
        close: () => void
}

type State = {
    article: {},
    articles: {}[],
    errors: {},
    q: string,
    isOpened: boolean
};


class ProductShortcodeModal extends Component<Props, State> {

    state = {
        article: {id: "", head: "", title: "", path: "", image_path: "", name: "", with_image: true},
        errors: {head: "", title: ""},
        isOpened: false,
        articles: [],
        q: ''
    };

    handleArticleSelect = (name, article) => {
        this.setState({article: {...article, ...{title: article.name, head: "TRY READING:"}}, articles: [], q: ''});
    };

    handleAutocompleteChange = (e) => {
        this.setState({q: e.target.value}, () => {
            this.updateList();
        });

    };

    handleFieldChange = (e) => {
        let article = this.state.article;
        article[e.target.name] = e.target.name === 'with_image' ? e.target.checked :  e.target.value;
        let errors = this.state.errors;
        errors[e.target.name] = '';
        this.setState({article: article, errors: errors})
    };

    updateList = () => {
        let value = this.state.q;
        if (!value){
            this.setState({articles: []});
            return;
        }

        let _this = this;
        let url = '/api/articles/autocomplete?';
        fetch(actions.getData(`${url}q=${value}`))
            .then(actions.status)
            .then(actions.json)
            .then((data: any) => {
                _this.setState({articles: data || []});
            });
    };


    submit = () => {
        if (this.state.article.id) {
            this.props.onSave(this.state.article);
            this.props.close();
        } else {
            this.setState({errors: {id: "Article can't be blank"}});
        }
    };


    render() {
        return(
            <React.Fragment>
                <h1>Add recommended Article</h1>
                <Autocomplete
                    getItemValue={(item) => item.name}
                    items={this.state.articles}
                    inputProps={{placeholder: "Search an Article", type: 'text'}}
                    wrapperStyle={{position: 'relative',zIndex:3, marginBottom: "10px"}}
                    renderItem={(item, isHighlighted) =>
                        <div key={`article${item.id}`} style={{ background: isHighlighted ? 'lightgray' : 'white', zIndex:3, padding:'10px 20px',color:'#333333',position:'relative'}}>
                            {item.name}
                        </div>}
                    value={this.state.q}
                    onChange={this.handleAutocompleteChange}
                    onSelect={this.handleArticleSelect}
                />
                {this.state.article.id && <div>
                    <div style={{marginBottom: '20px'}}>
                    <img src={this.state.article.image_path} style={{width: '200px', marginRight: '10px'}} />
                        <a href={this.state.article.path} target='_blank'>{this.state.article.name}</a>
                    </div>
                    <TextField name={'head'}
                               onChange={this.handleFieldChange}
                               placeholder={'Head text'}
                               className={'mar-b-10'}
                               value={this.state.article.head}
                               errorMessage={this.state.errors.head} />
                    <TextField name={'title'}
                               onChange={this.handleFieldChange}
                               placeholder={'Customize title'}
                               className={'mar-b-10'}
                               value={this.state.article.title}
                               errorMessage={this.state.errors.title} />
                    <CheckboxField onChange={this.handleFieldChange}
                                   name='with_image'
                                   className={'mar-b-10'}
                                   value={this.state.article.with_image}
                                   label='Show Image'
                    />
                </div>}
                <button className="button-orange" onClick={this.submit}>Insert into Post</button>
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </React.Fragment>
        )
    }

}
export default ProductShortcodeModal;