import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as articleActions from '../../actions/articleActions';

import {SelectField, TextField} from 'react-fields';
import ToggleSwitch from "../../partials/toggleSwitch";

import moment from 'moment';
import Datetime from 'react-datetime';
import {AdminFieldWrapper} from "../../partials/adminFieldWrapper";

class Source extends React.Component {

    handleDelete = (e) => {
        e.preventDefault();
        this.props.actions.deleteArticleSource(this.props.article, this.props.source);
    };

    handleDateChange = (value) => {
        let source = this.props.source;
        source.date = moment(value).format('DD/MM/YYYY');
        this.props.actions.changeArticleSource(this.props.article, source);
    };

    handleFieldChange = (e) => {
        let source = this.props.source;
        source[e.target.name] = e.target.name === 'nofollow' ? e.target.checked : e.target.value;
        source['errors'][e.target.name] = '';
        this.props.actions.changeArticleSource(this.props.article, source);
    };

    render() {
        let source = this.props.source;

        return (
            <div className="grid-x sources-input-group">
                <div className="large-3 cell">
                    <TextField onChange={this.handleFieldChange} name='name' placeholder='Name'
                               value={source.name}
                               errorMessage={source.errors.name} errorType="tooltip" />
                </div>
                <div className="large-4 cell">
                    <TextField onChange={this.handleFieldChange} name='url' placeholder='URL'
                               value={source.url}
                               errorMessage={source.errors.url} errorType="tooltip" />
                </div>
                <div className="large-2 cell">
                    <Datetime defaultValue={source.date} inputProps={{placeholder: 'Date'}}
                              dateFormat="DD/MM/YYYY"
                              timeFormat={false}
                              onChange={this.handleDateChange} errorType="tooltip" />
                </div>
                <div className="large-2 cell">
                    <label htmlFor='nofollow'>Nofollow</label>
                    <ToggleSwitch value={source.nofollow || false} name='nofollow' onChange={this.handleFieldChange} />
                </div>
                <div className="large-auto cell text-right">
                    <button className="button-delete" onClick={this.handleDelete}> <div className="small-icon bin"/> </button>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state, ownProps) {
    let source = state.article.sources.find(f => f.key === ownProps.source.key);
    return { article: state.article, source: source };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, articleActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Source);