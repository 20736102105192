import React from "react";
import SimpleModal from "../modals/simpleModal";
import Form from './resetPasswordForm';

class ResetPassword extends React.Component {
    forgotPasswordModal = React.createRef();
    emailSentModal = React.createRef();

    onSuccess = () => {
        this.forgotPasswordModal.current.close();
        this.emailSentModal.current.open();
    };

    openForgotPassword = () => {
        this.forgotPasswordModal.current.open();
    };

    render(){
        return(
            <React.Fragment>
                <a className="secondary-link" onClick={this.openForgotPassword}>{this.props.title || "Forgot your password?"}</a>
                <SimpleModal ref={this.forgotPasswordModal} title={this.props.title || "Forgot your password?"}>
                    <p className="lastText">Please enter the email address you used to create your CareerAddict account to reset your password.</p>
                    <Form onSuccess={this.onSuccess} />
                </SimpleModal>
                <SimpleModal ref={this.emailSentModal} title="Check your Email!" button="Close" onClose={() => {document.location='/'}} >
                    <p className="lastText">Follow instructions from received email.</p>
                </SimpleModal>
            </React.Fragment>
        );
    }
}
export default ResetPassword;