import {actions} from 'react-fields'

class ReportApi {

    static create(token, report, onSuccess, onError) {
        const url = '/api/reports';
        let data = { report: {
                report_reason: report.report_reason,
                comment_id: report.comment_id,
                message: report.message
            }, g_recaptcha_response: token };

        fetch(actions.postRequest(url, data ))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            onSuccess(response);
        }).catch(error => {
            console.log(error);
            error.json().then(function (value) {
                onError(value);
            });
        });
    }




}

export default ReportApi;