import React, { Component, Fragment } from 'react';
import Gallery from "./gallery";
import {Provider} from "react-redux";
import store from '../store/store';

const DEFAULT_OPTIONS = {header: 'Insert File'};

class GalleryModal extends Component {

    state = {
        options: DEFAULT_OPTIONS
    };

    render() {
        return (
            <Provider store={store}>
                <Fragment>
                    <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
                    <h1>{this.state.options.header}</h1>
                    <Gallery />
                </Fragment>
            </Provider>
        );
    }

}

export default  GalleryModal;