import initialState from './initialState';
import CF from "../common";
import moment from 'moment';

import {
    SET_INITIAL_PRESS_ARTICLE, UPDATE_PRESS_ARTICLE, CHANGE_PRESS_ARTICLE,
    SAVE_PRESS_ARTICLE_ERRORS, ADD_PRESS_ARTICLE_KEY_TAKEAWAY, CHANGE_PRESS_ARTICLE_KEY_TAKEAWAY, DELETE_PRESS_ARTICLE_KEY_TAKEAWAY,
    REORDER_PRESS_ARTICLE_KEY_TAKEAWAYS
} from "../actions/actionTypes";

import {CHAR_LIMITS} from '../press/settings';

export default function pressArticle(state = initialState.pressArticle, action) {
    switch (action.type) {
        case SET_INITIAL_PRESS_ARTICLE:
            let attributes = {
                hasChanges: false,
                articleLoaded: true,
                press_type: action.press_type,
                takeaways: prepareTakeaways(action.article.key_takeaways),
                takeawaysDeleted: [],
                errors: {},
                file_attachment: action.article.file_attachment || {},
                featured_image: action.article.featured_image ? Object.assign({}, action.article.featured_image, {
                    alt: action.article.image_alt || action.article.featured_image.alt || ''
                }) : null,
                path: !action.article.path && action.article.name && action.article.press_type !== 'news' ? CF.setUrl(action.article.name) : action.article.path,
                pathEdited: !!action.article.id,
                charLeft: {
                    name: CHAR_LIMITS['name'] - (action.article.name || '').length,
                    label: CHAR_LIMITS['label'] - (action.article.label || '').length,
                    share_message: (CHAR_LIMITS['share_message'] - (action.article.share_message || '').length),
                    meta_title: (CHAR_LIMITS['meta_title'] - (action.article.meta_title || '').length),
                    meta_description: (CHAR_LIMITS['meta_description'] - (action.article.meta_description || '').length),
                }
            };
                attributes['wordsCount'] = null;
                let publishDate = action.article.republish_date || action.article.publish_date;
                attributes['publish_date'] = publishDate ? moment(publishDate).format('YYYY-MM-DD') : '';
                attributes['publish_time'] = publishDate ? moment(publishDate).format('HH:mm') : '';
                attributes['current_publish_date'] = (action.article.publish_date ? moment(action.article.publish_date).format('YYYY-MM-DD HH:mm') : null);
            return { ...action.article, ...attributes };
        case UPDATE_PRESS_ARTICLE:
            let updatedAttributes = {
                hasChanges: false,
                errors: {},
                id: action.article.id,
                current_publish_date: (action.article.publish_date ? moment(action.article.publish_date).format('YYYY-MM-DD HH:mm') : null)
            };

            return Object.assign({}, state, updatedAttributes);
        case CHANGE_PRESS_ARTICLE:
            let newAttr = { hasChanges: true};
            return {...state, ...action.article, ...newAttr};
        case SAVE_PRESS_ARTICLE_ERRORS:
            let errorAttributes = {errors: action.errors};
            let takeawaysErrors = action.errors['takeaways'];
            if (takeawaysErrors) {
                errorAttributes['takeaways'] = state.takeaways.map(item => {
                    if (takeawaysErrors[item.key]) {
                        return { ...item, ...{errors: takeawaysErrors[item.key]}}
                    }
                    return item
                });
                delete errorAttributes['errors']['takeaways'];
            }

            return {...state, ...errorAttributes};
        case ADD_PRESS_ARTICLE_KEY_TAKEAWAY:
            let newTakeaway = new Object({ key: CF.generateUniqKey(), errors: {}});
            return Object.assign({}, state, {takeaways: [...state.takeaways, newTakeaway]});
        case CHANGE_PRESS_ARTICLE_KEY_TAKEAWAY:
            return Object.assign({}, state, {takeaways: state.takeaways.map(item => {
                    if(item.key === action.takeaway.key){
                        return { ...item, ...action.takeaway}
                    }
                    return item
                })});
        case REORDER_PRESS_ARTICLE_KEY_TAKEAWAYS:
            return {...state, ...{takeaways: action.takeaways}};
        case DELETE_PRESS_ARTICLE_KEY_TAKEAWAY:
            let takeaways = Object.assign([], state.takeaways);
            let index = state.takeaways.findIndex(f => f.key === action.takeaway.key);
            let deletedTakeaway = state.takeaways[index];
            takeaways.splice(index, 1);
            return Object.assign({}, state, {takeaways: takeaways, takeawaysDeleted: [...state.takeawaysDeleted, deletedTakeaway]});
        default:
            return state;
    }
}
function prepareTakeaways(takeaways) {
    return takeaways.map((item) => {
        let attributes = { key: CF.generateUniqKey(), errors: {} };
        return { ...item, ...attributes }
    });
}