import React, {Component, Fragment} from 'react';
import {SelectField, TextField, TextareaField, ErrorMessage} from "react-fields";
import Datetime from 'react-datetime';
import moment from "moment";

class TrustPilotReviewModal extends Component {

    state = {
        id: this.props.id || Math.random().toString(36).substring(7),
        review: { errors: []},
        isOpened: false
    };

    handleFieldChange = (e) => {
        let review = this.state.review;
        review[e.target.name] = e.target.value;
        review.errors[e.target.name] = '';
        this.setState({review: review})
    };

    handleDateChange = (value) => {
        let review = this.state.review;
        review.errors.date = '';
        review.date = moment(value).format('YYYY-MM-DD');
        this.setState({review: review})
    };


    isValid = () => {
        let review = this.state.review;
        let valid = true;
          if(!review.name) {
              review.errors.name = "Name can't be blank";
              valid = false;
          }
        // if(!review.url) {
        //     review.errors.url = "Url can't be blank";
        //     valid = false;
        // }
        if(!review.rating){
            review.errors.rating = "Rating can't be blank";
            valid = false;
        }
        if(!review.date) {
            review.errors.date = "Date can't be blank";
            valid = false;
        }
        if(!review.description) {
            review.errors.description = "Comments can't be blank";
            valid = false;
        } else if (review.description.match(/\[|\]|"/)) {
            review.errors.description = "Comments can't contain [ ] or \" symbols ";
            valid = false;
        }
        this.setState({review: review});
        return valid;
    };

    submit = () => {

        if (this.isValid()) {
            this.props.onSave(this.state.review);
            this.props.close();
        }
    };

    render() {
        return(
            <div className={'custom-form'}>
                <h1>Enter Trust Pilot Details</h1>
                 <TextField name={'name'}
                            onChange={this.handleFieldChange}
                            placeholder={'Name'}
                            className={'mar-b-10'}
                            value={this.state.review.name}
                            errorMessage={this.state.review.errors.name} />
                <SelectField name={'rating'}
                           onChange={this.handleFieldChange}
                           placeholder={'Rating'}
                           className={'mar-b-10'}
                           options={[1,2,3,4,5]}
                           selected={this.state.review.rating}
                           errorMessage={this.state.review.errors.rating} />
                {/*<TextField name={'url'}*/}
                {/*           onChange={this.handleFieldChange}*/}
                {/*           placeholder={'URL'}*/}
                {/*           className={'mar-b-10'}*/}
                {/*           value={this.state.review.url}*/}
                {/*           errorMessage={this.state.review.errors.url} />*/}


                <div className={this.state.review.errors.date ? ' mar-b-10 err' : 'mar-b-10'}>
                    <Datetime defaultValue={this.state.review.date}
                              dateFormat="YYYY-MM-DD"
                              timeFormat={false}
                              onChange={this.handleDateChange}
                              timeConstraints={{minutes: {step: 15}}}
                              closeOnSelect={true}
                              inputProps={{placeholder: 'Date'}}
                              utc={true}
                    />
                    <ErrorMessage message={this.state.review.errors.date} />
                </div>

                <TextareaField name={'description'}
                               onChange={this.handleFieldChange}
                               placeholder={'Comment'}
                               className={'mar-b-10'}
                               rows={4}
                               value={this.state.review.description}
                               errorMessage={this.state.review.errors.description} />

                <button className="button-orange" onClick={this.submit}>Insert into Post</button>
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </div>
        )
    }

}
export default TrustPilotReviewModal;