import React from 'react';
import SwitchButton from "../../customFields/switchButton";

export default function Category(props) {

       const category = props.category;

        return (
            <div className="grid-x" style={{marginBottom: "15px"}}>
                <div className="small-8 cell" style={{paddingLeft:'0px'}}>
                    <h4 className="preference-name" onClick={() => props.expandCategory(category.id)}>{category.name}</h4>
                </div>
                <div className="small-4 cell">
                    <SwitchButton className="comment-setting-switch" value={category.selected}
                                  color={category.color}
                                  name={`category_${category.id}`}
                                  onChange={() => props.selectCategory({...category, selected: !category.selected})}/>
                </div>
                <div className={`animate-height ${props.expanded ? 'active' : ''} small-10 cell`} style={{ maxHeight: `${props.expanded ? '1000px' : 0}`}} >
                    <p>{category.description}</p>
                </div>
            </div>
        )

}