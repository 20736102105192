import React, { Component } from 'react';

class Block extends Component {

    formatAmount = (amount) => {
        if(amount <= 0){
            return "FREE"
        }
        let price = (Math.round(amount * 100) / 100).toFixed(2);
        return `${this.props.currency.symbol}${price}`;
    };

    currencyCode = () => {
        return this.props.currency.code.toLowerCase();
    };

    renderBlock = () => {
        let template = this.props.template;
        return (
            <div onClick={(e) => {
                this.props.onClick ? this.props.onClick(template, e) : null}}
                 className={`cv-template-small-block${this.props.selected ? " selected" : ""}`}>
                <div className="img">
                    { template.images.length > 0 && <img src={template.images[0].small} alt={template.name} />}
                </div>
                <div className="info">
                    <h4>{this.props.template.name}</h4>
                    <div className={'cv-details-inline'}>
                        <p>{template.category_name}</p>
                        <div className="price text-right">
                            {template.special_offer && template.special_offer_label && <div className="checkout-offer-label bg-light-green">{template.special_offer_label}</div> }
                            {template.special_offer && !template.special_offer_label && <div className="original-price">{this.formatAmount(template[`price_${this.currencyCode()}`])}</div> }
                            <div>{this.formatAmount(template[`${template.special_offer ? 'special_' : ''}price_${this.currencyCode()}`])}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render(){
        let template = this.props.template;
        return (
            <div className='cell'>
                {template.id === 'free' ?
                    this.renderBlock() :
                    <a href={`/cv-templates${template.path}`} key={`renderTemplate${template.id}`}>
                        {this.renderBlock()}
                    </a>
                }
            </div>
        )
    }
}
export default Block;