import React, { Component } from 'react';
import CollectionForSelect from '../../../../components/base/collectionForSelect';
import {TextField} from "react-fields";

class ArticlePopupShortcodeModal extends Component {

    state = {
        id: this.props.id || Math.random().toString(36).substring(7),
        label: '',
        selectedPopupId: null,
        errors: {},
        isOpened: false
    };

    handleFieldChange = (e) => {
        let errors = this.state.errors;
        errors[e.target.name] = '';
        this.setState({[e.target.name]: e.target.value, errors: errors})
    };

    submit = () => {
        let errors = this.state.errors;
        let valid = true;
        if (!this.state.selectedPopupId) {
            errors.selectedPopupId = "Popup can't be blank";
            valid = false;
        }
        if (!this.state.label) {
            errors.label = "label can't be blank";
            valid = false;
        }
        if (valid) {
            this.props.onSave(this.state.selectedPopupId, this.state.label);
            this.props.close();
        } else {
            this.setState({errors: errors});
        }
    };


    render() {
        return(
            <React.Fragment>
                <h1>Select Popup</h1>
                <CollectionForSelect name="selectedPopupId" placeholder="Popup name..."
                                     onChange={this.handleFieldChange}
                                     className={'mar-b-10'}
                                     url="/api/article_popups" optionsValue='id' optionsLabel='name'
                                     selected={this.state.selectedPopupId}
                                     errorMessage={this.state.errors.selectedPopupId} />
                <TextField name={'label'}
                           onChange={this.handleFieldChange}
                           placeholder={'Label text'}
                           className={'mar-b-10'}
                           value={this.state.label}
                           errorMessage={this.state.errors.label} />
                <button className="button-orange" onClick={this.submit}>Insert into Post</button>
                <button className="close-button" aria-label="Close modal" type="button" onClick={this.props.close} />
            </React.Fragment>
        )
    }

}
export default ArticlePopupShortcodeModal;