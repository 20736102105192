import React, { Component, Fragment } from 'react';
import CvTemplatesApi from '../api/cvTemplates';
import TemplateBlock from './block';


import ListTop from "../../components/partials/listTop";
import DropDownButton from "../../components/dropDownButton";

import Pagination from "../../components/partials/seoPaginationButtons";
import PaginationMeta from '../../components/paginationMeta';
import {actions, Loader} from "react-fields";

const SORT_PRICE_LIST = [{value: '', name: 'Newest'}, {value: 'price_asc', name: 'Lowest price first'}, {value: 'price_desc', name: 'Highest price first'}];

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            collection: this.props.templates || [],
            totalCount: this.props.count || null,
            page: this.props.page || 1,
            totalPages: this.props.total || 1,
            isLoaded: true,
            currentCategory: this.props.category_id ? this.props.categoriesList.find((c) => c.id === parseInt(this.props.category_id)) : null,
            currentSort: this.props.sort ? SORT_PRICE_LIST.find(s => s.value === this.props.sort) : null,
            categoriesList: [{id: '', name: 'All'}].concat(this.props.categoriesList),
            priceSortingList: SORT_PRICE_LIST,
        };

        this.contentRef = React.createRef();
    }

    componentDidUpdate(){
        window.onpopstate = e => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let page = urlParams.get('page');
            page = page ? parseInt(page) : 1;
            if(page !== this.state.page){
                this.loadTemplates(page);
            }
        }
    }

    prepareOptionsSearch = (page) => {
        let params = page === 1 ? {} : {page: page};
        if (this.state.currentCategory)
            params['category_id'] = this.state.currentCategory.id;
        if (this.state.currentSort && !!this.state.currentSort.value) {
            params['sort'] = this.state.currentSort.value;
            params['currency_code'] = this.props.currency.code.toLowerCase();
        }
        return params;
    };

    loadTemplates = (page) => {
        const urlParams = this.prepareOptionsSearch(page);
        CvTemplatesApi.search(this.onLoad, urlParams);
    };

    goToPage = (e, page) => {
        e.preventDefault();
        this.setState({isLoading: true}, () => { this.loadTemplates(page) });
        const scrollTo = this.contentRef.current.getBoundingClientRect().top + document.documentElement.scrollTop - 110;
        window.scrollTo({top: scrollTo, behavior: 'smooth'});
    };

    onLoad = (response, urlParams) => {
        this.setState({collection: response.templates, page: response.page, isLoaded: true, isLoading: false,
                            totalCount: response.count, totalPages: response.total});
        PaginationMeta.update(urlParams, response.count, response.total, 12, true);
    };

    handleFilterChange = (filter) => {
        this.setState({currentCategory: filter}, () => {this.loadTemplates(1)});
    };

    handleSortChange = (sort) => {
        this.setState({currentSort: sort}, () => {this.loadTemplates(1)});
    };

    render(){
        let nextPage = parseInt(this.state.page)+1;
        let nextPagePath = `/cv-templates?page=${nextPage}`;
        if (!!this.state.currentCategory) { nextPagePath += `&category_id=${this.state.currentCategory.id}` }
        return (
            <div ref={this.contentRef}>
                {this.state.isLoading ? <Loader size="small unconfirmed"/> : null}
                <ListTop count={this.state.totalCount} header='résumé template'>
                    <div className="filter-buttons text-right">
                        <DropDownButton name='Sort' icon={'caret-down'} value={this.state.currentSort}
                                        onChange={this.handleSortChange}
                                        options={this.state.priceSortingList} optionsLabel='name' btnClassName='button-white text-left' />

                        {this.state.categoriesList ? <DropDownButton name='Category' icon='caret-down' value={this.state.currentCategory}
                                        onChange={this.handleFilterChange}
                                        options={this.state.categoriesList} optionsLabel='name' btnClassName='button-white text-left' /> : null}
                    </div>
                </ListTop>

                {this.state.totalCount > 0 ?
                    <Fragment>
                        <div className="grid-x grid-padding-y medium-up-2 large-up-4 list-cv-templates">
                            {this.state.collection.map((template, i) => (
                                <TemplateBlock key={`template${template.id}_${i}`} template={template}
                                                     currency={this.props.currency} />
                            ))}
                        </div>
                        {/*{this.state.page < this.state.totalPages ?*/}
                        {/*    <div className="grid-x align-center mar-top-40-small">*/}
                        {/*        <a className='button button-green-rounded' href={nextPagePath} onClick={(e) => { e.preventDefault(); this.loadTemplates(nextPage) }} >*/}
                        {/*            Load More*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*    : null}*/}

                        <div className="grid-x">
                            <div className="small-12 cell">
                                <Pagination page={parseInt(this.state.page)} options={this.prepareOptionsSearch(1)} path={'/cv-templates'}
                                            totalPages={this.state.totalPages}
                                            goToPage={this.goToPage}/>
                            </div>
                        </div>
                    </Fragment>
                    :
                    <h3>No Templates Found</h3> }

            </div>
        )
    }
}
export default List;