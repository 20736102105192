import React from "react";
import {InputTypeField} from "react-fields";
import SubscriptionActionHOC from "../subscriptionActionHOC";

const SubscriptionForm = SubscriptionActionHOC(props => {
    return (<React.Fragment>
        <InputTypeField className={'subscription__email'} type='email' name="email" value={props.form.email}
                        onChange={props.setEmail} errorMessage={props.form.errors.email}
                        placeholder="Enter Email..."
                        onKeyPress={props.submitOnEnter}
        />
        <label className="subscription__submit" onClick={props.submitForm}><span
            className="send-icon"/></label>
    </React.Fragment>)
});

export default function AdInput(props) {
    return (<SubscriptionForm formType={'article'} design={'ad_form'}/>)
}
