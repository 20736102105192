import React, { Component } from 'react';
import Carousel from './historyCarousel';
import LazyLoad from 'react-lazyload';

export default class Milestones extends Component {

    render() {
        return (
            <LazyLoad>
                <Carousel />
            </LazyLoad>
        );
    }
}