import React from 'react';
import loginApi from '../api/login';

export default function RightBarNavigation() {

    const logout = async () => {
        loginApi.delete().then(() => {
            document.location.href = '/';
        }).catch(error => {
            console.log(error);
        });
    };

    return (
        <li className="text-left medium-text-right author-menu">
            <a className="tabs-navigation-item logout" onClick={logout}>
                <span style={{marginRight: '10px'}}>Log Out</span>
                <div className="small-icon exit"/>
            </a>
        </li>
    )
}