import React from "react";
import SubscribeButton from "../handler";

//SubscribeAd id={'SubscribeArchiveAd'} for GTM!
export default function AdButton(props) {
    return (<div className="subscription__hunter--block SubscribeAd" id={'SubscribeArchiveAd'}>
        <div className="subscription__hunter--line"/>
        <div className="subscription__hunter--img">
            <img src="https://cdn2.careeraddict.com/uploads/images/59115/CareerHunter.png" alt="test"/>
        </div>
        <h3 className="subscription__hunter--title">
            <a href="https://www.careerhunter.io/?utm_source=careeraddict&utm_medium=native&utm_campaign=archive">Career Testing</a>
        </h3>
        <p className="subscription__hunter--desc">Use our scientific career matching platform to find professions that suit you.</p>
        <div className="subscription__hunter--logos">
            <a href="https://www.careerhunter.io/?utm_source=careeraddict&amp;utm_medium=native&amp;utm_campaign=subscribe"
            target="_blank" className="subscription__hunter--ch">Career<span>Hunter™</span></a>
            <img src="https://www.careerhunter.io/uploads/images/607/TP-review-logo-dark-01.svg" alt="trustpilot"
            className="subscription__hunter--trustpilot"/>
        </div>
            <SubscribeButton formType='banner' design={'ad_button'} adBannerId={props.adBannerId} />
        <p className="subscription__hunter--coupon">GET YOUR 10% COUPON</p>
    </div>)
}
