import React from "react";
import {Loader, ErrorMessage, actions, InputTypeField} from 'react-fields';
import PasswordApi from '../api/password';
import SimpleModal from "../modals/simpleModal";

class SetNewPassword extends React.Component {
    state = {
        isLoading: false,
        user: {reset_password_token: this.props.reset_password_token, errors: {}},
        email: "",
        errors:{}
    };


    handleFieldChange = (e) => {
        let user = this.state.user;
        let field_name = e.target.name;
        user[field_name] = e.target.value;
        user.errors[actions.camelize(field_name)] = "";
        this.setState({user: user});
    };

    renderErrors = (errors) => {
        let user = this.state.user;
        user.errors = errors;
        this.setState({isLoading: false, user: user});

    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        PasswordApi.updatePassword(this.state.user, this.onSuccess, this.renderErrors)
    };

    onSuccess = (response) => {
        this.setState({isLoading: false});
        if (response.author_type === 'unapproved')
            this.refs.passwordSavedUnapproved.open();
        else
            this.refs.passwordSaved.open();
    };



    render(){
        let user = this.state.user;
        return(
            <React.Fragment>
                    <form>
                        {this.state.isLoading ? <Loader size="small"/> : null}
                        <div className="grid-x">
                            <div className="small-12 cell">
                                <InputTypeField onKeyPress={this.submitOnEnter} name="new_password"
                                                onChange={this.handleFieldChange} value={user.new_password}
                                                placeholder="New Password*" type="password" errorMessage={user.errors.password}/>
                            </div>
                            <div className="small-12 cell">
                                <InputTypeField onKeyPress={this.submitOnEnter} name="password_confirmation"
                                                onChange={this.handleFieldChange} value={user.password_confirmation}
                                                placeholder="Confirm Password*" type="password" errorMessage={user.errors.passwordConfirmation}/>
                            </div>
                            <ErrorMessage message={user.errors.base}/>
                        </div>
                        <div className="small-6 cell text-center form-buttons">
                            <button className="button-orange" onClick={this.handleSubmit}>Save</button>
                        </div>
                    </form>
                <SimpleModal ref="passwordSaved" title="Password Changed!" sprite="ad-icon popUp" button="Close" onClose={() => {document.location='/account/profile'}} />
                <SimpleModal ref="passwordSavedUnapproved" title="Password Changed!" sprite="ad-icon popUp" button="Close" onClose={() => {document.location='/login'}} >
                    <React.Fragment>
                        <p>You're account is waiting for approval. If you have any questions in the meantime, please contact the CareerAddict Editor.</p>
                        <br />
                    </React.Fragment>
                </SimpleModal>
            </React.Fragment>
        );
    }
}
export default SetNewPassword;